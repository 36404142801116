import React from 'react'
import axios from 'axios'
import Member from './Member'
import  { Navigate } from 'react-router-dom'
import store from '../store/configstore'
import { LazyLoadImage } from 'react-lazy-load-image-component'


class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            username : null,
            showYoutube :false,
            review:[
                {url:'/images/reviews/review-6-2-2022.jpg',alt:'review-6-2-2022'},
                {url:'/images/reviews/review-5-2-2022.jpg',alt:'review-5-2-2022'},
                {url:'/images/reviews/review-4-2-2022.jpg',alt:'review-4-2-2022'},
                {url:'/images/reviews/review-2-2-2022.jpg',alt:'review-2-2-2022'},
                {url:'/images/reviews/review-30-1-2022.jpg',alt:'review-30-1-2022'},
                {url:'/images/reviews/review-29-1-2022.jpg',alt:'review-29-1-2022'},
                {url:'/images/reviews/review-28-1-2022.jpg',alt:'review-28-1-2022'},
                {url:'/images/reviews/review-26-1-2022.jpg',alt:'review-26-1-2022'},
                {url:'/images/reviews/review-24-1-2022.jpg',alt:'review-24-1-2022'},
                {url:'/images/reviews/review-22-1-2022.jpg',alt:'review-22-1-2022'},
                {url:'/images/reviews/review-20-1-2022.jpg',alt:'review-20-1-2022'},
{url:'/images/reviews/review-16-1-2022.jpg',alt:'review-16-1-2022'},
{url:'/images/reviews/review-15-1-2022.jpg',alt:'review-15-1-2022'},
{url:'/images/reviews/review-11-1-2022.jpg',alt:'review-11-1-2022'},
{url:'/images/reviews/review-10-1-2022.jpg',alt:'review-10-1-2022'},
{url:'/images/reviews/review-9-1-2022.jpg',alt:'review-9-1-2022'},
{url:'/images/reviews/review-7-1-2022.jpg',alt:'review-7-1-2022'},
{url:'/images/reviews/review-3-1-2022.jpg',alt:'review-3-1-2022'},
{url:'/images/reviews/review-31-12-2021.jpg',alt:'review-31-12-2021'},
{url:'/images/reviews/review-29-12-2021.jpg',alt:'review-29-12-2021'},
{url:'/images/reviews/review-28-12-2021.jpg',alt:'review-28-12-2021'},
{url:'/images/reviews/review-26-12-2021.jpg',alt:'review-26-12-2021'},
{url:'/images/reviews/review-24-12-2021.jpg',alt:'review-24-12-2021'},
{url:'/images/reviews/review-16-12-2021.jpg',alt:'review-16-12-2021'},
{url:'/images/reviews/review-12-12-2021.jpg',alt:'review-12-12-2021'},
{url:'/images/reviews/review-11-12-2021.jpg',alt:'review-11-12-2021'}
            ],
            showReviewAll:false
        }
        
     }

     switchYoutube = () =>{
         this.setState({showYoutube:!this.state.showYoutube})
     }
     
     submitForm = (event) => {
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'width=device-width,initial-scale=1');

        //axios.post(`http://localhost/member`,{username: event.target[0].value,password: event.target[1].value}).then(res =>{
        axios.post(`https://sv.aitded.com/member`,{username: event.target[0].value,password: event.target[1].value}).then(res =>{
            if(res.data.error===undefined){
                //console.log('login success')
                console.log(res.data)
                store.dispatch({ 
                    type: 'login',
                    data: {...res.data,level:'member',pwd:event.target[1].value}
                });
                this.setState({
                    username : res.data.username
                })
            }else{
                alert('Username หรือ Password ไม่ถูกต้อง')
                //console.log('login no success')
                store.dispatch({ 
                    type: 'logout',
                    data: {username : undefined, expire : undefined,level : undefined, pwd:undefined, gacha : 0,listGachaItem : undefined}
                });
                this.setState({
                    username : null
                })
            }
        })
        event.preventDefault();
     }

     showreviewall = () =>{
         this.setState({showReviewAll:true})
     }
     render() {
        //console.log(store.getState())
         //console.log(this.state)
         return (
                store.getState().username!==undefined&&store.getState().level==='member'?<Navigate to={{ pathname: "/member"}} />:
                <div>
                <div className="main-container">
                        <video autoPlay muted loop="yes" id="myVideo">
                            <source src="/videos/bg_video.mp4" type="video/mp4" width="100%" />
                        </video>
                    <div className="mainHomepage">
                    <div className="video_poster"><video controls className="configVDO" poster="https://aitded.com/images/poster-aitdedvdo.webp" src="https://aitded.b-cdn.net/vdo_promote_aitded_hl.mp4"></video></div> 

                    
                    <div className="main-login">
                        <div className="row">                    
                            <div className="space-6"></div>
                            <div className="login-box">
                                <div className="box">
                                    <h1 className="text-gradient">AITDED.COM</h1>
                                    <p style={{fontSize:'20pt'}}>ระบบกำลังปิดปรับปรังช่วงเวลา <br />10:00-12:00 <br />ขออภัยในความไม่สะดวก</p>
                                    <button className="btn btn-gradient-border btn-gacha btn-glow btnHomeform seeBtnYoutube" onClick={this.switchYoutube}>{this.state.showYoutube?'ซ่อน':'ดู'} VDO รีวิวการใช้สูตรบอล คลิ๊ก !!</button>
                                    {this.state.showYoutube?
                                    <div className="video_poster youtubeHowtoUse">
                                    <iframe width="800" height="350" src="https://www.youtube.com/embed/M2ZOve3WlSY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                           
                                    </div>
                                    :''}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                    <a href="https://lin.ee/NIDxu4g" rel="nofollow noopener" target="_blank"><LazyLoadImage src='/images/qrcode.png' className="btn btn-gradient-border btn-glow" style={{maxWidth:'200px',maxHeight:'200px',padding:'10px'}} /></a>
                    <div className="creditFooter" style={{paddingTop:'15px',color:'white',textAlign:'center'}}> ติดต่อขอรับสูตรฟรี แอดไลน์ : @aitded หรือคลิ๊กที่ปุ่มขอรับสูตร</div>
                </div>
                <div className='reviewZone'>
                </div>
                </div>
                
         )
     }
}

export default Home;
