import React from 'react'
import axios from 'axios'
import Member from './Member'
import  { Navigate } from 'react-router-dom'
import store from '../store/configstore'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import '../Snowfall.css';


class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            username : null,
            showYoutube :false,
            review:[
                {url:'/images/reviews/review-16-12-2022.jpg',alt:'review-16-12-2022'},
                {url:'/images/reviews/review-15-12-2022.jpg',alt:'review-15-12-2022'},
                {url:'/images/reviews/review-14-12-2022.jpg',alt:'review-14-12-2022'},
                {url:'/images/reviews/review-13-12-2022.jpg',alt:'review-13-12-2022'},
                {url:'/images/reviews/review-12-12-2022.jpg',alt:'review-12-12-2022'},
                {url:'/images/reviews/review-11-12-2022.jpg',alt:'review-11-12-2022'},
                {url:'/images/reviews/review-10-12-2022.jpg',alt:'review-10-12-2022'},
                {url:'/images/reviews/review-9-12-2022.jpg',alt:'review-9-12-2022'},
                {url:'/images/reviews/review-8-12-2022.jpg',alt:'review-8-12-2022'},
                {url:'/images/reviews/review-7-12-2022.jpg',alt:'review-7-12-2022'},
                {url:'/images/reviews/review-6-12-2022.jpg',alt:'review-6-12-2022'},
                {url:'/images/reviews/review-5-12-2022.jpg',alt:'review-5-12-2022'},
                {url:'/images/reviews/review-4-12-2022.jpg',alt:'review-4-12-2022'},
                {url:'/images/reviews/review-2-12-2022.jpg',alt:'review-2-12-2022'},
                {url:'/images/reviews/review-1-12-2022.jpg',alt:'review-1-12-2022'},
                {url:'/images/reviews/review-30-11-2022.jpg',alt:'review-30-11-2022'},
                {url:'/images/reviews/review-29-11-2022.jpg',alt:'review-29-11-2022'},
                {url:'/images/reviews/review-28-11-2022.jpg',alt:'review-28-11-2022'},
                {url:'/images/reviews/review-27-11-2022.jpg',alt:'review-27-11-2022'},
                {url:'/images/reviews/review-26-11-2022.jpg',alt:'review-26-11-2022'},
                {url:'/images/reviews/review-24-11-2022.jpg',alt:'review-24-11-2022'},
                {url:'/images/reviews/review-23-11-2022.jpg',alt:'review-23-11-2022'},
                {url:'/images/reviews/review-22-11-2022.jpg',alt:'review-22-11-2022'},
                {url:'/images/reviews/review-21-11-2022.jpg',alt:'review-21-11-2022'},
                {url:'/images/reviews/review-20-11-2022.jpg',alt:'review-20-11-2022'},
                {url:'/images/reviews/review-19-11-2022.jpg',alt:'review-19-11-2022'},
                {url:'/images/reviews/review-18-11-2022.jpg',alt:'review-18-11-2022'},
                {url:'/images/reviews/review-17-11-2022.jpg',alt:'review-17-11-2022'},
                {url:'/images/reviews/review-16-11-2022.jpg',alt:'review-16-11-2022'},
                {url:'/images/reviews/review-14-11-2022.jpg',alt:'review-14-11-2022'},
                {url:'/images/reviews/review-13-11-2022.jpg',alt:'review-13-11-2022'},
                {url:'/images/reviews/review-12-11-2022.jpg',alt:'review-12-11-2022'},
                {url:'/images/reviews/review-11-11-2022.jpg',alt:'review-11-11-2022'},
                {url:'/images/reviews/review-10-11-2022.jpg',alt:'review-10-11-2022'},
                {url:'/images/reviews/review-9-11-2022.jpg',alt:'review-9-11-2022'},
                {url:'/images/reviews/review-8-11-2022.jpg',alt:'review-8-11-2022'},
                {url:'/images/reviews/review-7-11-2022.jpg',alt:'review-7-11-2022'},
                {url:'/images/reviews/review-6-11-2022.jpg',alt:'review-6-11-2022'},
                {url:'/images/reviews/review-5-11-2022.jpg',alt:'review-5-11-2022'},
                {url:'/images/reviews/review-4-11-2022.jpg',alt:'review-4-11-2022'},
                {url:'/images/reviews/review-3-11-2022.jpg',alt:'review-3-11-2022'},
                {url:'/images/reviews/review-2-11-2022.jpg',alt:'review-2-11-2022'},
                {url:'/images/reviews/review-1-11-2022.jpg',alt:'review-1-11-2022'},
                /*{url:'/images/reviews/review-31-10-2022.jpg',alt:'review-31-10-2022'},
                {url:'/images/reviews/review-30-10-2022.jpg',alt:'review-30-10-2022'},
                {url:'/images/reviews/review-29-10-2022.jpg',alt:'review-29-10-2022'},
                {url:'/images/reviews/review-28-10-2022.jpg',alt:'review-28-10-2022'},
                {url:'/images/reviews/review-27-10-2022.jpg',alt:'review-27-10-2022'},
                {url:'/images/reviews/review-26-10-2022.jpg',alt:'review-26-10-2022'},
                {url:'/images/reviews/review-25-10-2022.jpg',alt:'review-25-10-2022'},
                {url:'/images/reviews/review-24-10-2022.jpg',alt:'review-24-10-2022'},
                {url:'/images/reviews/review-23-10-2022.jpg',alt:'review-23-10-2022'},
                {url:'/images/reviews/review-22-10-2022.jpg',alt:'review-22-10-2022'},
                {url:'/images/reviews/review-21-10-2022.jpg',alt:'review-21-10-2022'},
                {url:'/images/reviews/review-20-10-2022.jpg',alt:'review-20-10-2022'},
                {url:'/images/reviews/review-19-10-2022.jpg',alt:'review-19-10-2022'},
                {url:'/images/reviews/review-17-10-2022.jpg',alt:'review-17-10-2022'},
                {url:'/images/reviews/review-16-10-2022.jpg',alt:'review-16-10-2022'},
                {url:'/images/reviews/review-15-10-2022.jpg',alt:'review-15-10-2022'},
                {url:'/images/reviews/review-14-10-2022.jpg',alt:'review-14-10-2022'},
                {url:'/images/reviews/review-13-10-2022.jpg',alt:'review-13-10-2022'},
                {url:'/images/reviews/review-12-10-2022.jpg',alt:'review-12-10-2022'},
                {url:'/images/reviews/review-11-10-2022.jpg',alt:'review-11-10-2022'},
                {url:'/images/reviews/review-10-10-2022.jpg',alt:'review-10-10-2022'},
                {url:'/images/reviews/review-9-10-2022.jpg',alt:'review-9-10-2022'},
                {url:'/images/reviews/review-8-10-2022.jpg',alt:'review-8-10-2022'},
                {url:'/images/reviews/review-7-10-2022.jpg?v1.1',alt:'review-7-10-2022'},
                {url:'/images/reviews/review-6-10-2022.jpg',alt:'review-6-10-2022'},
                {url:'/images/reviews/review-5-10-2022.jpg',alt:'review-5-10-2022'},
                {url:'/images/reviews/review-4-10-2022.jpg',alt:'review-4-10-2022'},
                {url:'/images/reviews/review-3-10-2022.jpg',alt:'review-3-10-2022'},
                {url:'/images/reviews/review-2-10-2022.jpg',alt:'review-2-10-2022'},
                {url:'/images/reviews/review-1-10-2022.jpg',alt:'review-1-10-2022'},
                {url:'/images/reviews/review-30-9-2022.jpg',alt:'review-30-9-2022'},
                {url:'/images/reviews/review-28-9-2022.jpg',alt:'review-28-9-2022'},
                {url:'/images/reviews/review-27-9-2022.jpg',alt:'review-27-9-2022'},
                {url:'/images/reviews/review-26-9-2022.jpg',alt:'review-26-9-2022'},
                {url:'/images/reviews/review-25-9-2022.jpg',alt:'review-25-9-2022'},
                {url:'/images/reviews/review-24-9-2022.jpg',alt:'review-24-9-2022'},
                {url:'/images/reviews/review-22-9-2022.jpg',alt:'review-22-9-2022'},
                {url:'/images/reviews/review-22-9-2022.jpg',alt:'review-22-9-2022'},
                {url:'/images/reviews/review-20-9-2022.jpg',alt:'review-20-9-2022'},
                {url:'/images/reviews/review-19-9-2022.jpg',alt:'review-19-9-2022'},
                {url:'/images/reviews/review-18-9-2022.jpg',alt:'review-18-9-2022'},
                {url:'/images/reviews/review-17-9-2022.jpg',alt:'review-17-9-2022'},
                {url:'/images/reviews/review-16-9-2022.jpg',alt:'review-16-9-2022'},
                {url:'/images/reviews/review-15-9-2022.jpg',alt:'review-15-9-2022'},
                {url:'/images/reviews/review-14-9-2022.jpg',alt:'review-14-9-2022'},
                {url:'/images/reviews/review-13-9-2022.jpg',alt:'review-13-9-2022'},
                {url:'/images/reviews/review-12-9-2022.jpg',alt:'review-12-9-2022'},
                {url:'/images/reviews/review-11-9-2022.jpg',alt:'review-11-9-2022'},
                {url:'/images/reviews/review-9-9-2022.jpg',alt:'review-9-9-2022'},
                {url:'/images/reviews/review-8-9-2022.jpg',alt:'review-8-9-2022'},
                {url:'/images/reviews/review-7-9-2022.jpg',alt:'review-7-9-2022'},
                {url:'/images/reviews/review-6-9-2022.jpg',alt:'review-6-9-2022'},
                {url:'/images/reviews/review-5-9-2022.jpg',alt:'review-5-9-2022'},
                {url:'/images/reviews/review-4-9-2022.jpg',alt:'review-4-9-2022'},
                {url:'/images/reviews/review-3-9-2022.jpg',alt:'review-3-9-2022'},
                {url:'/images/reviews/review-2-9-2022.jpg',alt:'review-2-9-2022'},
                {url:'/images/reviews/review-1-9-2022.jpg',alt:'review-1-9-2022'},
                {url:'/images/reviews/review-31-8-2022.jpg',alt:'review-31-8-2022'},
                {url:'/images/reviews/review-30-8-2022.jpg',alt:'review-30-8-2022'},
                {url:'/images/reviews/review-29-8-2022.jpg',alt:'review-29-8-2022'},
                {url:'/images/reviews/review-28-8-2022.jpg',alt:'review-28-8-2022'},
                {url:'/images/reviews/review-27-8-2022.jpg',alt:'review-27-8-2022'},
                {url:'/images/reviews/review-25-8-2022.jpg',alt:'review-25-8-2022'},
                {url:'/images/reviews/review-24-8-2022.jpg',alt:'review-24-8-2022'},
                {url:'/images/reviews/review-23-8-2022.jpg',alt:'review-23-8-2022'},
                {url:'/images/reviews/review-22-8-2022.jpg',alt:'review-22-8-2022'},
                {url:'/images/reviews/review-21-8-2022.jpg',alt:'review-21-8-2022'},
                {url:'/images/reviews/review-20-8-2022.jpg',alt:'review-20-8-2022'},
                {url:'/images/reviews/review-19-8-2022.jpg',alt:'review-19-8-2022'},
                {url:'/images/reviews/review-18-8-2022.jpg',alt:'review-18-8-2022'},
                {url:'/images/reviews/review-17-8-2022.jpg',alt:'review-17-8-2022'},
                {url:'/images/reviews/review-16-8-2022.jpg',alt:'review-16-8-2022'},
                {url:'/images/reviews/review-15-8-2022.jpg',alt:'review-15-8-2022'},
                {url:'/images/reviews/review-13-8-2022.jpg',alt:'review-13-8-2022'},
                {url:'/images/reviews/review-12-8-2022.jpg',alt:'review-12-8-2022'},
                {url:'/images/reviews/review-11-8-2022.jpg',alt:'review-11-8-2022'},
                {url:'/images/reviews/review-10-8-2022.jpg',alt:'review-10-8-2022'},
                {url:'/images/reviews/review-9-8-2022.jpg',alt:'review-9-8-2022'},
                {url:'/images/reviews/review-8-8-2022.jpg',alt:'review-8-8-2022'},
                {url:'/images/reviews/review-7-8-2022.jpg',alt:'review-7-8-2022'},
                {url:'/images/reviews/review-6-8-2022.jpg',alt:'review-6-8-2022'},
                {url:'/images/reviews/review-5-8-2022.jpg',alt:'review-5-8-2022'},
                {url:'/images/reviews/review-4-8-2022.jpg',alt:'review-4-8-2022'},
                {url:'/images/reviews/review-3-8-2022.jpg',alt:'review-3-8-2022'},
                {url:'/images/reviews/review-2-8-2022.jpg',alt:'review-2-8-2022'},
                {url:'/images/reviews/review-1-8-2022.jpg',alt:'review-1-8-2022'},
                {url:'/images/reviews/review-30-7-2022.jpg',alt:'review-30-7-2022'},
                {url:'/images/reviews/review-29-7-2022.jpg',alt:'review-29-7-2022'},
                {url:'/images/reviews/review-28-7-2022.jpg',alt:'review-28-7-2022'},
                {url:'/images/reviews/review-27-7-2022.jpg',alt:'review-27-7-2022'},
                {url:'/images/reviews/review-26-7-2022.jpg',alt:'review-26-7-2022'},
                {url:'/images/reviews/review-25-7-2022.jpg',alt:'review-25-7-2022'},
                {url:'/images/reviews/review-24-7-2022.jpg',alt:'review-24-7-2022'},
                {url:'/images/reviews/review-23-7-2022.jpg',alt:'review-23-7-2022'},
                {url:'/images/reviews/review-22-7-2022.jpg',alt:'review-22-7-2022'},
                {url:'/images/reviews/review-21-7-2022.jpg',alt:'review-21-7-2022'},
                {url:'/images/reviews/review-20-7-2022.jpg',alt:'review-20-7-2022'},
                {url:'/images/reviews/review-18-7-2022.jpg',alt:'review-18-7-2022'},
                {url:'/images/reviews/review-17-7-2022.jpg',alt:'review-17-7-2022'},
                {url:'/images/reviews/review-16-7-2022.jpg',alt:'review-16-7-2022'},
                {url:'/images/reviews/review-15-7-2022.jpg',alt:'review-15-7-2022'},
                {url:'/images/reviews/review-15-7-2022.jpg',alt:'review-15-7-2022'},
                {url:'/images/reviews/review-14-7-2022.jpg',alt:'review-14-7-2022'},
                {url:'/images/reviews/review-13-7-2022.jpg',alt:'review-13-7-2022'},
                {url:'/images/reviews/review-12-7-2022.jpg',alt:'review-12-7-2022'},
                {url:'/images/reviews/review-11-7-2022.jpg',alt:'review-11-7-2022'},
                {url:'/images/reviews/review-10-7-2022.jpg',alt:'review-10-7-2022'},
                {url:'/images/reviews/review-9-7-2022.jpg',alt:'review-9-7-2022'},
                {url:'/images/reviews/review-8-7-2022.jpg',alt:'review-8-7-2022'},
                {url:'/images/reviews/review-7-7-2022.jpg?v1',alt:'review-7-7-2022'},
                {url:'/images/reviews/review-6-7-2022.jpg',alt:'review-6-7-2022'},
                {url:'/images/reviews/review-5-7-2022.jpg',alt:'review-5-7-2022'},
                {url:'/images/reviews/review-4-7-2022.jpg',alt:'review-4-7-2022'},
                {url:'/images/reviews/review-3-7-2022.jpg',alt:'review-3-7-2022'},
                {url:'/images/reviews/review-2-7-2022.jpg',alt:'review-2-7-2022'},
                {url:'/images/reviews/review-1-7-2022.jpg',alt:'review-1-7-2022'},    
                {url:'/images/reviews/review-30-6-2022.jpg',alt:'review-30-6-2022'},
                {url:'/images/reviews/review-29-6-2022.jpg',alt:'review-29-6-2022'},
                {url:'/images/reviews/review-28-6-2022.jpg',alt:'review-28-6-2022'},
                {url:'/images/reviews/review-27-6-2022.jpg',alt:'review-27-6-2022'},
                {url:'/images/reviews/review-26-6-2022.jpg',alt:'review-26-6-2022'},
                {url:'/images/reviews/review-25-6-2022.jpg',alt:'review-25-6-2022'},
                {url:'/images/reviews/review-24-6-2022.jpg',alt:'review-24-6-2022'},
                {url:'/images/reviews/review-23-6-2022.jpg',alt:'review-23-6-2022'},
                {url:'/images/reviews/review-22-6-2022.jpg',alt:'review-22-6-2022'},
                {url:'/images/reviews/review-21-6-2022.jpg',alt:'review-21-6-2022'},
                {url:'/images/reviews/review-20-6-2022.jpg',alt:'review-20-6-2022'},
                {url:'/images/reviews/review-19-6-2022.jpg?v1',alt:'review-19-6-2022'},
                {url:'/images/reviews/review-18-6-2022.jpg',alt:'review-18-6-2022'},
                {url:'/images/reviews/review-17-6-2022.jpg',alt:'review-17-6-2022'},
                {url:'/images/reviews/review-16-6-2022.jpg',alt:'review-16-6-2022'},
                {url:'/images/reviews/review-14-6-2022.jpg',alt:'review-14-6-2022'},
                {url:'/images/reviews/review-13-6-2022.jpg',alt:'review-13-6-2022'},
                {url:'/images/reviews/review-12-6-2022.jpg',alt:'review-12-6-2022'},
                {url:'/images/reviews/review-11-6-2022.jpg',alt:'review-11-6-2022'},
                {url:'/images/reviews/review-10-6-2022.jpg',alt:'review-10-6-2022'},
                {url:'/images/reviews/review-9-6-2022.jpg',alt:'review-9-6-2022'},
                {url:'/images/reviews/review-8-6-2022.jpg',alt:'review-8-6-2022'},
                {url:'/images/reviews/review-7-6-2022.jpg',alt:'review-7-6-2022'},
                {url:'/images/reviews/review-6-6-2022.jpg',alt:'review-6-6-2022'},
                {url:'/images/reviews/review-5-6-2022.jpg',alt:'review-5-6-2022'},
                {url:'/images/reviews/review-4-6-2022.jpg',alt:'review-4-6-2022'},
                {url:'/images/reviews/review-3-6-2022.jpg',alt:'review-3-6-2022'},
                {url:'/images/reviews/review-2-6-2022.jpg',alt:'review-2-6-2022'},
                {url:'/images/reviews/review-1-6-2022.jpg',alt:'review-1-6-2022'},
                {url:'/images/reviews/review-30-5-2022.jpg',alt:'review-30-5-2022'},
                {url:'/images/reviews/review-29-5-2022.jpg',alt:'review-29-5-2022'},
                {url:'/images/reviews/review-28-5-2022.jpg',alt:'review-28-5-2022'},
                {url:'/images/reviews/review-27-5-2022.jpg',alt:'review-27-5-2022'},
                {url:'/images/reviews/review-26-5-2022.jpg',alt:'review-26-5-2022'},
                {url:'/images/reviews/review-25-5-2022.jpg',alt:'review-25-5-2022'},
                {url:'/images/reviews/review-24-5-2022.jpg',alt:'review-24-5-2022'},
                {url:'/images/reviews/review-23-5-2022.jpg',alt:'review-23-5-2022'},
                {url:'/images/reviews/review-22-5-2022.jpg',alt:'review-22-5-2022'},
                {url:'/images/reviews/review-21-5-2022.jpg',alt:'review-21-5-2022'},
                {url:'/images/reviews/review-20-5-2022.jpg',alt:'review-20-5-2022'},
                {url:'/images/reviews/review-19-5-2022.jpg',alt:'review-19-5-2022'},
                {url:'/images/reviews/review-18-5-2022.jpg',alt:'review-18-5-2022'},
                {url:'/images/reviews/review-17-5-2022.jpg',alt:'review-17-5-2022'},
                {url:'/images/reviews/review-16-5-2022.jpg',alt:'review-16-5-2022'},
                {url:'/images/reviews/review-15-5-2022.jpg',alt:'review-15-5-2022'},
                {url:'/images/reviews/review-14-5-2022.jpg',alt:'review-14-5-2022'},
                {url:'/images/reviews/review-13-5-2022.jpg',alt:'review-13-5-2022'},
                {url:'/images/reviews/review-12-5-2022.jpg',alt:'review-12-5-2022'},
                {url:'/images/reviews/review-11-5-2022.jpg',alt:'review-11-5-2022'},
                {url:'/images/reviews/review-10-5-2022.jpg',alt:'review-10-5-2022'},
                {url:'/images/reviews/review-9-5-2022.jpg',alt:'review-9-5-2022'},
                {url:'/images/reviews/review-8-5-2022.jpg',alt:'review-8-5-2022'},
                {url:'/images/reviews/review-7-5-2022.jpg',alt:'review-7-5-2022'},
                {url:'/images/reviews/review-6-5-2022.jpg',alt:'review-6-5-2022'},
                {url:'/images/reviews/review-5-5-2022.jpg',alt:'review-5-5-2022'},
                {url:'/images/reviews/review-4-5-2022.jpg',alt:'review-4-5-2022'},
                {url:'/images/reviews/review-3-5-2022.jpg',alt:'review-3-5-2022'},
                {url:'/images/reviews/review-2-5-2022.jpg',alt:'review-2-5-2022'},
                {url:'/images/reviews/review-1-5-2022.jpg',alt:'review-1-5-2022'},
                {url:'/images/reviews/review-30-4-2022.jpg',alt:'review-30-4-2022'},
                {url:'/images/reviews/review-29-4-2022.jpg',alt:'review-29-4-2022'},
                {url:'/images/reviews/review-28-4-2022.jpg',alt:'review-28-4-2022'},
                {url:'/images/reviews/review-27-4-2022.jpg',alt:'review-27-4-2022'},
                {url:'/images/reviews/review-26-4-2022.jpg',alt:'review-26-4-2022'},
                {url:'/images/reviews/review-25-4-2022.jpg',alt:'review-25-4-2022'},
                {url:'/images/reviews/review-24-4-2022.jpg',alt:'review-24-4-2022'},
                {url:'/images/reviews/review-23-4-2022.jpg',alt:'review-23-4-2022'},
                {url:'/images/reviews/review-22-4-2022.jpg',alt:'review-22-4-2022'},
                {url:'/images/reviews/review-21-4-2022.jpg',alt:'review-21-4-2022'},
                {url:'/images/reviews/review-20-4-2022.jpg',alt:'review-20-4-2022'},
                {url:'/images/reviews/review-19-4-2022.jpg',alt:'review-19-4-2022'},
                {url:'/images/reviews/review-18-4-2022.jpg',alt:'review-18-4-2022'},
                {url:'/images/reviews/review-17-4-2022.jpg',alt:'review-17-4-2022'},
                {url:'/images/reviews/review-16-4-2022.jpg',alt:'review-16-4-2022'},
                {url:'/images/reviews/review-15-4-2022.jpg',alt:'review-15-4-2022'},
                {url:'/images/reviews/review-14-4-2022.jpg',alt:'review-14-4-2022'},
                {url:'/images/reviews/review-12-4-2022.jpg',alt:'review-12-4-2022'},
                {url:'/images/reviews/review-11-4-2022.jpg',alt:'review-11-4-2022'},
                {url:'/images/reviews/review-10-4-2022.jpg',alt:'review-10-4-2022'},
                {url:'/images/reviews/review-9-4-2022.jpg',alt:'review-9-4-2022'},
                {url:'/images/reviews/review-8-4-2022.jpg',alt:'review-8-4-2022'},
                {url:'/images/reviews/review-7-4-2022.jpg',alt:'review-7-4-2022'},
                {url:'/images/reviews/review-6-4-2022.jpg',alt:'review-6-4-2022'},
                {url:'/images/reviews/review-4-4-2022.jpg',alt:'review-4-4-2022'},
                {url:'/images/reviews/review-3-4-2022.jpg',alt:'review-3-4-2022'},
                {url:'/images/reviews/review-2-4-2022.jpg',alt:'review-2-4-2022'},
                {url:'/images/reviews/review-1-4-2022.jpg',alt:'review-1-4-2022'},
                {url:'/images/reviews/review-30-3-2022.jpg',alt:'review-30-3-2022'},
                {url:'/images/reviews/review-29-3-2022.jpg',alt:'review-29-3-2022'},
                {url:'/images/reviews/review-27-3-2022.jpg',alt:'review-27-3-2022'},
                {url:'/images/reviews/review-26-3-2022.jpg',alt:'review-26-3-2022'},
                {url:'/images/reviews/review-25-3-2022.jpg',alt:'review-25-3-2022'},
                {url:'/images/reviews/review-23-3-2022.jpg',alt:'review-23-3-2022'},
                {url:'/images/reviews/review-22-3-2022.jpg',alt:'review-22-3-2022'},
                {url:'/images/reviews/review-20-3-2022.jpg',alt:'review-20-3-2022'},
                {url:'/images/reviews/review-19-3-2022.jpg',alt:'review-19-3-2022'},
                {url:'/images/reviews/review-17-3-2022.jpg',alt:'review-17-3-2022'},
                {url:'/images/reviews/review-16-3-2022.jpg',alt:'review-16-3-2022'},
                {url:'/images/reviews/review-15-3-2022.jpg',alt:'review-15-3-2022'},
                {url:'/images/reviews/review-13-3-2022.jpg',alt:'review-13-3-2022'},
                {url:'/images/reviews/review-12-3-2022.jpg',alt:'review-12-3-2022'},
                {url:'/images/reviews/review-11-3-2022.jpg',alt:'review-11-3-2022'},
                {url:'/images/reviews/review-10-3-2022.jpg',alt:'review-10-3-2022'},
                {url:'/images/reviews/review-8-3-2022.jpg',alt:'review-8-3-2022'},
                {url:'/images/reviews/review-7-3-2022.jpg',alt:'review-7-3-2022'},
                {url:'/images/reviews/review-5-3-2022.jpg',alt:'review-5-3-2022'},
                {url:'/images/reviews/review-4-3-2022.jpg',alt:'review-4-3-2022'},
                {url:'/images/reviews/review-2-3-2022.jpg',alt:'review-2-3-2022'},
                {url:'/images/reviews/review-1-3-2022.jpg',alt:'review-1-3-2022'},
                {url:'/images/reviews/review-27-2-2022.jpg',alt:'review-27-2-2022'},
                {url:'/images/reviews/review-26-2-2022.jpg',alt:'review-26-2-2022'},
                {url:'/images/reviews/review-25-2-2022.jpg',alt:'review-25-2-2022'},
                {url:'/images/reviews/review-22-2-2022.jpg',alt:'review-22-2-2022'},
                {url:'/images/reviews/review-19-2-2022.jpg',alt:'review-19-2-2022'},
                {url:'/images/reviews/review-2022-2-17.jpg',alt:'review-2022-2-17'},
                {url:'/images/reviews/review-16-2-2022.jpg',alt:'review-16-2-2022'},
                {url:'/images/reviews/review-15-2-2022.jpg',alt:'review-15-2-2022'},
                {url:'/images/reviews/review-13-2-2022.jpg',alt:'review-13-2-2022'},
                {url:'/images/reviews/review-2022-2-11.jpg',alt:'review-2022-2-11'},
                {url:'/images/reviews/review-8-2-2022.jpg',alt:'review-8-2-2022'},
                {url:'/images/reviews/review-6-2-2022.jpg',alt:'review-6-2-2022'},
                {url:'/images/reviews/review-5-2-2022.jpg',alt:'review-5-2-2022'},
                {url:'/images/reviews/review-4-2-2022.jpg',alt:'review-4-2-2022'},
                {url:'/images/reviews/review-2-2-2022.jpg',alt:'review-2-2-2022'},
                {url:'/images/reviews/review-30-1-2022.jpg',alt:'review-30-1-2022'},
                {url:'/images/reviews/review-29-1-2022.jpg',alt:'review-29-1-2022'},
                {url:'/images/reviews/review-28-1-2022.jpg',alt:'review-28-1-2022'},
                {url:'/images/reviews/review-26-1-2022.jpg',alt:'review-26-1-2022'},
                {url:'/images/reviews/review-24-1-2022.jpg',alt:'review-24-1-2022'},
                {url:'/images/reviews/review-22-1-2022.jpg',alt:'review-22-1-2022'},
                {url:'/images/reviews/review-20-1-2022.jpg',alt:'review-20-1-2022'},
                {url:'/images/reviews/review-16-1-2022.jpg',alt:'review-16-1-2022'},
                {url:'/images/reviews/review-15-1-2022.jpg',alt:'review-15-1-2022'},
                {url:'/images/reviews/review-11-1-2022.jpg',alt:'review-11-1-2022'},
                {url:'/images/reviews/review-10-1-2022.jpg',alt:'review-10-1-2022'},
                {url:'/images/reviews/review-9-1-2022.jpg',alt:'review-9-1-2022'},
                {url:'/images/reviews/review-7-1-2022.jpg',alt:'review-7-1-2022'},
                {url:'/images/reviews/review-3-1-2022.jpg',alt:'review-3-1-2022'},
                {url:'/images/reviews/review-31-12-2021.jpg',alt:'review-31-12-2021'},
                {url:'/images/reviews/review-29-12-2021.jpg',alt:'review-29-12-2021'},
                {url:'/images/reviews/review-28-12-2021.jpg',alt:'review-28-12-2021'},
                {url:'/images/reviews/review-26-12-2021.jpg',alt:'review-26-12-2021'},
                {url:'/images/reviews/review-24-12-2021.jpg',alt:'review-24-12-2021'},
                {url:'/images/reviews/review-16-12-2021.jpg',alt:'review-16-12-2021'},
                {url:'/images/reviews/review-12-12-2021.jpg',alt:'review-12-12-2021'},
                {url:'/images/reviews/review-11-12-2021.jpg',alt:'review-11-12-2021'}*/
            ],
            showReviewAll:false
        }
        
     }
     

     switchYoutube = () =>{
         this.setState({showYoutube:!this.state.showYoutube})
     }

     selectPage = (page) =>{
        if(page==='contact'){
            window.open("https://lin.ee/NIDxu4g", "_blank");
        }else{

            window.scrollTo(0, 0);
            if(page!==this.state.selectPage){this.setState({selectPage : page})}
        }
    }
     
     submitForm = (event) => {
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'width=device-width,initial-scale=1');

        //axios.post(`http://localhost/member`,{username: event.target[0].value,password: event.target[1].value}).then(res =>{
        axios.post(`https://sv.aitded.com/member`,{username: event.target[0].value,password: event.target[1].value}).then(res =>{
            if(res.data.error===undefined){
                //console.log('login success')
                //console.log(res.data)
                store.dispatch({ 
                    type: 'login',
                    data: {...res.data,level:'member',pwd:event.target[1].value}
                });
                this.setState({
                    username : res.data.username
                })
            }else{
                alert('Username หรือ Password ไม่ถูกต้อง')
                //console.log('login no success')
                store.dispatch({ 
                    type: 'logout',
                    data: {username : undefined, expire : undefined,level : undefined, pwd:undefined, gacha : 0,listGachaItem : undefined,exp : 0}
                });
                this.setState({
                    username : null
                })
            }
        })
        event.preventDefault();
     }

     showreviewall = () =>{
         this.setState({showReviewAll:true})
     }

     render() {
        //console.log(store.getState())
         //console.log(this.state)

         let snowfallItem = [];
         for(var i = 0; i < 20; i++) {
            let randNum = Math.floor(Math.random() * 100);
            snowfallItem.push(<snowflake><img src={`images/snowfall/${randNum+1}.png`} className='itemfall' /></snowflake>);
        }
         return (
                store.getState().username!==undefined&&store.getState().level==='member'?<Navigate to={{ pathname: "/member"}} />:
                <div className='homeInfo'>
                    {/* s fall */}
                    
                    <snowfall>
                        {snowfallItem.map((item,index)=>{
                            return(
                                snowfallItem[index]
                            )
                        })}
                        {/*<snowflake><img src="images/snowfall/1.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/2.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/3.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/4.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/5.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/6.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/7.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/8.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/9.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/10.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/11.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/12.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/13.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/14.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/15.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/16.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/17.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/18.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/19.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/20.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/21.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/22.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/23.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/24.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/25.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/26.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/27.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/28.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/29.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/30.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/31.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/32.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/33.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/34.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/35.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/36.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/37.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/38.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/39.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/40.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/41.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/42.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/43.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/44.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/45.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/46.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/47.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/48.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/49.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/50.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/51.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/52.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/53.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/54.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/55.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/56.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/57.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/58.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/59.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/60.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/61.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/62.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/63.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/64.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/65.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/66.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/67.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/68.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/69.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/70.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/71.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/72.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/73.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/74.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/75.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/76.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/77.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/78.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/79.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/80.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/81.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/82.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/83.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/84.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/85.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/86.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/87.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/88.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/89.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/90.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/91.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/92.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/93.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/94.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/95.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/96.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/97.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/98.png" className='itemfall' /></snowflake>
                        <snowflake><img src="images/snowfall/99.png" className='itemfall' /></snowflake>*/}
                    </snowfall>
                    {/* e fall */}
                <div className="main-container">
                        {/*<video autoPlay muted loop="yes" id="myVideo">
                            <source src="/videos/bg_video.mp4" type="video/mp4" width="100%" />
                        </video>*/}
                    <div className="mainHomepage">
                    <div className="video_poster"><video controls className="configVDO" poster="https://aitded.com/images/new-poster-aitded.jpg" src="https://aitded.b-cdn.net/vdo_promote_aitded_hl.mp4"></video></div>

                    
                    <div className="main-login contentSalePage cardBorder">
                        <div className="row">                    
                            <div className="space-6"></div>
                            <div className="login-box">
                                <div className="box">
                                    <h1 className="text-gradient">AITDED.COM</h1>
                                    <form onSubmit={this.submitForm} id="chkLogin" method="post" role="form">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="usr" placeholder="username" required />          
                                        </div>
                                        <div className="form-group" style={{marginTop: '15px',marginBottom: '15px'}}>
                                            <input type="password" className="form-control" name="pwd" placeholder="password" required />
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12 col-xs-12">
                                                <button type="submit" value="Submit" className="btn btn-gradient-border btn-glow btnHomeform">เข้าสู่ระบบ</button>
                                                <a href="https://lin.ee/NIDxu4g" rel="nofollow noopener" target="_blank"><span className="btn btn-gradient-border btn-glow btnHomeform">ขอเข้าร่วม</span></a>
                                            </div>
                                        </div>
                                    </form>
                                    {/*<button className="btn btn-gradient-border btn-gacha btn-glow btnHomeform seeBtnYoutube" onClick={this.switchYoutube}>{this.state.showYoutube?'ซ่อน':'ดู'} VDO รีวิวการใช้สูตรบอล คลิ๊ก !!</button>*/}
                                    {this.state.showYoutube?
                                    <div className="video_poster youtubeHowtoUse">
                                    <iframe width="800" height="350" src="https://www.youtube.com/embed/M2ZOve3WlSY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                           
                                    </div>
                                    :''}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    

                    <div className='reviewZone'>
                        <LazyLoadImage src={`/images/promotion_member.webp`} alt={`promotion member`} className="shake" style={{maxWidth:"90%",margin:"10px auto"}}/>
                    </div>
                    </div>

                    <a href="https://lin.ee/NIDxu4g" rel="nofollow noopener" target="_blank"><LazyLoadImage src='/images/qrcode.png' className="btn btn-gradient-border btn-glow" style={{maxWidth:'200px',maxHeight:'200px',padding:'10px'}} /></a>
                    <div className="creditFooter" style={{paddingTop:'15px',color:'white',textAlign:'center'}}> ติดต่อขอรับสูตรฟรี แอดไลน์ : @aitded หรือคลิ๊กที่ปุ่มขอเข้าร่วม</div>
                </div>
                 {/*<div className='reviewZone'>
                   <p className='text-center reviewHeading'>รีวิวบางส่วนจากสูตรของเรา</p>
                {this.state.review.slice(0,9).map((item,index)=>{
                    return(
                        <LazyLoadImage key={index} src={item.url} alt={item.alt} className="reviewTdedImg seeBtnYoutube" />
                    )
                })}
                <div style={{clear:'both'}}></div>
                {this.state.showReviewAll?this.state.review.slice(9).map((item,index)=>{
                    return(
                        <LazyLoadImage key={item.alt} src={item.url} alt={item.alt} className="reviewTdedImg seeBtnYoutube" />
                    )
                }):''}
                {this.state.showReviewAll?'':<button onClick={this.showreviewall} className="btn btn-gradient-border btn-gacha btn-glow btnHomeform seeBtnYoutube">ดูรีวิวทั้งหมด คลิ้ก !!</button>}
                </div>*/}


                {/*<div class="footer-navbar">
                        <a href="https://lin.ee/NIDxu4g" rel="nofollow noreferrer" target="_blank" class="menuFooter1"><span>ติดต่อเรา @AITDED</span></a>
                    </div>*/}

                    <div className="FooterbarMember">
                        {/*<div className="nav-footer menuFooter1" onClick={(e) =>this.selectPage(undefined)} ><span className="text-footermenu">หน้าหลัก</span></div>
                        <div className="nav-footer menuFooter2" onClick={(e) =>this.selectPage('gacha')} ><span className="text-footermenu">สุ่มกาชา</span></div>
                        <div className="nav-footer menuFooter3" onClick={(e) =>this.selectPage('ball')} ><span className="text-footermenu">สูตรบอล</span></div>
                        <div className="nav-footer menuFooter4" onClick={(e) =>this.selectPage('contact')} ><span className="text-footermenu">ติดต่อเรา</span></div>*/}
                        {/*<div className="nav-footer"><span onClick={(e) =>this.selectPage('casino')} className="btn btn-gradient-border btn-glow">คาสิโน</span></div>
                        <div className="nav-footer"><span onClick={(e) =>this.selectPage('slot')} className="btn btn-gradient-border btn-glow">สูตรสล็อต</span></div>*/}
                        <div className="nav-footer menuFooter5" onClick={(e) =>alert('โปรดเข้าสู่ระบบ')} ><div className='iconMobileFooterMenu'><img className='iconY_anim' src='/images/icon/menu_1_rank.webp' /></div><br/><span className="text-footermenu">พิเศษ</span></div>
                        <div className="nav-footer menuFooter2" onClick={(e) =>alert('โปรดเข้าสู่ระบบ')} ><div className='iconMobileFooterMenu'><img className='iconY_anim'  src='/images/icon/menu_1_sudball.webp' /></div><br/><span className="text-footermenu">สูตรบอล</span></div>
                        <div className="nav-footer menuFooter1" onClick={(e) =>this.selectPage(undefined)} ><div className='iconMobileFooterMenu'><img src='/images/icon/menu_1_home.webp' className='imageRotate' /></div><br/><span className="text-footermenu">AITDED</span></div>
                        <div className="nav-footer menuFooter4" onClick={(e) =>alert('โปรดเข้าสู่ระบบ')} ><div className='iconMobileFooterMenu'><img className='iconY_anim'  src='/images/icon/menu_1_gift.webp' /></div><br/><span className="text-footermenu">สุ่มกาชา</span></div>
                        <div className="nav-footer menuFooter3" onClick={(e) =>this.selectPage('contact')} ><div className='iconMobileFooterMenu'><img className='iconY_anim'  src='/images/icon/menu_1_chat.webp' /></div><br/><span className="text-footermenu">เข้าร่วม</span></div>
                    </div>
            </div>
         )
     }
}

export default Home;
