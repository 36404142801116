import React from 'react'
import store from '../../store/configstore'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'


class RealtimegachaEmbed extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            listRealtimeGacha : [],
            listGacha : [

                /*
                back up
                {code : 0, name : 'ชิ้นส่วน เครดิตฟรี 10,000 บาท',full : 20,img : 'chip-10k.png'},
                {code : 1, name : 'ชิ้นส่วน เครดิตฟรี 5,000 บาท',full : 20,img : 'chip-5k.png'},
                {code : 2, name : 'ชิ้นส่วน เครดิตฟรี 2,000 บาท',full : 20,img : 'chip-2k.png'},
                {code : 3, name : 'ชิ้นส่วน เครดิตฟรี 1,000 บาท',full : 20,img : 'chip-1k.png'},
                {code : 4, name : 'ชิ้นส่วน เครดิตฟรี 800 บาท',full : 20,img : 'chip-800.png'},
                {code : 5, name : 'ชิ้นส่วน เครดิตฟรี 500 บาท',full : 20,img : 'chip-500.png'},
                {code : 6, name : 'ชิ้นส่วน เครดิตฟรี 300 บาท',full : 20,img : 'chip-300.png'},
                {code : 7, name : 'ชิ้นส่วน เครดิตฟรี 200 บาท',full : 20,img : 'chip-200.png'},
                {code : 8, name : 'ชิ้นส่วน เครดิตฟรี 100 บาท',full : 20,img : 'chip-100.png'},
                {code : 9, name : 'ชิ้นส่วน ไข่ที่แตกแล้ว',full : 3,img : 'chip-10k.png'}
                */

                {code : 0, name : 'ชิ้นส่วน Iphone 13 6.1 นิ้ว 128GB มูลค่า 29,990 บาท',full : 100,img : 'iphone-13.png'},
                {code : 1, name : 'ชิ้นส่วน MacBook Air รุ่น 13 นิ้ว 256GB มูลค่า 29,600 บาท',full : 100,img : 'MacBook-Air-13-นิ้ว.png'},
                {code : 2, name : 'ชิ้นส่วน ทองคำ 1 บาท มูลค่า 28,500 บาท',full : 100,img : 'gold.png'},
                {code : 3, name : 'ชิ้นส่วน Sumsung Galaxy Z Fold3 5g มูลค่า 57,900 บาท',full : 100,img : 'galaxy-z-fold3-5g.png'},
                {code : 4, name : 'ชิ้นส่วน Apple Watch Series 7 มูลค่า 13,900 บาท',full : 50,img : 'Apple-Watch-Series-7.png'},
                {code : 5, name : 'ชิ้นส่วน Honda New PCX 160 มูลค่า 86,900 บาท',full : 200,img : 'new-pcx-160.png'},
                {code : 6, name : 'ชิ้นส่วน Sony X80H 4K UltraHD 43 นิ้ว มูลค่า 17,490 บาท',full : 50,img : 'Sony-X80H-4K-Ultra-HD-43-นิ้ว.png'},
                {code : 7, name : 'ชิ้นส่วน AirPods Pro มูลค่า 8,992 บาท',full : 50,img : 'AirPods-Pro.png'},
                {code : 8, name : 'ชิ้นส่วน ทองคำ 50 สตางค์ มูลค่า 14,250 บาท',full : 100,img : 'gold-50.png'},
                {code : 9, name : 'ชิ้นส่วน Ps5 Blu Ray Edition มูลค่า 16,990 บาท',full : 75,img : 'ps5-Blu-Ray-Edition.png'},
                {code : 10, name : 'ชิ้นส่วน เงินสด มูลค่า 10,000 บาท',full : 50,img : 'money-10k.png'},
                {code : 11, name : 'ชิ้นส่วน เงินสด มูลค่า 5,000 บาท',full : 30,img : 'money-5k.png'},
                {code : 12, name : 'ชิ้นส่วน เงินสด มูลค่า 2,000 บาท',full : 30,img : 'money-2k.png'},
                {code : 13, name : 'ชิ้นส่วน เงินสด มูลค่า 1,000 บาท',full : 30,img : 'money-1k.png'},
                {code : 14, name : 'ชิ้นส่วน เครดิตฟรี 500 บาท',full : 30,img : 'credit-500.png'},
                {code : 15, name : 'ชิ้นส่วน เครดิตฟรี 400 บาท',full : 30,img : 'credit-400.png'},
                {code : 16, name : 'ชิ้นส่วน เครดิตฟรี 300 บาท',full : 30,img : 'credit-300.png'},
                {code : 17, name : 'ชิ้นส่วน เครดิตฟรี 200 บาท',full : 30,img : 'credit-200.png'},
                {code : 18, name : 'ชิ้นส่วน เครดิตฟรี 100 บาท',full : 30,img : 'credit-100.png'},
                {code : 19, name : 'ชิ้นส่วน ไข่ที่แตกแล้ว',full : 500,img : 'egg-open.gif'},
                {code : 20, name : 'ชิ้นส่วน iPad Pro 12.9 นิ้ว 128GB มูลค่า 34,500 บาท',full : 100,img : 'ipad-pro-12-9.png'},
                {code : 21, name : 'ชิ้นส่วน Nintendo Switch OLED Model มูลค่า 14,990 บาท',full : 50,img : 'Nintendo-Switch-OLED-Model.png'},
                {code : 22, name : 'ชิ้นส่วน iMac Retina 5K รุ่น 27 นิ้ว มูลค่า 69,900 บาท',full : 150,img : 'iMac-Retina-5K-27-inc.png'},
                {code : 23, name : 'ชิ้นส่วน ทองคำ 25 สตางค์ มูลค่า 7,125 บาท',full : 50,img : 'gold-25-satang.png'}
            ],
        }
    }

    callApiLastGacha = () =>{
        axios.get(`https://sv.aitded.com/logsgacha`).then(res =>{
            console.log(res.data)
            if(res.data.error===undefined){
                this.setState({listRealtimeGacha:res.data})
            }
        })
    }
    
    componentDidMount(){
        this.callApiLastGacha()
        setInterval(this.callApiLastGacha, 300000); // รันตารางใหม่ทุก 5 นาที
    }

    componentWillUnmount(){
        clearInterval(this.callApiLastGacha)
    }

    render() {
        console.log(this.state.listRealtimeGacha)
        return (
            <div>
                <div className='boxRealtimeRewardEmbed'>
                
                {this.state.listRealtimeGacha.length>0?
                this.state.listRealtimeGacha.map((item,index)=>{
                    return(
                        <div key={index}>
                            {item.thaitime.substr(11,8)} : {item.user.substr(0,(item.user.length-2) )}xx<br/>ได้รับ {this.state.listGacha[this.state.listGacha.findIndex(({code}) => code === item.reviceGachaCode)].name}
                            <LazyLoadImage src={`/images/reward/${this.state.listGacha[this.state.listGacha.findIndex(({code}) => code === item.reviceGachaCode)].img}`} alt={this.state.listGacha[this.state.listGacha.findIndex(({code}) => code === item.reviceGachaCode)].name} className='imgRealtimeReward'/>
                        </div>
                    )
                })
                :''}
                </div>
            </div>
        )
    }
}

export default RealtimegachaEmbed;