import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Toprankslot extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            listGames : [
                {name:'Mahjong Ways',rtp:'96.92',maxwin:'25000',img:'Mahjong-Ways.png',urlDemo:'65',urlPlay:''},
                {name:'Mahjong Ways 2',rtp:'96.95',maxwin:'100000',img:'Mahjong-Ways-2.png',urlDemo:'74',urlPlay:''},
                {name:'Wild Bandito',rtp:'96.73',maxwin:'25000',img:'Wild-Bandito.png',urlDemo:'104',urlPlay:''},
                {name:'Bali Vacation',rtp:'96.71',maxwin:'100000',img:'Bali-Vacation.png',urlDemo:'94',urlPlay:''},
                {name:'Majestic Treasures',rtp:'96.68',maxwin:'50000',img:'Majestic-Treasures.png',urlDemo:'95',urlPlay:''},
                {name:'Candy Bonanza',rtp:'96.72',maxwin:'50000',img:'Candy-Bonanza.png',urlDemo:'100',urlPlay:''},
                {name:'Queen of Bounty',rtp:'96.74',maxwin:'50000',img:'Queen-of-Bounty.png',urlDemo:'84',urlPlay:''},
                {name:'Secrets of Cleopatra',rtp:'96.74',maxwin:'100000',img:'Secrets-of-Cleopatra.png',urlDemo:'90',urlPlay:''},
                {name:'Genie 3 Wishes',rtp:'96.75',maxwin:'100000',img:'Genie-3-Wishes.png',urlDemo:'85',urlPlay:''},
                {name:'Phoenix Rises',rtp:'96.7',maxwin:'20000',img:'Phoenix-Rises.png',urlDemo:'82',urlPlay:''},
                {name:'Candy Burst',rtp:'96.95',maxwin:'15000',img:'Candy-Burst.png',urlDemo:'70',urlPlay:''},
                {name:'Shaolin Soccer',rtp:'96.93',maxwin:'4000',img:'Shaolin-Soccer.png',urlDemo:'67',urlPlay:''},
                {name:'Vampire Charm',rtp:'96.75',maxwin:'20000',img:'Vampire-Charm.png',urlDemo:'58',urlPlay:''},
                {name:'Jack Frost Winter',rtp:'96.7',maxwin:'100000',img:'Jack-Frost-Winter.png',urlDemo:'97',urlPlay:''},
                {name:'Guardians of Ice & Fire',rtp:'96.7',maxwin:'50000',img:'Guardians-of-Ice-and-Fire.png',urlDemo:'91',urlPlay:''},
                {name:'Bikini Paradise',rtp:'96.5',maxwin:'100000',img:'Bikini-Paradise.png',urlDemo:'69',urlPlay:''},
                {name:'Reel Love',rtp:'96.96',maxwin:'30000',img:'Reel-Love.png',urlDemo:'20',urlPlay:''},
                {name:'Fortune Mouse',rtp:'96.96',maxwin:'1000',img:'Fortune-Mouse.png',urlDemo:'68',urlPlay:''},
                {name:'Dragon Hatch',rtp:'96.83',maxwin:'15000',img:'Dragon-Hatch.png',urlDemo:'57',urlPlay:''},
                {name:'Dragon Tiger Luck',rtp:'96.94',maxwin:'200',img:'Dragon-Tiger-Luck.png',urlDemo:'63',urlPlay:''},
                {name:'Muay Thai Champion',rtp:'97.38',maxwin:'15000',img:'Muay-Thai-Champion.png',urlDemo:'64',urlPlay:''},
                {name:'Leprechaun Riches',rtp:'97.35',maxwin:'100000',img:'Leprechaun-Riches.png',urlDemo:'60',urlPlay:''},
                {name:'Double Fortune',rtp:'96.22',maxwin:'100000',img:'Double-Fortune.png',urlDemo:'48',urlPlay:''},
                {name:'The Great Icescape',rtp:'96.32',maxwin:'50000',img:'The-Great-Icescape.png',urlDemo:'53',urlPlay:''},
                {name:'Jungle Delight',rtp:'96.03',maxwin:'15000',img:'Jungle-Delight.png',urlDemo:'40',urlPlay:''},
                {name:'Captain Bounty',rtp:'96.15',maxwin:'30000',img:'Captain-Bounty.png',urlDemo:'54',urlPlay:''},
                {name:'Flirting Scholar',rtp:'97.44',maxwin:'22500',img:'Flirting-Scholar.png',urlDemo:'61',urlPlay:''},
                {name:'Ganesha Gold',rtp:'96.08',maxwin:'100000',img:'Ganesha-Gold.png',urlDemo:'42',urlPlay:''},
                {name:'Symbols of Egypt',rtp:'95.71',maxwin:'1800',img:'Symbols-of-Egypt.png',urlDemo:'41',urlPlay:''},
                {name:'Win Win Won',rtp:'94.14',maxwin:'2600',img:'Win-Win-Won.png',urlDemo:'24',urlPlay:''},
                {name:'Gem Saviour Sword',rtp:'95.54',maxwin:'400',img:'Gem-Saviour-Sword.png',urlDemo:'38',urlPlay:''},
                {name:'Ninja vs Samurai',rtp:'97.44',maxwin:'15000',img:'Ninja-vs-Samurai.png',urlDemo:'59',urlPlay:''},
                {name:'Dragon Legend',rtp:'97.15',maxwin:'3000',img:'Dragon-Legend.png',urlDemo:'29',urlPlay:''},
                {name:'Mermaid Riches',rtp:'96.71',maxwin:'20000',img:'Mermaid-Riches.png',urlDemo:'102',urlPlay:''},
                {name:'Groundhog Harvest',rtp:'96.71',maxwin:'50000',img:'Groundhog-Harvest.png',urlDemo:'111',urlPlay:''},
                {name:'Sushi Oishi',rtp:'96.71',maxwin:'50000',img:'Sushi-Oishi.png',urlDemo:'109',urlPlay:''},
                {name:'Fortune Ox',rtp:'96.75',maxwin:'2000',img:'Fortune-Ox.png',urlDemo:'98',urlPlay:''},
                {name:'Galactic Gems',rtp:'96.74',maxwin:'50000',img:'Galactic-Gems.png',urlDemo:'86',urlPlay:''},
                {name:'Circus Delight',rtp:'96.7',maxwin:'100000',img:'Circus-Delight.png',urlDemo:'80',urlPlay:''},
                {name:'Wild Fireworks',rtp:'96.75',maxwin:'20000',img:'Wild-Fireworks.png',urlDemo:'83',urlPlay:''},
                {name:'Emperor Favour',rtp:'96.03',maxwin:'10000',img:'Emperor-Favour.png',urlDemo:'44',urlPlay:''},
                {name:'Three Monkeys',rtp:'96.14',maxwin:'1800',img:'Three-Monkeys.png',urlDemo:'43',urlPlay:''},
                {name:'Journey To The Wealth',rtp:'96.39',maxwin:'8000',img:'Journey-To-The-Wealth.png',urlDemo:'50',urlPlay:''},
                {name:'Fortune Gods',rtp:'95.04',maxwin:'3600',img:'Fortune-Gods.png',urlDemo:'3',urlPlay:''},
                {name:'Santa Gift Rush',rtp:'96.36',maxwin:'9000',img:'Santa-Gift-Rush.png',urlDemo:'37',urlPlay:''},
                {name:'Hip Hop Panda',rtp:'95.75',maxwin:'600',img:'Hip-Hop-Panda.png',urlDemo:'33',urlPlay:''},
                {name:'Hood vs Wolf',rtp:'95.39',maxwin:'4000',img:'Hood-vs-Wolf.png',urlDemo:'18',urlPlay:''},
                {name:'Hotpot',rtp:'95.83',maxwin:'15000',img:'Hotpot.png',urlDemo:'28',urlPlay:''},
                {name:'Gem Saviour',rtp:'96.82',maxwin:'500',img:'Gem-Saviour.png',urlDemo:'2',urlPlay:''},
                {name:'Plushie Frenzy',rtp:'94.82',maxwin:'10000',img:'Plushie-Frenzy.png',urlDemo:'25',urlPlay:''},
                {name:'Farm Invaders',rtp:'96.68',maxwin:'20000',img:'Farm-Invaders.png',urlDemo:'116',urlPlay:''},
                {name:'Mask Carnival',rtp:'96.7',maxwin:'20000',img:'Mask-Carnival.png',urlDemo:'118',urlPlay:''},
                {name:'Spirited Wonders',rtp:'96.7',maxwin:'50000',img:'Spirited-Wonders.png',urlDemo:'119',urlPlay:''},
                {name:'Supermarket Spree',rtp:'96.71',maxwin:'25000',img:'Supermarket-Spree.png',urlDemo:'115',urlPlay:''},
                {name:'Raider Jane Crypt of Fortune',rtp:'96.75',maxwin:'50000',img:'Raider-Jane-Crypt-of-Fortune.png',urlDemo:'113',urlPlay:''},
                {name:'Piggy Gold',rtp:'95.86',maxwin:'50000',img:'Piggy-Gold.png',urlDemo:'39',urlPlay:''},
                {name:'Prosperity Lion',rtp:'95.77',maxwin:'800',img:'Prosperity-Lion.png',urlDemo:'36',urlPlay:''},
                {name:'Legend of Hou Yi',rtp:'95.78',maxwin:'5000',img:'Legend-of-Hou-Yi.png',urlDemo:'34',urlPlay:''},
                {name:'Mr. Hallow Win',rtp:'95.86',maxwin:'5000',img:'Mr-Hallow-Win.png',urlDemo:'35',urlPlay:''},
                {name:'Honey Trap of Diao Chan',rtp:'96.96',maxwin:'100000',img:'Honey-Trap-of-Diao-Chan.png',urlDemo:'1',urlPlay:''}
            ],
            connMobile : this.props.connMobile
        }
    }
    componentDidMount(){
        let listForSet = this.state.listGames
        listForSet.sort((a, b) => b.maxwin - a.maxwin);
        this.setState({listGames:listForSet})
    }

    sortList = (select,list) =>{
        let listForSet = list
        if(select == 'rtp'){
            listForSet.sort((a, b) => b.rtp - a.rtp);
            this.setState({listGames:listForSet})
        }else if(select == 'maxwin'){
            listForSet.sort((a, b) => b.maxwin - a.maxwin);
            this.setState({listGames:listForSet})
        }
    }

    render() {
        //urlDemo = `https://m.pg-demo.com/114/index.html?l=en-US&btt=2&ot=8735ze6y8kp7jpwmxvau7gvytu3adwj4&f=https%3A%2F%2Fpublic.pg-redirect.com%2Fpages%2Fclose.html&__refer=m.pg-redirect.com&__sv=0`
        return (
            <div>
                <div className="mainMemberPage">
                    <div className="boxls" style={{textAlign:'center'}}>
                        <div className="selectShowSlot text-center">
                            <div>
                            <button className="btnMini btn-glow btn-primary" onClick={(e)=>{this.sortList('rtp',this.state.listGames)}}>เรียงตาม RTP</button>
                            </div>
                            <div>
                            <button className="btnMini btn-glow btn-primary" onClick={(e)=>{this.sortList('maxwin',this.state.listGames)}}>เรียงตาม Max Win</button>
                            </div>
                        </div>
                        {this.state.listGames.map((item,index) =>{
                            return(
                                this.props.connMobile===false?
                                <div className="rowSlotGame fading" key={index} style={index<3?{color:'#26e974'}:index<6?{color:'#ffc150'}:this.state.listGames.length-3<=index?{color:'#ff1349'}:{}}>
                                    <div className="col-6"><LazyLoadImage src={`https://aitded.com/images/slots/${item.img}`} className="iconSlotgame"/></div>
                                    <div className="col-6">Name : {item.name}<br/>Rate : {item.rtp}%<br/>Max Win : X{item.maxwin}</div>
                                    {/*<div className="col-6 text-center my-5">
                                        <a href={`https://m.pg-demo.com/${item.urlDemo}/index.html?l=en-US&btt=2&ot=8735ze6y8kp7jpwmxvau7gvytu3adwj4&f=https%3A%2F%2Fpublic.pg-redirect.com%2Fpages%2Fclose.html&__refer=m.pg-redirect.com&__sv=0`} target="_blank" rel="nofollow noreferrer"><span className="btn-glow demobtnSlot" >ทดลองเล่น</span></a>
                                        
                                    </div>*/}
                                    <div className="col-12 text-center my-5">
                                        <a href={`https://bonus55bet.com`} className="btn-glow" target="_blank" rel="nofollow noreferrer"><span className='demobtnSlot'>สมัครเล่น</span></a>
                                    </div>
                                </div>:
                                <div className="rowSlotGame fading" key={index} style={index<3?{color:'#26e974'}:index<6?{color:'#ffc150'}:this.state.listGames.length-3<=index?{color:'#ff1349'}:{}}>
                                <div className="col-6"><LazyLoadImage src={`https://aitded.com/images/slots/${item.img}`} className="iconSlotgame"/></div>
                                <div className="col-6">Name : {item.name}<br/>Rate : {item.rtp}%<br/>Max Win : X{item.maxwin}</div>
                                {/*<div className="col-6 text-center my-5">
                                    <a href={`https://m.pg-demo.com/${item.urlDemo}/index.html?l=en-US&btt=2&ot=8735ze6y8kp7jpwmxvau7gvytu3adwj4&f=https%3A%2F%2Fpublic.pg-redirect.com%2Fpages%2Fclose.html&__refer=m.pg-redirect.com&__sv=0`} target="_blank" rel="nofollow noreferrer"><span className="btn-glow demobtnSlot" >ทดลองเล่น</span></a>
                                    
                                </div>*/}
                                <div className="col-12 text-center my-5">
                                    <a href={`https://bonus55bet.com`} className="btn-glow" target="_blank" rel="nofollow noreferrer"><span className='demobtnSlot'>สมัครเล่น</span></a>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
                <div className="note">
                    <h2>รู้จัก RTP และ Max Win เกมสล็อต</h2>
                    <p>RTP ย่อมาจาก "Return to player" แปลตามตัวคือการจ่ายคืนแก่ผู้เล่น ในเกมสล็อตนั้นค่า RTP คือการจ่ายคืนแก่ผู้เล่นเป็นค่าเฉลี่ย หากเกมไหนมีค่า RTP สูง แปลว่าเกมนั้นจะมีค่าเฉลี่ยจ่ายคืนสูง เช่นมีค่า RTP = 95% หากผู้เล่นเดิมพันรวมๆ 100 บาท การปันรางวัลของสล็อตนั้นจะมีค่าเฉลี่ยอยู่ที่ 95 บาทเป็นต้น ทั้งนี้ 
                        ค่า RTP ไม่ได้เป็นตัวการันตีว่าต้องจ่ายออกเท่านั้นทุกๆครั้งที่หมุน เป็นเพียงค่าเฉลี่ยเท่านั้นซึ่งจะมีกฎหมายควบคุมอัตราจ่ายออก RTP กำกับไว้อยู่เพื่อไม่ให้เกมสล็อตมีการเอาเปรียบผู้เล่นจนเกินไป
                    </p>
                    <p>สำหรับ Max Win ในตารางข้างต้นคืออัตราจ่ายสูงสุดที่เป็นไปได้ต่อการหมุน 1 ครั้ง เราอาจเห็นว่ามีการจ่ายสูงสุดหลักหมื่นเท่าก็มี ซึ่งมาจากการได้คูณฟรีไปเรื่อยๆซึ่งหลายเกมสล็อตจะมีโอกาสออกแบบนี้ ยิ่งเกมไหนมีเรท Max Win สูง เงินรางวัลย่อยมักไม่เยอะนักและจะออกยากกว่าเกมที่มี Max Win ต่ำ แต่หากผู้เล่นโชคดี 
                        อาจได้รางวัล Max Win หรือคูณเบิ่ลๆเกือบเท่าเรทสูงสุด จากการหมุนเพียงไม่กี่ครั้งก็เป็นได้
                    </p>
                </div>
            </div>
        )
    }
            
}

export default Toprankslot;
