import logo from './logo.svg';
import React from 'react'
import Home from './pages/Home'
import Member from './pages/Member'
import Homeadmin from './pages/Homeadmin'
import RealtimegachaEmbed from './pages/embed/RealtimegachaEmbed'
import Adminrockstar from './pages/Adminrockstar'
import Maintenance from './pages/Maintenance'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import './App.css';
import store from './store/configstore'

function App() {
  
  return (
    <>
    <div className="App"><div className="inApp">
      
    <Router>
      <Routes>

      <Route path="/member" element={<Member />} />

      <Route path="/homeadmin_rockstar" element={<Homeadmin />} />

      <Route path="/admin_rockstar" element={<Adminrockstar />} />
        
      <Route path="/embed/realtimegacha" element={<RealtimegachaEmbed />} />

      <Route path="/" element={<Home />} />
      
      {/* <Route path="/" element={<Maintenance />} /> */}

      </Routes>
    </Router>
    </div>

      
    </div>

    
    </>
  );
}

export default App;
