import React,{ useState,useEffect , useRef} from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../Snowfall.css';

const Lotto = () => {

    let listLotto = [
        {img:'16-12-2022-v3.jpg',alt:'16 ธันวาคม 2565 3',content:'16 ธันวาคม 2565'},
        {img:'16-12-2022-v2.jpg',alt:'16 ธันวาคม 2565 2',content:'16 ธันวาคม 2565'},
        {img:'16-12-2022-v1.jpg',alt:'16 ธันวาคม 2565 1',content:'16 ธันวาคม 2565'},
        {img:'1-12-2022-v3.jpg',alt:'1 ธันวาคม 2565 3',content:'1 ธันวาคม 2565'},
        {img:'1-12-2022-v2.jpg',alt:'1 ธันวาคม 2565 2',content:'1 ธันวาคม 2565'},
        {img:'1-12-2022-v1.jpg',alt:'1 ธันวาคม 2565 1',content:'1 ธันวาคม 2565'},
        {img:'16-11-2022-v3.jpg',alt:'16 พฤศจิกายน 2565 3',content:'16 พฤศจิกายน 2565'},
        {img:'16-11-2022-v2.jpg',alt:'16 พฤศจิกายน 2565 2',content:'16 พฤศจิกายน 2565'},
        {img:'16-11-2022-v1.jpg',alt:'16 พฤศจิกายน 2565 1',content:'16 พฤศจิกายน 2565'},
        {img:'1-11-2022-v3.jpg',alt:'1 พฤศจิกายน 2565 3',content:'1 พฤศจิกายน 2565'},
        {img:'1-11-2022-v2.jpg',alt:'1 พฤศจิกายน 2565 2',content:'1 พฤศจิกายน 2565'},
        {img:'1-11-2022-v1.jpg',alt:'1 พฤศจิกายน 2565 1',content:'1 พฤศจิกายน 2565'},
        {img:'16-10-2022-v3.jpg',alt:'16 ตุลาคม 2565 3',content:'16 ตุลาคม 2565'},
        {img:'16-10-2022-v2.jpg',alt:'16 ตุลาคม 2565 2',content:'16 ตุลาคม 2565'},
        {img:'16-10-2022-v1.jpg',alt:'16 ตุลาคม 2565 1',content:'16 ตุลาคม 2565'},
        {img:'1-10-2022-v3.jpg',alt:'1 ตุลาคม 2565 3',content:'1 ตุลาคม 2565'},
        {img:'1-10-2022-v2.jpg',alt:'1 ตุลาคม 2565 2',content:'1 ตุลาคม 2565'},
        {img:'1-10-2022-v1.jpg',alt:'1 ตุลาคม 2565 1',content:'1 ตุลาคม 2565'},
        {img:'16-9-2022-v3.jpg',alt:'16 กันยายน 2565 3',content:'16 กันยายน 2565'},
        {img:'16-9-2022-v2.jpg',alt:'16 กันยายน 2565 2',content:'16 กันยายน 2565'},
        {img:'16-9-2022-v1.jpg',alt:'16 กันยายน 2565 1',content:'16 กันยายน 2565'},
        {img:'1-9-2022-v3.jpg',alt:'1 กันยายน 2565 3',content:'1 กันยายน 2565'},
        {img:'1-9-2022-v2.jpg',alt:'1 กันยายน 2565 2',content:'1 กันยายน 2565'},
        {img:'1-9-2022-v1.jpg',alt:'1 กันยายน 2565 1',content:'1 กันยายน 2565'},
        {img:'16-8-2022-v3.jpg',alt:'16 สิงหาคม 2565 3',content:'16 สิงหาคม 2565'},
        {img:'16-8-2022-v2.jpg',alt:'16 สิงหาคม 2565 2',content:'16 สิงหาคม 2565'},
        {img:'16-8-2022-v1.jpg',alt:'16 สิงหาคม 2565 1',content:'16 สิงหาคม 2565'},
        {img:'1-8-2022-v3.jpg',alt:'1 สิงหาคม 2565 3',content:'1 สิงหาคม 2565'},
        {img:'1-8-2022-v2.jpg',alt:'1 สิงหาคม 2565 2',content:'1 สิงหาคม 2565'},
        {img:'1-8-2022-v1.jpg',alt:'1 สิงหาคม 2565 1',content:'1 สิงหาคม 2565'},
        {img:'1-7-2022-v3.jpg',alt:'1 กรกฎาคม 2565 3',content:'1 กรกฎาคม 2565'},
        {img:'1-7-2022-v2.jpg',alt:'1 กรกฎาคม 2565 2',content:'1 กรกฎาคม 2565'},
        {img:'1-7-2022-v1.jpg',alt:'1 กรกฎาคม 2565 1',content:'1 กรกฎาคม 2565'},
        {img:'16-6-2022-v3.jpg',alt:'16 มิถุนายน 2565 3',content:'16 มิถุนายน 2565'},
        {img:'16-6-2022-v2.jpg',alt:'16 มิถุนายน 2565 2',content:'16 มิถุนายน 2565'},
        {img:'16-6-2022-v1.jpg',alt:'16 มิถุนายน 2565 1',content:'16 มิถุนายน 2565'},
        {img:'1-6-2022-v3.jpg',alt:'1 มิถุนายน 2565 3',content:'1 มิถุนายน 2565'},
        {img:'1-6-2022-v2.jpg',alt:'1 มิถุนายน 2565 2',content:'1 มิถุนายน 2565'},
        {img:'1-6-2022-v1.jpg',alt:'1 มิถุนายน 2565 1',content:'1 มิถุนายน 2565'},
        {img:'16-5-2022-v3.jpg',alt:'16 พฤษภาคม 2565 3',content:'16 พฤษภาคม 2565'},
        {img:'16-5-2022-v2.jpg',alt:'16 พฤษภาคม 2565 2',content:'16 พฤษภาคม 2565'},
        {img:'16-5-2022-v1.jpg',alt:'16 พฤษภาคม 2565 1',content:'16 พฤษภาคม 2565'},
        {img:'2-5-2022-v3.jpg',alt:'2 พฤษภาคม 2565 3',content:'2 พฤษภาคม 2565'},
        {img:'2-5-2022-v2.jpg',alt:'2 พฤษภาคม 2565 2',content:'2 พฤษภาคม 2565'},
        {img:'2-5-2022-v1.jpg',alt:'2 พฤษภาคม 2565 1',content:'2 พฤษภาคม 2565'},
        {img:'16-4-2022-v4.jpg',alt:'16 เมษายน 2565 4',content:'16 เมษายน 2565'},
        {img:'16-4-2022-v3.jpg',alt:'16 เมษายน 2565 3',content:'16 เมษายน 2565'},
        {img:'16-4-2022-v2.jpg',alt:'16 เมษายน 2565 2',content:'16 เมษายน 2565'},
        {img:'16-4-2022-v1.jpg',alt:'16 เมษายน 2565 1',content:'16 เมษายน 2565'},
        {img:'1-4-2022-v4.webp',alt:'1 เมษายน 2565 4',content:'1 เมษายน 2565'},
        {img:'1-4-2022-v3.jpg',alt:'1 เมษายน 2565 3',content:'1 เมษายน 2565'},
        {img:'1-4-2022-v2.jpg',alt:'1 เมษายน 2565 2',content:'1 เมษายน 2565'},
        {img:'1-4-2022-v1.jpg',alt:'1 เมษายน 2565 1',content:'1 เมษายน 2565'},
        {img:'16-3-2022-v3.jpg',alt:'16 มีนาคม 2565 3',content:'16 มีนาคม 2565'},
        {img:'16-3-2022-v2.jpg',alt:'16 มีนาคม 2565 2',content:'16 มีนาคม 2565'},
        {img:'16-3-2022-v1.jpg',alt:'16 มีนาคม 2565 1',content:'16 มีนาคม 2565'},
        {img:'1-3-2022-v4.jpg',alt:'1 มีนาคม 2565 4',content:'1 มีนาคม 2565'},
        {img:'1-3-2022-v3.jpg',alt:'1 มีนาคม 2565 3',content:'1 มีนาคม 2565'},
        {img:'1-3-2022-v2.jpg',alt:'1 มีนาคม 2565 2',content:'1 มีนาคม 2565'},
        {img:'1-3-2022-v1.jpg',alt:'1 มีนาคม 2565 1',content:'1 มีนาคม 2565'},
    ]


    return(
        <div className="mainMemberPage mainLotto">
            <snowfall>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
                <snowflake><img src="images/lotto_mini.png" className='itemfall' /></snowflake>
            </snowfall>
            <h2 style={{textAlign:'center'}}>รวมเลขเด็ด หวยเด็ดทั่วไทย</h2>
            {
                listLotto.map((item,index)=>{
                    return(
                    <div key={index} className='listLotto '>
                        <LazyLoadImage src={`/images/lotto/${item.img}`} alt={item.content} className='listLottoImg' />
                        <h4 className='btn-gradient-border btn-lotto-login' style={{margin:10,padding:5,borderRadius:10}}>{item.content}</h4>
                    </div>
                    )
                })
            }
        </div>
    )
}

export default Lotto