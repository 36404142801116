import { createStore } from 'redux';
import Islogin from '../reducers/Islogin'
import { loadState, saveState } from '../modules/localStorage'

const persistStore = loadState()
const store = createStore(Islogin, persistStore)

store.subscribe(() => {
  saveState(store.getState())
})

export default store