import React from 'react'
import axios from 'axios'
import Hva from './Hva'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Chartsh2h from './Chartsh2h.js'
import Lineup from './Lineup'
import Inc from './Inc'

/*


เหย้า-เยือน 6.5 คะแนน
อัตราชนะ ลบกัน > 10% = 1, > 20% = 2, > 30% = 3
อัตราเสมอ ลบกัน > 10% = 0.25, > 20% = 0.5, > 30% = 0.75
อัตราแพ้ ลบกัน > 10% = -0.5, > 20% = -1, > 30% = -1.5
ค่าเฉลี่ยประตูได้เสียต่อเกม ลบ กัน > 0.5 = 1, >1 =2, > 1.5=3, >2 =4
จำนวนชนะ การพบกัน ลบกัน >1 = 1,>2 = 2,>3=3
ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ลบกัน > 0.5 = 0.25, >1 =0.5, > 1.5=0.75

อัตราชนะ ลบกัน > 10% = 0.25, > 20% = 0.5, > 30% = 0.75

all 5 คะแนน
อัตราชนะ ลบกัน > 10% = 0.5, > 20% = 1, > 30% = 1.5
อัตราเสมอ ลบกัน > 10% = 0.125, > 20% = 0.25, > 30% = 0.375
อัตราแพ้ ลบกัน > 10% = -0.25, > 20% = -0.5, > 30% = -0.75
#ค่าเฉลี่ยประตูได้เสียต่อเกม ลบ กัน > 0.5 = 0.5, >1 =0.75, > 1.5=1, >2 =1.25 ไม่ได้หาร 2 เพราะต้องการรวม 10 คะแนนพอดี
จำนวนชนะ การพบกัน ลบกัน ทีมไหนมากกว่า >1 =0.5,>2=1, > 3 = 1.5
ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ลบกัน > 0.5 = 0.125, >1 =0.25, > 1.5=0.375

ความมั่นใจ xxx

จำนวนประตู xxx

*/
class Aimain extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            connMobile:this.props.connMobile,
            idmatch :this.props.idmatch?this.props.idmatch:null,
            homeInfo : undefined,
            awayInfo : undefined,
            homeLineup : undefined,
            awayLineup : undefined,
            homeInc : undefined,
            awayInc : undefined,
            loading : true,
            infoMatch : undefined,
            idHome : undefined,
            idAway : undefined,
            fmtHome : undefined,
            fmtAway : undefined,
            ht : '-',
            ft : '-',
            selectTdedOrH2h : this.props.checkCase?'inc':'tded',
            checkCase : this.props.checkCase, // false = non start match & true = inprogress match || end match

            reciveDataChildHva : false,
            reciveDataChildHome : false,
            reciveDataChildAway : false,

            dataCalHva : {
                sumResult : {w:0,d:0,l:0},
                sumGoals : {gfHome:0,glHome:0,gfAway:0,glAway:0},
                last5Match :{
                    sum:{w:0,d:0,l:0},
                    sumGoals:{gfHome:0,glHome:0,gfAway:0,glAway:0}
                },
                sumResultAll : {w:0,d:0,l:0},
                sumGoalsAll : {gfHome:0,glHome:0,gfAway:0,glAway:0},
                last5MatchAll :{
                    sum:{w:0,d:0,l:0},
                    sumGoals:{gfHome:0,glHome:0,gfAway:0,glAway:0}
                },
                wdlSet : []
            },
            dataCalHome : {
                sumResult : {w:0,d:0,l:0},
                sumGoals : {gfHome:0,glHome:0,gfAway:0,glAway:0},
                last5Match :{
                    sum:{w:0,d:0,l:0},
                    sumGoals:{gfHome:0,glHome:0,gfAway:0,glAway:0}
                },
                sumResultAll : {w:0,d:0,l:0},
                sumGoalsAll : {gfHome:0,glHome:0,gfAway:0,glAway:0},
                last5MatchAll :{
                    sum:{w:0,d:0,l:0},
                    sumGoals:{gfHome:0,glHome:0,gfAway:0,glAway:0}
                },
                wdlSet : []
            },
            dataCalAway : {
                sumResult : {w:0,d:0,l:0},
                sumGoals : {gfHome:0,glHome:0,gfAway:0,glAway:0},
                last5Match :{
                    sum:{w:0,d:0,l:0},
                    sumGoals:{gfHome:0,glHome:0,gfAway:0,glAway:0}
                },
                sumResultAll : {w:0,d:0,l:0},
                sumGoalsAll : {gfHome:0,glHome:0,gfAway:0,glAway:0},
                last5MatchAll :{
                    sum:{w:0,d:0,l:0},
                    sumGoals:{gfHome:0,glHome:0,gfAway:0,glAway:0}
                },
                wdlSet : []
            }

        }
     }

     selectTdedOrH2h = (select) =>{
         this.setState({selectTdedOrH2h : select})
     }

     componentDidMount(){
         
        let homeInfo = {}
        let awayInfo = {}
        let homeLineup = []
        let awayLineup = []
        let homeInc = []
        let awayInc = []
        let infoMatch = {
            id: '',
            tournament_stageFK: '',
            startdate: '',
            status_type: '',
            status_descFK: '',
            tournamentFK: '',
            tournament_templateFK: '',
            tournament_stage_name: '',
            tournament_name: '',
            tournament_template_name: '',
            VenueName: ''
        }
        let htHome = 0
        let htAway = 0
        let ftHome = 0
        let ftAway = 0
        axios.get(`https://api.mvpkick.com/event?id=${this.state.idmatch}`).then(res => {
            let event = res.data;
            infoMatch = {
                id: event.id,
                tournament_stageFK: event.tournament_stageFK,
                startdate: event.startdate,
                status_type: event.status_type,
                status_descFK: event.status_descFK,
                tournamentFK: event.tournamentFK,
                tournament_templateFK: event.tournament_templateFK,
                tournament_stage_name: event.tournament_stage_name,
                tournament_name: event.tournament_name,
                tournament_template_name: event.tournament_template_name,
            }
            let {event_participants, property} = event;
            let fmtHome = undefined;
            let fmtAway = undefined;
            for (const listEventParticipants in event_participants) {
                let {number,participant,property,result} = event_participants[listEventParticipants];
                switch(number){
                    case '1':
                        homeInfo = participant;
                        if(event_participants[listEventParticipants].lineup!==undefined){
                            let {lineup} = event_participants[listEventParticipants]
                            for(const key in lineup){
                                homeLineup.push(lineup[key])
                            }
                        }
                        htHome = 0;
                        ftHome = 0;
                        for(const key in result){
                            switch(result[key].result_code){
                                case "halftime":htHome = result[key].value;break;
                                case "ordinarytime":ftHome = result[key].value;break;
                                default:break;
                            }
                        }
                        if(event_participants[listEventParticipants].incident!==undefined){
                            let {incident} = event_participants[listEventParticipants]
                            for(const key in incident){
                                let incImg = '/images/viewmatch/no_img.png'
                                switch(incident[key].incident_typeFK){
                                    case '7': incImg = "/images/viewmatch/goal.png";break;
                                    case '8': incImg = "/images/viewmatch/penalty.png";break;
                                    case '9': incImg = "/images/viewmatch/penalty_miss.png";break;
                                    case '10': incImg = "/images/viewmatch/own_goal.png";break;
                                    case '11': incImg = "/images/viewmatch/penalty_miss.png";break;
                                    case '12': incImg = "/images/viewmatch/penalty.png";break;
                                    case '14': incImg = "/images/viewmatch/yellow_card.png";break;
                                    case '15': incImg = "/images/viewmatch/yellow_card_2.png";break;
                                    case '16': incImg = "/images/viewmatch/red_card.png";break;
                                    case '18': incImg = "/images/viewmatch/goal.png";break;
                                    case '20': incImg = "/images/viewmatch/subout.png";break;
                                    case '32': incImg = "/images/viewmatch/subin.png";break;
                                    case '34': incImg = "/images/viewmatch/assists.png";break;
                                    case '35': incImg = "/images/viewmatch/assists_2.png";break;
                                    default:break;
                                }
                                homeInc.push({...incident[key],team:'home',incImg})
                            }
                            homeInc.sort((a,b)=>a.elapsed - b.elapsed)
                        }
                        for (const key in property){
                            switch (property[key].name) {
                                case 'formation':fmtHome=property[key].value;break;
                                default:break;
                            }
                        }
                        break;
                    case '2':
                        awayInfo = participant;
                        if(event_participants[listEventParticipants].lineup!==undefined){
                            let {lineup} = event_participants[listEventParticipants]
                            for(const key in lineup){
                                awayLineup.push(lineup[key])
                            }
                        }
                        htAway = 0;
                        ftAway = 0;
                        for(const key in result){
                            switch(result[key].result_code){
                                case "halftime":htAway = result[key].value;break;
                                case "ordinarytime":ftAway = result[key].value;break;
                                default:break;
                            }
                        }
                        if(event_participants[listEventParticipants].incident!==undefined){
                            let {incident} = event_participants[listEventParticipants]
                            for(const key in incident){
                                let incImg = '/images/viewmatch/no_img.png'
                                switch(incident[key].incident_typeFK){
                                    case '7': incImg = "/images/viewmatch/goal.png";break;
                                    case '8': incImg = "/images/viewmatch/penalty.png";break;
                                    case '9': incImg = "/images/viewmatch/penalty_miss.png";break;
                                    case '10': incImg = "/images/viewmatch/own_goal.png";break;
                                    case '11': incImg = "/images/viewmatch/penalty_miss.png";break;
                                    case '12': incImg = "/images/viewmatch/penalty.png";break;
                                    case '14': incImg = "/images/viewmatch/yellow_card.png";break;
                                    case '15': incImg = "/images/viewmatch/yellow_card_2.png";break;
                                    case '16': incImg = "/images/viewmatch/red_card.png";break;
                                    case '18': incImg = "/images/viewmatch/goal.png";break;
                                    case '20': incImg = "/images/viewmatch/subout.png";break;
                                    case '32': incImg = "/images/viewmatch/subin.png";break;
                                    case '34': incImg = "/images/viewmatch/assists.png";break;
                                    case '35': incImg = "/images/viewmatch/assists_2.png";break;
                                    default:break;
                                }
                                awayInc.push({...incident[key],team:'away',incImg})
                            }
                            awayInc.sort((a,b)=>a.elapsed - b.elapsed)
                        }
                        for (const key in property){
                            switch (property[key].name) {
                                case 'formation':fmtAway=property[key].value;break;
                                default:break;
                            }
                        }
                        break;
                    default:break;
                }
            }
            for (const listEventProperty in property) {
                let {name, value} = property[listEventProperty];
                switch(name){
                    case 'VenueName':infoMatch.VenueName=value;break;
                    default:break;
                }
            }
            this.setState({
                homeInfo : homeInfo,
                awayInfo : awayInfo,
                infoMatch : infoMatch,
                loading : false,
                idHome : homeInfo.id,
                idAway : awayInfo.id,
                homeLineup : homeLineup,
                awayLineup : awayLineup,
                homeInc : homeInc,
                awayInc : awayInc,
                fmtHome : fmtHome,
                fmtAway : fmtAway,
                ht : htHome + ' - ' + htAway,
                ft : ftHome + ' - ' + ftAway
            });
        })
     }

     componentDidUpdate(){
         if(this.props.connMobile!==this.state.connMobile){
             this.setState({connMobile : this.props.connMobile})
         }
     }

     onConfirm = (sumResult,sumGoals,last5match,keyword,sumResultAll,sumGoalsAll,last5matchAll,data='yes',wdlSet) => {
        //console.log(wdlSet)
        if(data=='no'){
            if(keyword==='hva'&&this.state.reciveDataChildHva==false){this.setState({reciveDataChildHva:true})}
            else if(keyword==='home'&&this.state.reciveDataChildHva==false){this.setState({reciveDataChildHome:true})}
            else if(keyword==='away'&&this.state.reciveDataChildHva==false){this.setState({reciveDataChildAway:true})}
        }else{
            if(keyword==='hva'&&this.state.reciveDataChildHva==false){
                this.setState({
                    reciveDataChildHva:true,
                    dataCalHva : {
                        sumResult,
                        sumGoals,
                        last5Match :last5match,
                        sumResultAll,
                        sumGoalsAll,
                        last5matchAll : last5matchAll,
                        wdlSet : wdlSet
                    }
                })
            }else if(keyword==='home'&&this.state.reciveDataChildHome==false){
                //console.log(sumResult,sumGoals,last5match,keyword)
                this.setState({
                    reciveDataChildHome:true,
                    dataCalHome : {
                        sumResult,
                        sumGoals,
                        last5Match :last5match,
                        sumResultAll,
                        sumGoalsAll,
                        last5matchAll : last5matchAll,
                        wdlSet : wdlSet
                    }
                })
            }else if(keyword==='away'&&this.state.reciveDataChildAway==false){
                //console.log(sumResult,sumGoals,last5match,keyword)
                this.setState({
                    reciveDataChildAway:true,
                    dataCalAway : {
                        sumResult,
                        sumGoals,
                        last5Match :last5match,
                        sumResultAll,
                        sumGoalsAll,
                        last5matchAll : last5matchAll,
                        wdlSet : wdlSet
                    }
                })
            }
        }
        
    }
      

     render() {
        let sumPointHome = 0
        let sumPointAway = 0
        let sumPointHomeAll = 0
        let sumPointAwayAll = 0
        let teamwinTded;
        let percentTded;
        let sumPointHomeResult;
        let sumPointAwayResult;
        
        /* s var cal score x-x */
        let allMatch;
        let goalsHomeTarget;
        let goalsAwayTarget;
        /* e var cal score x-x */
        if(this.state.reciveDataChildHva&&this.state.reciveDataChildHome&&this.state.reciveDataChildAway){
            
            // อัตราชนะ เหย้าเยือน 1.5 คะแนน
            // อัตราชนะ ลบกัน ถ้า >= 10% = 0.5, >= 20% = 1, >= 30% = 1.5
            let checkPoint1 = Math.round((this.state.dataCalHome.sumResult.w/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)-Math.round((this.state.dataCalAway.sumResult.w/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)
            if(checkPoint1>=30){sumPointHome+=1.5}
            else if(checkPoint1>=20){sumPointHome+=1}
            else if(checkPoint1>=10){sumPointHome+=0.5}
            else if(checkPoint1>=0){sumPointHome+=0}
            else if(checkPoint1<=-30){sumPointAway+=1.5}
            else if(checkPoint1<=-20){sumPointAway+=1}
            else if(checkPoint1<=-10){sumPointAway+=0.5}
            //console.log(`อัตราชนะ เหย้าเยือน 1.5 คะแนน :` +checkPoint1)
            //console.log(`อัตราชนะ เหย้าเยือน 1.5 คะแนน เหย้า :` +sumPointHome)
            //console.log(`อัตราชนะ เหย้าเยือน 1.5 คะแนน เยือน :` +sumPointAway)

            // อัตราเสมอ เหย้าเยือน 0.75 คะแนน
            // อัตราเสมอ ลบกัน ถ้า >= 10% = 0.25, >= 20% = 0.5, >= 30% = 0.75
            let checkPoint2 = Math.round((this.state.dataCalHome.sumResult.d/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)-Math.round((this.state.dataCalAway.sumResult.d/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)
            if(checkPoint2>=30){sumPointHome+=0.75}
            else if(checkPoint2>=20){sumPointHome+=0.5}
            else if(checkPoint2>=10){sumPointHome+=0.25}
            else if(checkPoint2>=0){sumPointHome+=0}
            else if(checkPoint2<=-30){sumPointAway+=0.75}
            else if(checkPoint2<=-20){sumPointAway+=0.5}
            else if(checkPoint2<=-10){sumPointAway+=0.25}
            //console.log(`อัตราเสมอ เหย้าเยือน 0.75 คะแนน :` +checkPoint2)
            //console.log(`อัตราเสมอ เหย้าเยือน 0.75 คะแนน เหย้า :` +sumPointHome)
            //console.log(`อัตราเสมอ เหย้าเยือน 0.75 คะแนน เยือน :` +sumPointAway)

            // อัตราแพ้ เหย้าเยือน 0.75 คะแนน
            // อัตราแพ้ ลบกัน ถ้า >= 10% = -0.25, >= 20% = -0.5, >= 30% = -0.75
            let checkPoint3 = Math.round((this.state.dataCalHome.sumResult.l/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)-Math.round((this.state.dataCalAway.sumResult.l/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)
            if(checkPoint3>=30){sumPointHome+=-0.75}
            else if(checkPoint3>=20){sumPointHome+=-0.5}
            else if(checkPoint3>=10){sumPointHome+=-0.25}
            else if(checkPoint3>=0){sumPointHome+=0;}
            else if(checkPoint3<=-30){sumPointAway+=-0.75;}
            else if(checkPoint3<=-20){sumPointAway+=-0.5;}
            else if(checkPoint3<=-10){sumPointAway+=-0.25;}
            //console.log(`อัตราแพ้ เหย้าเยือน 0.75 คะแนน :` +checkPoint3)
            //console.log(`อัตราแพ้ เหย้าเยือน 0.75 คะแนน เหย้า :` +sumPointHome)
            //console.log(`อัตราแพ้ เหย้าเยือน 0.75 คะแนน เยือน :` +sumPointAway)

            // ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 2 คะแนน
            // ค่าเฉลี่ยประตูได้เสียต่อเกม ลบกัน ถ้า >= 0.5 = 0.5, >=1 = 1, >=1.5 = 1.5, >=2 = 2
            let checkPoint4 = ((this.state.dataCalHome.sumGoals.gfHome-this.state.dataCalHome.sumGoals.glHome)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)).toFixed(2)-((this.state.dataCalAway.sumGoals.gfAway-this.state.dataCalAway.sumGoals.glAway)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)).toFixed(2)
            if(checkPoint4>=2){sumPointHome+=2}
            else if(checkPoint4>=1.5){sumPointHome+=1.5}
            else if(checkPoint4>=1){sumPointHome+=1}
            else if(checkPoint4>=0.5){sumPointHome+=0.5;}
            else if(checkPoint4>=0){sumPointHome+=0;}
            else if(checkPoint4<=-2){sumPointAway+=2;}
            else if(checkPoint4<=-1.5){sumPointAway+=1.5;}
            else if(checkPoint4<=-1){sumPointAway+=1;}
            else if(checkPoint4<=-0.5){sumPointAway+=0.5;}
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 2 คะแนน :` +checkPoint4)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 2 คะแนน เหย้า :` +sumPointHome)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 2 คะแนน เยือน :` +sumPointAway)

            // จำนวนชนะ การพบกัน เหย้าเยือน 3 คะแนน
            // จำนวนชนะ การพบกัน ลบกัน ถ้า >=1 = 1,>=2 = 2,>=3 = 3
            let checkPoint5 = this.state.dataCalHva.sumResult.w
            if(checkPoint5>=3){sumPointHome+=3}
            else if(checkPoint5>=2){sumPointHome+=2}
            else if(checkPoint5>=1){sumPointHome+=1}
            else if(checkPoint5>=0){sumPointHome+=0;}
            else if(checkPoint5<=-3){sumPointAway+=3;}
            else if(checkPoint5<=-2){sumPointAway+=2;}
            else if(checkPoint5<=-1){sumPointAway+=1;}
            //console.log(`จำนวนชนะ การพบกัน เหย้าเยือน 3 คะแนน :` +checkPoint5)
            //console.log(`จำนวนชนะ การพบกัน เหย้าเยือน 3 คะแนน เหย้า :` +sumPointHome)
            //console.log(`จำนวนชนะ การพบกัน เหย้าเยือน 3 คะแนน เยือน :` +sumPointAway)

            // ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน เหย้าเยือน 0.75 คะแนน
            // ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ลบกัน ถ้า >= 0.5 = 0.25, >=1 = 0.5, >= 1.5 = 0.75
            let checkPoint6 = (this.state.dataCalHva.sumGoals.gfHome===0?this.state.dataCalHva.sumGoals.gfHome:((this.state.dataCalHva.sumGoals.gfHome-this.state.dataCalHva.sumGoals.glHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l)).toFixed(2))-(this.state.dataCalHva.sumGoals.glHome===0?this.state.dataCalHva.sumGoals.glHome:((this.state.dataCalHva.sumGoals.glHome-this.state.dataCalHva.sumGoals.gfHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l)).toFixed(2))
            if(checkPoint6>=1.5){sumPointHome+=0.75}
            else if(checkPoint6>=1){sumPointHome+=0.5}
            else if(checkPoint6>=0.5){sumPointHome+=0.25}
            else if(checkPoint6>=0){sumPointHome+=0;}
            else if(checkPoint6<=-1.5){sumPointAway+=0.75;}
            else if(checkPoint6<=-1){sumPointAway+=0.5;}    
            else if(checkPoint6<=-0.5){sumPointAway+=0.25;}
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 4 คะแนน :` +checkPoint6)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 4 คะแนน เหย้า :` +sumPointHome)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม เหย้าเยือน 4 คะแนน เยือน :` +sumPointAway)

            /* ส่วนที่ 2 */

            // อัตราชนะ ทั้งหมด 0.75 คะแนน
            // อัตราชนะ ลบกัน ถ้า >= 10% = 0.25, >= 20% = 0.5, >= 30% = 0.75
            let checkPoint1All = Math.round((this.state.dataCalHome.sumResultAll.w/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)-Math.round((this.state.dataCalAway.sumResultAll.w/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)
            if(checkPoint1All>=30){sumPointHomeAll+=0.75}
            else if(checkPoint1All>=20){sumPointHomeAll+=0.5}
            else if(checkPoint1All>=10){sumPointHomeAll+=0.25}
            else if(checkPoint1All>=0){sumPointHomeAll+=0}
            else if(checkPoint1All<=-30){sumPointAwayAll+=0.75}
            else if(checkPoint1All<=-20){sumPointAwayAll+=0.5}
            else if(checkPoint1All<=-10){sumPointAwayAll+=0.25}
            //console.log(`อัตราชนะ ทั้งหมด 0.75 คะแนน :` +checkPoint1All)
            //console.log(`อัตราชนะ ทั้งหมด 0.75 คะแนน เหย้า :` +sumPointHomeAll)
            //console.log(`อัตราชนะ ทั้งหมด 0.75 คะแนน เยือน :` +sumPointAwayAll)

            // อัตราเสมอ ทั้งหมด 0.375 คะแนน
            // อัตราเสมอ ลบกัน ถ้า >= 10% = 0.125, >= 20% = 0.25, >= 30% = 0.375
            let checkPoint2All = Math.round((this.state.dataCalHome.sumResultAll.d/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)-Math.round((this.state.dataCalAway.sumResultAll.d/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)
            if(checkPoint2All>=30){sumPointHomeAll+=0.375}
            else if(checkPoint2All>=20){sumPointHomeAll+=0.25}
            else if(checkPoint2All>=10){sumPointHomeAll+=0.125}
            else if(checkPoint2All>=0){sumPointHomeAll+=0}
            else if(checkPoint2All<=-30){sumPointAwayAll+=0.375}
            else if(checkPoint2All<=-20){sumPointAwayAll+=0.25}
            else if(checkPoint2All<=-10){sumPointAwayAll+=0.125}
            //console.log(`อัตราเสมอ ทั้งหมด 0.375 คะแนน :` +checkPoint2All)
            //console.log(`อัตราเสมอ ทั้งหมด 0.375 คะแนน เหย้า :` +sumPointHomeAll)
            //console.log(`อัตราเสมอ ทั้งหมด 0.375 คะแนน เยือน :` +sumPointAwayAll)

            // อัตราแพ้ ทั้งหมด 0.75 คะแนน
            // อัตราแพ้ ลบกัน ถ้า >= 10% = 0.25, >= 20% = 0.5, >= 30% = 0.75
            let checkPoint3All = Math.round((this.state.dataCalHome.sumResultAll.l/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)-Math.round((this.state.dataCalAway.sumResultAll.l/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)
            if(checkPoint3All>=30){sumPointHomeAll+=-0.75}
            else if(checkPoint3All>=20){sumPointHomeAll+=-0.5}
            else if(checkPoint3All>=10){sumPointHomeAll+=-0.25}
            else if(checkPoint3All>=0){sumPointHomeAll+=0}
            else if(checkPoint3All<=-30){sumPointAwayAll+=-0.75}
            else if(checkPoint3All<=-20){sumPointAwayAll+=-0.5}
            else if(checkPoint3All<=-10){sumPointAwayAll+=0.25}
            //console.log(`อัตราแพ้ ทั้งหมด 0.75 คะแนน :` +checkPoint3All)
            //console.log(`อัตราแพ้ ทั้งหมด 0.75 คะแนน เหย้า :` +sumPointHomeAll)
            //console.log(`อัตราแพ้ ทั้งหมด 0.75 คะแนน เยือน :` +sumPointAwayAll)

            // ค่าเฉลี่ยประตูได้เสียต่อเกม ทั้งหมด 1.25 คะแนน
            // #ค่าเฉลี่ยประตูได้เสียต่อเกม ลบ กัน > 0.5 = 0.5, >1 =0.75, > 1.5=1, >2 =1.25 ไม่ได้หาร 2 เพราะต้องการรวม 10 คะแนนพอดี
            let checkPoint4All = (((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)-(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l)).toFixed(2)-(((this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)-(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway))/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)).toFixed(2)
            if(checkPoint4All>=2){sumPointHomeAll+=1.25}
            else if(checkPoint4All>=1.5){sumPointHomeAll+=1}
            else if(checkPoint4All>=1){sumPointHomeAll+=0.75}
            else if(checkPoint4All>=0.5){sumPointHomeAll+=0.5;}
            else if(checkPoint4All>=0){sumPointHomeAll+=0;}
            else if(checkPoint4All<=-2){sumPointAwayAll+=1.25;}
            else if(checkPoint4All<=-1.5){sumPointAwayAll+=1;}
            else if(checkPoint4All<=-1){sumPointAwayAll+=0.75;}
            else if(checkPoint4All<=-0.5){sumPointAwayAll+=0.5;}
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม ทั้งหมด 1.25 คะแนน :` +checkPoint4All)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม ทั้งหมด 1.25 คะแนน เหย้า :` +sumPointHomeAll)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม ทั้งหมด 1.25 คะแนน เยือน :` +sumPointAwayAll)

            // จำนวนชนะ การพบกัน ทั้งหมด 1.5 คะแนน
            // จำนวนชนะ การพบกัน ลบกัน ถ้า >=1 =0.5, >=2 = 1, >=3 = 1.5
            let checkPoint5All = this.state.dataCalHva.sumResultAll.w-this.state.dataCalHva.sumResultAll.l
            if(checkPoint5All>=3){sumPointHomeAll+=1.5}
            else if(checkPoint5All>=2){sumPointHomeAll+=1}
            else if(checkPoint5All>=1){sumPointHomeAll+=0.5}
            else if(checkPoint5All>=0){sumPointHomeAll+=0;}
            else if(checkPoint5All<=-3){sumPointAwayAll+=1.5;}
            else if(checkPoint5All<=-2){sumPointAwayAll+=1;}
            else if(checkPoint5All<=-1){sumPointAwayAll+=0.5;}
            //console.log(`จำนวนชนะ การพบกัน ทั้งหมด 1.5 คะแนน :` +checkPoint5All)
            //console.log(`จำนวนชนะ การพบกัน ทั้งหมด 1.5 คะแนน เหย้า :` +sumPointHomeAll)
            //console.log(`จำนวนชนะ การพบกัน ทั้งหมด 1.5 คะแนน เยือน :` +sumPointAwayAll)

            // ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ทั้งหมด 0.375 คะแนน
            // ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ลบกัน >=0.5 = 0.125, >=1 = 0.25, >=1.5 = 0.375
            let checkPoint6All = (this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)===0?(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway):(((this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)-(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)
            if(checkPoint6All>=1.5){sumPointHomeAll+=0.375}
            else if(checkPoint6All>=1){sumPointHomeAll+=0.25}
            else if(checkPoint6All>=0.5){sumPointHomeAll+=0.125}
            else if(checkPoint6All>=0){sumPointHomeAll+=0;}
            else if(checkPoint6All<=-0.5){sumPointAwayAll+=0.125;}
            else if(checkPoint6All<=-1){sumPointAwayAll+=0.25;}
            else if(checkPoint6All<=-1.5){sumPointAwayAll+=0.375;}
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ทั้งหมด 0.375 คะแนน :` +checkPoint6All)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ทั้งหมด 0.375 คะแนน เหย้า :` +sumPointHomeAll)
            //console.log(`ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน ทั้งหมด 0.375 คะแนน เยือน :` +sumPointAwayAll)

            //console.log(`รวม :` + sumPointHome)
            //console.log(`รวม :` + sumPointAway)
            //console.log(`รวม :` + sumPointHomeAll)
            //console.log(`รวม :` + sumPointAwayAll)

            if(sumPointHome<0){sumPointAway=sumPointAway-sumPointHome;sumPointHome=sumPointHome-sumPointHome}
            if(sumPointAway<0){sumPointHome=sumPointHome-sumPointAway;sumPointAway=sumPointAway-sumPointAway}

            if(sumPointHomeAll<0){sumPointAwayAll=sumPointAwayAll-sumPointHomeAll;sumPointHomeAll=sumPointHomeAll-sumPointHomeAll}
            if(sumPointAwayAll<0){sumPointHomeAll=sumPointHomeAll-sumPointAwayAll;sumPointAwayAll=sumPointAwayAll-sumPointAwayAll}

            sumPointHomeResult = sumPointHome+sumPointHomeAll
            sumPointAwayResult = sumPointAway+sumPointAwayAll
            
            if(sumPointHomeResult-sumPointAwayResult>=1){
                teamwinTded = 'home';
            }else if(sumPointHomeResult-sumPointAwayResult>-1&&sumPointHomeResult-sumPointAwayResult<1){
                teamwinTded = 'draw';
            }else if(sumPointHomeResult-sumPointAwayResult<=-1){
                teamwinTded = 'away';
            }

            if(sumPointHomeResult>sumPointAwayResult){
                let selectPoint = Math.round(sumPointHomeResult-sumPointAwayResult)
                switch(selectPoint){
                    case 0:percentTded=55;break;
                    case 1:percentTded=60;break;
                    case 2:percentTded=65;break;
                    case 3:percentTded=70;break;
                    case 4:percentTded=75;break;
                    case 5:percentTded=80;break;
                    case 6:percentTded=85;break;
                    case 7:percentTded=90;break;
                    case 8:percentTded=95;break;
                    case 9:percentTded=99;break;
                    case 10:percentTded=99.99;break;
                    default: break;
                }
                //console.log(selectPoint)
            }else if(sumPointAwayResult>sumPointHomeResult){
                let selectPoint = Math.round(sumPointAwayResult-sumPointHomeResult)
                switch(selectPoint){
                    case 0:percentTded=55;break;
                    case 1:percentTded=60;break;
                    case 2:percentTded=65;break;
                    case 3:percentTded=70;break;
                    case 4:percentTded=75;break;
                    case 5:percentTded=80;break;
                    case 6:percentTded=85;break;
                    case 7:percentTded=90;break;
                    case 8:percentTded=95;break;
                    case 9:percentTded=99;break;
                    case 10:percentTded=99.99;break;
                    default: break;
                }
            }else{
                percentTded=55
            }

            allMatch = (this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)+(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)
            
            let random0and1 = Math.floor(Math.random() * 2);

            goalsHomeTarget = Math.round((this.state.dataCalHome.sumGoals.gfHome+this.state.dataCalAway.sumGoals.glAway)/allMatch)
            goalsAwayTarget =  Math.round((this.state.dataCalAway.sumGoals.gfAway+this.state.dataCalHome.sumGoals.glHome)/allMatch)

            if(random0and1==1){
                if(goalsHomeTarget>0&&goalsAwayTarget>0){
                    goalsHomeTarget = goalsHomeTarget-1;
                    goalsAwayTarget = goalsAwayTarget-1;
                }
            }
            switch(teamwinTded){
                case 'home':
                    if(goalsHomeTarget<=goalsAwayTarget){goalsHomeTarget=goalsAwayTarget+1};break;
                case 'away':
                    if(goalsAwayTarget<=goalsHomeTarget){goalsAwayTarget=goalsHomeTarget+1};break;
                case 'draw':
                    if(goalsHomeTarget!==goalsAwayTarget){goalsHomeTarget=goalsAwayTarget};break;
                default:
                    break;
            }

        }
        
        return (
                 this.state.loading==false?
                 <div>
                     <div className="switchTdedAndH2h">
                        {
                        this.state.checkCase?
                        <button className="btn btn-semi-transparent btn-glow" onClick={(e)=>{this.selectTdedOrH2h('inc')}}>ข้อมูลการแข่ง</button>:
                        <button className="btn btn-semi-transparent btn-glow" onClick={(e)=>{this.selectTdedOrH2h('tded')}}>สรุปทีเด็ด</button>
                        }
                        {this.state.homeLineup.length>0||this.state.awayLineup.length>0?<button className="btn btn-semi-transparent btn-glow" onClick={(e)=>{this.selectTdedOrH2h('lineup')}}>รายชื่อผู้เล่น</button>:''}
                        <button className="btn btn-semi-transparent btn-glow" onClick={(e)=>{this.selectTdedOrH2h('h2h')}}>สถิติ</button>
                    </div>
                     <div className="infoTded">
                        <div><LazyLoadImage className="logoTeamPage" src={this.state.homeInfo.logo} alt={this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.name} /><br/>{this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.thainame}</div>
                        <div><LazyLoadImage className="logoTeamPage" src={this.state.awayInfo.logo} alt={this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.name} /><br/>{this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.thainame}</div>
                    </div>
                    <div className="w-100 text-center">สนามแข่ง : {this.state.infoMatch.VenueName!==''?this.state.infoMatch.VenueName:' - '}</div>
                    
                    {this.state.selectTdedOrH2h=='tded'?
                    <div className="calTdedResult">
                        <Hva nameHome={this.state.homeInfo.thainame?this.state.homeInfo.thainame:this.state.homeInfo.name} nameAway={this.state.awayInfo.thainame?this.state.awayInfo.thainame:this.state.awayInfo.name} idHome={this.state.idHome} idAway={this.state.idAway} tourTemFK={this.state.infoMatch.tournament_templateFK} keyword='hva' connMobile={this.state.connMobile} selectTdedOrH2h={this.state.selectTdedOrH2h} HorA='home' tour="focus" confirm={this.onConfirm} key="hva"
                        reciveDataChildHva={this.state.reciveDataChildHva}
                        reciveDataChildHome={this.state.reciveDataChildHome}
                        reciveDataChildAway={this.state.reciveDataChildAway}
                        /> {/* HorA='home' เพราะทีมซ้ายคือทีมโฟกัส */}
                        <div className="col-6 seeTded">
                        <Hva nameHome={this.state.homeInfo.thainame?this.state.homeInfo.thainame:this.state.homeInfo.name} nameAway={this.state.awayInfo.thainame?this.state.awayInfo.thainame:this.state.awayInfo.name} idHome={this.state.idHome} idAway={this.state.idAway} tourTemFK={this.state.infoMatch.tournament_templateFK} keyword='home' connMobile={this.state.connMobile} selectTdedOrH2h={this.state.selectTdedOrH2h} HorA='home' tour="focus" confirm={this.onConfirm} key="home"
                        reciveDataChildHva={this.state.reciveDataChildHva}
                        reciveDataChildHome={this.state.reciveDataChildHome}
                        reciveDataChildAway={this.state.reciveDataChildAway}
                        />
                        </div>
                        <div className="col-6 seeTded">
                        <Hva nameHome={this.state.homeInfo.thainame?this.state.homeInfo.thainame:this.state.homeInfo.name} nameAway={this.state.awayInfo.thainame?this.state.awayInfo.thainame:this.state.awayInfo.name} idHome={this.state.idHome} idAway={this.state.idAway} tourTemFK={this.state.infoMatch.tournament_templateFK} keyword='away' connMobile={this.state.connMobile} selectTdedOrH2h={this.state.selectTdedOrH2h} HorA='away' tour="focus" confirm={this.onConfirm} key="away"
                        reciveDataChildHva={this.state.reciveDataChildHva}
                        reciveDataChildHome={this.state.reciveDataChildHome}
                        reciveDataChildAway={this.state.reciveDataChildAway}
                        />
                        </div>
                        {this.state.reciveDataChildHva&&this.state.reciveDataChildHome&&this.state.reciveDataChildAway?
                        <div>
                            {/*console.log(this.state.dataCalHome)*/}
                            {
                            <Chartsh2h 
                            wdlHome={this.state.dataCalHome.wdlSet} wdlAway={this.state.dataCalAway.wdlSet} idHome={this.state.idHome} idAway={this.state.idAway} homeInfo={this.state.homeInfo} awayInfo={this.state.awayInfo}
                            />}
                            <div className="headingNote text-center"><h4 className="text-center"><span className="text-gradient-v2">วิเคราะห์ส่วนแรก</span></h4>เปรียบเทียบสถิติ เฉพาะรายการ {this.state.infoMatch.tournament_template_name} และเทียบ เหย้า-เยือน เหมือนกันเท่านั้น</div>
                            <div className="col-12 boxHvaTdedCal">
                            <div className="col-4 text-right">ในบ้าน {this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l} นัด</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">คำนวนจากรายการแข่ง</span></div>
                                <div className="col-4">นอกบ้าน {this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l} นัด</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)>=(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)?
                                        '100':
                                        Math.round(((this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%`,marginLeft:'auto'}}>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)>=(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)?
                                        '100':
                                        Math.round(((this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%`}}>
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="col-4 text-right">{Math.round((this.state.dataCalHome.sumResult.w/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ชนะ</span></div>
                                <div className="col-4">{Math.round((this.state.dataCalAway.sumResult.w/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-win" style={{maxWidth:`${Math.round((this.state.dataCalHome.sumResult.w/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%`,marginLeft:'auto'}}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-win" style={{maxWidth:`${Math.round((this.state.dataCalAway.sumResult.w/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%`}}></div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{Math.round((this.state.dataCalHome.sumResult.d/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">เสมอ</span></div>
                                <div className="col-4">{Math.round((this.state.dataCalAway.sumResult.d/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                        <div className="progress-bar-v1-draw" style={{maxWidth:`${Math.round((this.state.dataCalHome.sumResult.d/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%`,marginLeft:'auto'}}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                        <div className="progress-bar-v1-draw" style={{maxWidth:`${Math.round((this.state.dataCalAway.sumResult.d/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%`}}></div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{Math.round((this.state.dataCalHome.sumResult.l/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">แพ้</span></div>
                                <div className="col-4">{Math.round((this.state.dataCalAway.sumResult.l/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-lose" style={{maxWidth:`${Math.round((this.state.dataCalHome.sumResult.l/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l))*100)}%`,marginLeft:'auto'}}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-lose" style={{maxWidth:`${Math.round((this.state.dataCalAway.sumResult.l/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l))*100)}%`}}></div>
                                    </div>
                                </div>
                                
                                <div className="col-4 text-right">{this.state.dataCalHome.sumGoals.gfHome}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ยิงประตูรวม</span></div>
                                <div className="col-4">{this.state.dataCalAway.sumGoals.gfAway}</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHome.sumGoals.gfHome>=this.state.dataCalAway.sumGoals.gfAway?'100':Math.round((this.state.dataCalHome.sumGoals.gfHome/this.state.dataCalAway.sumGoals.gfAway)*100)}%`,marginLeft:'auto'}}>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalAway.sumGoals.gfAway>=this.state.dataCalHome.sumGoals.gfHome?'100':Math.round((this.state.dataCalAway.sumGoals.gfAway/this.state.dataCalHome.sumGoals.gfHome)*100)}%`}}>
                                         </div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{this.state.dataCalHome.sumGoals.glHome}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">เสียประตูรวม</span></div>
                                <div className="col-4">{this.state.dataCalAway.sumGoals.glAway}</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHome.sumGoals.glHome>=this.state.dataCalAway.sumGoals.glAway?'100':
                                        Math.round((this.state.dataCalHome.sumGoals.glHome/this.state.dataCalAway.sumGoals.glAway)*100)}%`,marginLeft:'auto'}}>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalAway.sumGoals.glAway>=this.state.dataCalHome.sumGoals.glHome?'100':
                                        Math.round((this.state.dataCalAway.sumGoals.glAway/this.state.dataCalHome.sumGoals.glHome)*100)}%`}}>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 text-right"><span className="TdedSpanMiddle">{((this.state.dataCalHome.sumGoals.gfHome-this.state.dataCalHome.sumGoals.glHome)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)).toFixed(2)}</span></div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ค่าเฉลี่ยประตูได้เสียต่อเกม</span></div>
                                <div className="col-4">{((this.state.dataCalAway.sumGoals.gfAway-this.state.dataCalAway.sumGoals.glAway)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)).toFixed(2)}</div>
                                <div className="col-4 text-right">
                                    {
                                        (this.state.dataCalHome.sumGoals.gfHome-this.state.dataCalHome.sumGoals.glHome)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l).toFixed(2)>=((this.state.dataCalAway.sumGoals.gfAway-this.state.dataCalAway.sumGoals.glAway)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        (this.state.dataCalHome.sumGoals.gfHome-this.state.dataCalHome.sumGoals.glHome)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l).toFixed(2)<((this.state.dataCalAway.sumGoals.gfAway-this.state.dataCalAway.sumGoals.glAway)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>
                                <div className="col-4 text-center"></div>
                                <div className="col-4">
                                    {
                                        (this.state.dataCalAway.sumGoals.gfAway-this.state.dataCalAway.sumGoals.glAway)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l).toFixed(2)>=((this.state.dataCalHome.sumGoals.gfHome-this.state.dataCalHome.sumGoals.glHome)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        (this.state.dataCalAway.sumGoals.gfAway-this.state.dataCalAway.sumGoals.glAway)/(this.state.dataCalAway.sumResult.w+this.state.dataCalAway.sumResult.d+this.state.dataCalAway.sumResult.l).toFixed(2)<((this.state.dataCalHome.sumGoals.gfHome-this.state.dataCalHome.sumGoals.glHome)/(this.state.dataCalHome.sumResult.w+this.state.dataCalHome.sumResult.d+this.state.dataCalHome.sumResult.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>

                                <div className="col-4 text-right">{this.state.dataCalHva.sumResult.w}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">จำนวนชนะ การพบกัน</span></div>
                                <div className="col-4">{this.state.dataCalHva.sumResult.l}</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHva.sumResult.w>=this.state.dataCalHva.sumResult.l?
                                        '100':
                                        Math.round((this.state.dataCalHva.sumResult.w/this.state.dataCalHva.sumResult.l)*100)}%`,marginLeft:'auto'}}>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHva.sumResult.l>=this.state.dataCalHva.sumResult.w?
                                        '100':
                                        Math.round((this.state.dataCalHva.sumResult.l/this.state.dataCalHva.sumResult.w)*100)}%`}}>
                                    </div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{this.state.dataCalHva.sumGoals.gfHome===0?this.state.dataCalHva.sumGoals.gfHome:((this.state.dataCalHva.sumGoals.gfHome-this.state.dataCalHva.sumGoals.glHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l)).toFixed(2)}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน</span></div>
                                <div className="col-4">{this.state.dataCalHva.sumGoals.glHome===0?this.state.dataCalHva.sumGoals.glHome:((this.state.dataCalHva.sumGoals.glHome-this.state.dataCalHva.sumGoals.gfHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l)).toFixed(2)}</div>
                                <div className="col-4 text-right">
                                    {
                                        (this.state.dataCalHva.sumGoals.gfHome-this.state.dataCalHva.sumGoals.glHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l).toFixed(2)>=((this.state.dataCalHva.sumGoals.glHome-this.state.dataCalHva.sumGoals.gfHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        (this.state.dataCalHva.sumGoals.gfHome-this.state.dataCalHva.sumGoals.glHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l).toFixed(2)<(this.state.dataCalHva.sumGoals.glHome-this.state.dataCalHva.sumGoals.gfHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>
                                <div className="col-4 text-center"></div>
                                <div className="col-4">
                                    {
                                        (this.state.dataCalHva.sumGoals.glHome-this.state.dataCalHva.sumGoals.gfHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l).toFixed(2)>=((this.state.dataCalHva.sumGoals.gfHome-this.state.dataCalHva.sumGoals.glHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        (this.state.dataCalHva.sumGoals.glHome-this.state.dataCalHva.sumGoals.gfHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l).toFixed(2)<(this.state.dataCalHva.sumGoals.gfHome-this.state.dataCalHva.sumGoals.glHome)/(this.state.dataCalHva.sumResult.w+this.state.dataCalHva.sumResult.d+this.state.dataCalHva.sumResult.l).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>

                                <div className="resultTdedZone1 text-center">
                                    <h3>ตัดเกรดส่วนแรก</h3>
                                    <div className="infoTeam1TdedZone1">
                                        <div><LazyLoadImage className="logoTeamPage" src={this.state.homeInfo.logo} alt={this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.name} /><br/>
                                        {this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.thainame}<br/>
                                        รวม : {sumPointHome} คะแนน<br/>
                                        {sumPointHome>=sumPointAway?<LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:<LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />}
                                        </div>
                                    </div>
                                    <div className="infoTeam2TdedZone1">
                                        <div><LazyLoadImage className="logoTeamPage" src={this.state.awayInfo.logo} alt={this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.name} /><br/>
                                        {this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.thainame}<br/>
                                        รวม : {sumPointAway} คะแนน<br/>
                                        {sumPointAway>=sumPointHome?<LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:<LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="headingNote text-center"><h4 className="text-center"><span className="text-gradient-v2">วิเคราะห์ส่วนที่สอง</span></h4>เปรียบเทียบสถิติ ทุกรายการ ทุกทัวร์นาเม้น</div>
                            <div className="col-12 boxAllTdedCal">
                                
                                <div className="col-4 text-right">{this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l} นัด</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">คำนวนจากรายการแข่ง</span></div>
                                <div className="col-4">{this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l} นัด</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l)>=(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)?
                                        '100':
                                        Math.round(((this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l)/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%`,marginLeft:'auto'}}>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)>=(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l)?
                                        '100':
                                        Math.round(((this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%`}}>
                                    </div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{Math.round((this.state.dataCalHome.sumResultAll.w/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ชนะ</span></div>
                                <div className="col-4">{Math.round((this.state.dataCalAway.sumResultAll.w/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-win" style={{maxWidth:`${Math.round((this.state.dataCalHome.sumResultAll.w/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%`,marginLeft:'auto'}}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-win" style={{maxWidth:`${Math.round((this.state.dataCalAway.sumResultAll.w/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%`}}></div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{Math.round((this.state.dataCalHome.sumResultAll.d/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">เสมอ</span></div>
                                <div className="col-4">{Math.round((this.state.dataCalAway.sumResultAll.d/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                        <div className="progress-bar-v1-draw" style={{maxWidth:`${Math.round((this.state.dataCalHome.sumResultAll.d/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%`,marginLeft:'auto'}}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                        <div className="progress-bar-v1-draw" style={{maxWidth:`${Math.round((this.state.dataCalAway.sumResultAll.d/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%`}}></div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{Math.round((this.state.dataCalHome.sumResultAll.l/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">แพ้</span></div>
                                <div className="col-4">{Math.round((this.state.dataCalAway.sumResultAll.l/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-lose" style={{maxWidth:`${Math.round((this.state.dataCalHome.sumResultAll.l/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l))*100)}%`,marginLeft:'auto'}}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-lose" style={{maxWidth:`${Math.round((this.state.dataCalAway.sumResultAll.l/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l))*100)}%`}}></div>
                                    </div>
                                </div>

                                {/* การคำนวน All ประตูไม่เหมือนช่องบน ระวัง */}
                                <div className="col-4 text-right">{this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ยิงประตูรวม</span></div>
                                <div className="col-4">{this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway}</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway>=this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway?'100':Math.round((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)/(this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)*100)}%`,marginLeft:'auto'}}>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalAway.sumGoalsAll.gfAway>=this.state.dataCalHome.sumGoalsAll.gfHome?'100':Math.round((this.state.dataCalAway.sumGoalsAll.gfAway/this.state.dataCalHome.sumGoalsAll.gfHome)*100)}%`}}>
                                         </div>
                                    </div>
                                </div>
                                

                                <div className="col-4 text-right">{this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">เสียประตูรวม</span></div>
                                <div className="col-4">{this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway}</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway)>=(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway)?'100':
                                        Math.round((this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway)/(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway)*100)}%`,marginLeft:'auto'}}>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway>=this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway?'100':
                                        Math.round(((this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway)/(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))*100)}%`}}>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)-(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l).toFixed(2)}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ค่าเฉลี่ยประตูได้เสียต่อเกม</span></div>
                                <div className="col-4">{(((this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)-(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway))/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)).toFixed(2)}</div>
                                <div className="col-4 text-right">
                                    {
                                        ((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)-(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l).toFixed(2)>=(((this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)-(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway))/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        ((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)-(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l).toFixed(2)<(((this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)-(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway))/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>
                                <div className="col-4 text-center"></div>
                                <div className="col-4">
                                    {
                                        (((this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)-(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway))/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)).toFixed(2)>=(((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)-(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        (((this.state.dataCalAway.sumGoalsAll.gfHome+this.state.dataCalAway.sumGoalsAll.gfAway)-(this.state.dataCalAway.sumGoalsAll.glHome+this.state.dataCalAway.sumGoalsAll.glAway))/(this.state.dataCalAway.sumResultAll.w+this.state.dataCalAway.sumResultAll.d+this.state.dataCalAway.sumResultAll.l)).toFixed(2)<(((this.state.dataCalHome.sumGoalsAll.gfHome+this.state.dataCalHome.sumGoalsAll.gfAway)-(this.state.dataCalHome.sumGoalsAll.glHome+this.state.dataCalHome.sumGoalsAll.glAway))/(this.state.dataCalHome.sumResultAll.w+this.state.dataCalHome.sumResultAll.d+this.state.dataCalHome.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>

                                <div className="col-4 text-right">{this.state.dataCalHva.sumResultAll.w}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">จำนวนชนะ การพบกัน</span></div>
                                <div className="col-4">{this.state.dataCalHva.sumResultAll.l}</div>
                                <div className="col-6 text-right">
                                    <div className="progress-v1 f-right">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHva.sumResultAll.w>=this.state.dataCalHva.sumResultAll.l?
                                        '100':
                                        Math.round((this.state.dataCalHva.sumResultAll.w/this.state.dataCalHva.sumResultAll.l)*100)}%`,marginLeft:'auto'}}>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="progress-v1">
                                    <div className="progress-bar-v1-main" style={{maxWidth:`${this.state.dataCalHva.sumResultAll.l>=this.state.dataCalHva.sumResultAll.w?
                                        '100':
                                        Math.round((this.state.dataCalHva.sumResultAll.l/this.state.dataCalHva.sumResultAll.w)*100)}%`}}>
                                    </div>
                                    </div>
                                </div>

                                <div className="col-4 text-right">{(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)===0?(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway):(((this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)-(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)}</div>
                                <div className="col-4 text-center"><span className="text-gradient-v3">ค่าเฉลี่ยประตูได้เสียต่อเกม การพบกัน</span></div>
                                <div className="col-4">{(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway)===0?(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway):(((this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway)-(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)}</div>
                                <div className="col-4 text-right">
                                    {
                                        ((this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)-(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l).toFixed(2)>=(((this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway)-(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        ((this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)-(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l).toFixed(2)<(((this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway)-(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>
                                <div className="col-4 text-center"></div>
                                <div className="col-4">
                                    {
                                        ((this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway)-(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l).toFixed(2)>=(((this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)-(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:
                                        ((this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway)-(this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l).toFixed(2)<(((this.state.dataCalHva.sumGoalsAll.gfHome+this.state.dataCalHva.sumGoalsAll.gfAway)-(this.state.dataCalHva.sumGoalsAll.glHome+this.state.dataCalHva.sumGoalsAll.glAway))/(this.state.dataCalHva.sumResultAll.w+this.state.dataCalHva.sumResultAll.d+this.state.dataCalHva.sumResultAll.l)).toFixed(2)?
                                        <LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />:
                                        <LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />
                                    }
                                </div>
                                <div className="resultTdedZone2 text-center">
                                    <h3>ตัดเกรดส่วนที่สอง</h3>
                                    <div className="infoTeam1TdedZone1">
                                        <div><LazyLoadImage className="logoTeamPage" src={this.state.homeInfo.logo} alt={this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.name} /><br/>
                                        {this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.thainame}<br/>
                                        รวม : {sumPointHomeAll} คะแนน<br/>
                                        {sumPointHomeAll>=sumPointAwayAll?<LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:<LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />}
                                        </div>
                                    </div>
                                    <div className="infoTeam2TdedZone1">
                                        <div><LazyLoadImage className="logoTeamPage" src={this.state.awayInfo.logo} alt={this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.name} /><br/>
                                        {this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.thainame}<br/>
                                        รวม : {sumPointAwayAll} คะแนน<br/>
                                        {sumPointAwayAll>=sumPointHomeAll?<LazyLoadImage src="/images/like.webp" ali="like" className="likeChek" />:<LazyLoadImage src="/images/dislike.webp" ali="like" className="likeChek" />}
                                        </div>
                                    </div>
                            </div>
                            </div>
                            
                            <div className="endTdedZone">
                                {teamwinTded!==undefined?
                                    teamwinTded==='home'?
                                    <div className="funtongBox">
                                        <LazyLoadImage className="logoTeamPage" src={this.state.homeInfo.logo} alt={this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.name} /><br/>
                                        {this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.thainame}<br/>
                                        <h4>ทายผลทีม</h4>
                                        <h4><span className="btn-green">"{this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.thainame} ชนะ"</span></h4>
                                        <div className="contentTdedResult">ชนะคะแนนวิเคราะห์<br/>{sumPointHomeResult} ต่อ {sumPointAwayResult}<br/>
                                        ความมั่นใจ : {percentTded!==undefined?`${percentTded}%`:'-'}</div>
                                    </div>:
                                    teamwinTded==='away'?
                                    <div className="funtongBox">
                                        <LazyLoadImage className="logoTeamPage" src={this.state.awayInfo.logo} alt={this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.name} /><br/>
                                        {this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.thainame}<br/>
                                        <h4>ทายผลทีม</h4>
                                        <h4><span className="btn-red">"{this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.thainame} ชนะ"</span></h4>
                                        <div className="contentTdedResult">ชนะคะแนนวิเคราะห์<br/>{sumPointAwayResult} ต่อ {sumPointHomeResult}<br/>
                                        ความมั่นใจ : {percentTded!==undefined?`${percentTded}%`:'-'}</div>
                                    </div>:
                                    teamwinTded==='draw'?
                                    <div className="drawBox">
                                        <div className="funtongBox funtongBoxDraw">
                                            <LazyLoadImage className="logoTeamPage" src={this.state.homeInfo.logo} alt={this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.name} /><br/>
                                            {this.state.homeInfo.thainame===null?this.state.homeInfo.name:this.state.homeInfo.thainame}
                                        </div>
                                        <div className="funtongBox funtongBoxDraw">
                                            <LazyLoadImage className="logoTeamPage" src={this.state.awayInfo.logo} alt={this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.name} /><br/>
                                            {this.state.awayInfo.thainame===null?this.state.awayInfo.name:this.state.awayInfo.thainame}
                                        </div>
                                        <div style={{clear:'both'}}></div>
                                    <div className="funtongBox">
                                        <h4>ทายผลทีม</h4>
                                        <h4><span className="btn-yellow">"เสมอ"</span></h4>
                                        <div className="contentTdedResult">คะแนนวิเคราะห์ใกล้เคียงกัน<br/>{sumPointHomeResult} ต่อ {sumPointAwayResult}<br/>
                                        ความมั่นใจ : {percentTded!==undefined?`${percentTded}%`:'-'}</div>
                                    </div>
                                    </div>:
                                    <div>ขออภัย ! รายการนี้ไม่สามารถคำนวนได้</div>
                                :''}
                                <div className="funtongBox"><span className="text-gradient-v4">สกอร์ที่คาด {`${goalsHomeTarget} - ${goalsAwayTarget}`}</span></div>
                            </div>
                        </div>:
                        ''}
                    </div>:
                    this.state.selectTdedOrH2h==='h2h'?
                    <div className="seeH2hAll">
                        <Hva nameHome={this.state.homeInfo.thainame?this.state.homeInfo.thainame:this.state.homeInfo.name} nameAway={this.state.awayInfo.thainame?this.state.awayInfo.thainame:this.state.awayInfo.name} idHome={this.state.idHome} idAway={this.state.idAway} tourTemFK={this.state.infoMatch.tournament_templateFK} keyword='hva' connMobile={this.state.connMobile} selectTdedOrH2h={this.state.selectTdedOrH2h} key="hvaAll" />
                        <Hva nameHome={this.state.homeInfo.thainame?this.state.homeInfo.thainame:this.state.homeInfo.name} nameAway={this.state.awayInfo.thainame?this.state.awayInfo.thainame:this.state.awayInfo.name} idHome={this.state.idHome} idAway={this.state.idAway} tourTemFK={this.state.infoMatch.tournament_templateFK} keyword='home' connMobile={this.state.connMobile} selectTdedOrH2h={this.state.selectTdedOrH2h} key="homeAll" />
                        <Hva nameHome={this.state.homeInfo.thainame?this.state.homeInfo.thainame:this.state.homeInfo.name} nameAway={this.state.awayInfo.thainame?this.state.awayInfo.thainame:this.state.awayInfo.name} idHome={this.state.idHome} idAway={this.state.idAway} tourTemFK={this.state.infoMatch.tournament_templateFK} keyword='away' connMobile={this.state.connMobile} selectTdedOrH2h={this.state.selectTdedOrH2h} key="awayAll" />
                    </div>:
                    this.state.selectTdedOrH2h==='inc'?
                    <div>
                        <Inc homeInfo={this.state.homeInfo} awayInfo={this.state.awayInfo} homeInc={this.state.homeInc} awayInc={this.state.awayInc} idmatch={this.props.idmatch} connMobile={this.state.connMobile} ht={this.state.ht} ft={this.state.ft} key='inc' />
                    </div>:
                    <div>
                        <Lineup homeInfo={this.state.homeInfo} awayInfo={this.state.awayInfo} homeLineup={this.state.homeLineup} awayLineup={this.state.awayLineup} idmatch={this.props.idmatch} connMobile={this.state.connMobile} fmtHome={this.state.fmtHome} fmtAway={this.state.fmtAway} key='lineup' />
                    </div>
                    }
                 </div>:
                 <div style={{textAlign:'center'}} className="seeH2hAll"><LazyLoadImage className="running" src={`/images/running.gif`} alt={`running`} style={{width:'50px'}} /><br/>กำลังโหลดข้อมูล...</div>
         )
     }
}

export default Aimain;