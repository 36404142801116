import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Hva extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            loading : true,
            idHome : this.props.idHome?this.props.idHome:undefined,
            idAway : this.props.idAway?this.props.idAway:undefined,
            nameHome : this.props.nameHome?this.props.nameHome:undefined,
            nameAway : this.props.nameAway?this.props.nameAway:undefined,
            keyword : this.props.keyword?this.props.keyword:undefined,
            tourTemFK : this.props.tourTemFK?this.props.tourTemFK:undefined,
            connMobile : this.props.connMobile,
            list : [],
            HorA : this.props.HorA?this.props.HorA:'all',
            tour : this.props.tour?this.props.tour:'all', // focus & all
            selectTdedOrH2h : this.props.selectTdedOrH2h?this.props.selectTdedOrH2h:'tded',
            resultWDL : '',
            listDataNoChange : []
        }
    }

    componentDidMount(){
        let urlApi;
        if(this.state.keyword==='home'){
            urlApi =  `https://api.mvpkick.com/team?id=${this.state.idHome}&lastmatch=yes`;
        }else if(this.state.keyword==='away'){
            urlApi =  `https://api.mvpkick.com/team?id=${this.state.idAway}&lastmatch=yes`;
        }else if(this.state.keyword==='hva'){
            urlApi = `https://api.mvpkick.com/h2h/?idhome=${this.state.idHome}&idaway=${this.state.idAway}`;
        }

        axios.get(urlApi).then(res => {
            if(res.data.msg){ // request fail

                // s Case no list
                if(this.state.selectTdedOrH2h==='tded'){
                    if(this.state.keyword==='hva'&&this.props.reciveDataChildHva===false){
                        //console.log(`hva `+this.props.reciveDataChildHva)
                        this.props.confirm(undefined,undefined,undefined,'hva',undefined,undefined,undefined,'no')
                    }else if(this.state.keyword==='home'&&this.props.reciveDataChildHome===false){
                        //console.log(`home `+this.props.reciveDataChildHome)
                        this.props.confirm(undefined,undefined,undefined,'home',undefined,undefined,undefined,'no')
                    }else if(this.state.keyword==='away'&&this.props.reciveDataChildAway===false){
                        //console.log(`away `+this.props.reciveDataChildAway)
                        this.props.confirm(undefined,undefined,undefined,'away',undefined,undefined,undefined,'no')
                    }
                }
                // e Case no list

                this.setState({list : [], loading : false})
            }else{
                var {events} = res.data;
                let listforSet = [];
                for (const listEvent of events) {
                    listforSet.push(listEvent)
                }
                listforSet.sort((a, b) => new Date(b.startdate) - new Date(a.startdate));
                //this.setState({list : listforSet, loading : false})
                this.callData(listforSet)
            }
        })
    }


    componentDidUpdate(){
        ////console.log(this.state.HorA + ' ' + this.props.nameHome + this.props.nameAway + ' ' + this.state.keyword)
        if(this.state.loading){
            this.callData(this.state.listDataNoChange)
        }
        if(this.props.selectTdedOrH2h!==this.state.selectTdedOrH2h){this.setState({selectTdedOrH2h:this.props.selectTdedOrH2h})}

        //console.log(this.props.connMobile)
        if(this.props.connMobile!==this.state.connMobile){this.callData(this.state.listDataNoChange)}
    }

    setHva = (reciveHorA) =>{
        if(reciveHorA!==this.state.HorA){
            this.setState({HorA:reciveHorA,loading:true})
        }
    }

    callData = (listforSet) =>{
        let listData = [];
        var goals = [];
        var wdl = [];
        var resultWDL = '';
        ////console.log(listforSet)
        if(listforSet.length>0){
        listData = listforSet.map((item,index) =>{

            let {event_participants} = item;
            let homeInfo = {
                participant:{
                    id:'',
                    name:'',
                    gender:'',
                    countryFK:'',
                    country_name:'',
                    logo:''
                },
                result:{
                    ordinarytime:0,
                    halftime:0,
                    finalresult:0,
                    runningscore:0
                }
            }
            let awayInfo = {
                participant:{
                    id:'',
                    name:'',
                    gender:'',
                    countryFK:'',
                    country_name:'',
                    logo:''
                },
                result:{
                    ordinarytime:0,
                    halftime:0,
                    finalresult:0,
                    runningscore:0
                }
            }
            for (const listEventParticipants in event_participants) {
                let {number,participant,result} = event_participants[listEventParticipants];
                if(number==='1'){
                    homeInfo.participant = participant;
                    for (const results in result) {
                        let {result_code,value} = result[results]
                        switch(result_code){
                            case 'runningscore':homeInfo.result.runningscore=value;break;
                            case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                            case 'halftime':homeInfo.result.halftime=value;break;
                            case 'finalresult':homeInfo.result.finalresult=value;break;
                            default:break;
                        }
                    }
                }else if(number==='2'){
                    awayInfo.participant = participant;
                    for (const results in result) {
                        let {result_code,value} = result[results]
                        switch(result_code){
                            case 'runningscore':awayInfo.result.runningscore=value;break;
                            case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                            case 'halftime':awayInfo.result.halftime=value;break;
                            case 'finalresult':awayInfo.result.finalresult=value;break;
                            default:break;
                        }
                    }
                }
            }
            var selectTeamId;
            this.state.keyword==='home'||this.state.keyword==='hva'?selectTeamId=this.state.idHome:selectTeamId=this.state.idAway
            
            if(homeInfo.participant.id===selectTeamId){
                goals.push({
                    gfHome:parseInt(homeInfo.result.finalresult),
                    gfAway:0,
                    glHome:parseInt(awayInfo.result.finalresult),
                    glAway:0
                })
                if(homeInfo.result.finalresult-awayInfo.result.finalresult>0){
                    wdl.push({w:1,d:0,l:0});
                }else if(homeInfo.result.finalresult-awayInfo.result.finalresult===0){
                    wdl.push({w:0,d:1,l:0})
                }else if(homeInfo.result.finalresult-awayInfo.result.finalresult<0){
                    wdl.push({w:0,d:0,l:1})
                }
            }else if(awayInfo.participant.id===selectTeamId){
                
                goals.push({
                    gfHome:0,
                    gfAway:parseInt(awayInfo.result.finalresult),
                    glHome:0,
                    glAway:parseInt(homeInfo.result.finalresult)
                })
                if(awayInfo.result.finalresult-homeInfo.result.finalresult>0){
                    wdl.push({w:1,d:0,l:0});
                }else if(awayInfo.result.finalresult-homeInfo.result.finalresult===0){
                    wdl.push({w:0,d:1,l:0})
                }else if(awayInfo.result.finalresult-homeInfo.result.finalresult<0){
                    wdl.push({w:0,d:0,l:1})
                }
            }

            if(this.state.HorA==='home'&&homeInfo.participant.id!==selectTeamId){
                return undefined;
            }else if(this.state.HorA==='away'&&awayInfo.participant.id!==selectTeamId){
                return undefined;
            }

            if(this.state.tour!=='all'&&item.tournament_templateFK!==this.state.tourTemFK){
                return undefined;
            }
            
            if(this.state.selectTdedOrH2h==='tded'){
                return(
                    <div key={index}>
                        {this.props.connMobile===false?
                            // PC 
                            <div className="listMatch">
                                <div className="col-3 text-center">{item.startdate.substr(5,5)}</div>
                                <div className="col-2 text-right"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                                <div className="col-2 text-center ftScore"><span>{homeInfo.result.finalresult+' - '+awayInfo.result.finalresult}</span></div>
                                <div className="col-2"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /></div>
                                <div className="col-3 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                            </div>:
                            // Mobile 
                            <div className="listMatch">
                                <div className="col-3 text-center">{item.startdate.substr(5,5)}</div>
                                <div className="col-2 text-right"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                                <div className="col-2 text-center ftScore"><span>{homeInfo.result.finalresult+' - '+awayInfo.result.finalresult}</span></div>
                                <div className="col-2"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /></div>
                                <div className="col-3 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                            </div>
                        }
                    </div>
                )
            }else{
                return(
                    <div key={index}>
                        {this.props.connMobile===false?
                            // PC 
                            <div className="listMatch">
                                <div className="col-2 text-center">{item.startdate.substr(0,10)}<br />{item.tournament_template_name}</div>
                                <div className="col-3 text-right">{homeInfo.participant.name}<LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                                <div className="col-2 text-center ftScore"><span>{homeInfo.result.finalresult+' - '+awayInfo.result.finalresult}</span></div>
                                <div className="col-3"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} />{awayInfo.participant.name}</div>
                                <div className="col-2 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                            </div>:
                            // Mobile 
                            <div className="listMatch">
                                <div className="col-2 text-center">{item.startdate.substr(0,10)}<br />{item.tournament_template_name}</div>
                                <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} />{homeInfo.participant.name}<br /><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} />{awayInfo.participant.name}</div>
                                <div className="col-1 text-center">{homeInfo.result.finalresult}<br />{awayInfo.result.finalresult}</div>
                                <div className="col-1 text-center">{homeInfo.result.halftime}<br />{awayInfo.result.halftime}</div>
                                <div className="col-2 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                            </div>
                        }
                    </div>
                )
            }
        })
        
        let wdlAll = [...wdl];
        let goalsAll = [...goals];

        //console.log(wdl)
        //console.log(wdlAll)

        for(let z=0;z<listData.length;z++){
            if(listData[z]===undefined){
                listData.splice(z,1)
                wdl.splice(z,1)
                goals.splice(z,1)
                z--;
            }
        }

        //console.log(wdl)
        //console.log(wdlAll)

        if(this.state.selectTdedOrH2h==='tded'){ //คำนวนจาก 10 รายการแรกเท่านั้น
            listData.splice(10)
            wdl.splice(10)
            goals.splice(10)
        }

        var sum = {w:0,d:0,l:0} // อิงจาก id Team ที่โยนเข้ามา ไม่ใช่ทีมเหย้าแต่ละคู่
        var sumAway = {w:0,d:0,l:0} // อิงจากทีมอื่นๆในแต่ละคู่ ไม่ใช่ id team ที่โยนเข้ามา
        var sumGoals = {gfHome:0,glHome:0,gfAway:0,glAway:0}
        let last5match = {
            sum : {w:0,d:0,l:0},
            sumGoals : {gfHome:0,glHome:0,gfAway:0,glAway:0}
        }
        for(let i=0;i<wdl.length;i++){
            switch(1){
                case wdl[i].w:sum.w++;sumAway.l++;break;
                case wdl[i].d:sum.d++;sumAway.d++;break;
                case wdl[i].l:sum.l++;sumAway.w++;break;
                default:break;
            }
            sumGoals.gfHome += goals[i].gfHome;
            sumGoals.gfAway += goals[i].gfAway;
            sumGoals.glHome += goals[i].glHome;
            sumGoals.glAway += goals[i].glAway;

            if(i==4){ // sent data set 1 for last5match variable
                last5match = {
                    sum : {
                        w:sum.w,
                        d:sum.d,
                        l:sum.l
                    },
                    sumGoals : {
                        gfHome:sumGoals.gfHome,
                        glHome:sumGoals.glHome,
                        gfAway:sumGoals.gfAway,
                        glAway:sumGoals.glAway
                    }
                }
            }
        }

        /* s all match case */
        var sumAll = {w:0,d:0,l:0} // อิงจาก id Team ที่โยนเข้ามา ไม่ใช่ทีมเหย้าแต่ละคู่
        var sumAwayAll = {w:0,d:0,l:0} // อิงจากทีมอื่นๆในแต่ละคู่ ไม่ใช่ id team ที่โยนเข้ามา
        var sumGoalsAll = {gfHome:0,glHome:0,gfAway:0,glAway:0}
        let last5matchAll = {
            sum : {w:0,d:0,l:0},
            sumGoals : {gfHome:0,glHome:0,gfAway:0,glAway:0}
        }
        for(let i=0;i<wdlAll.length;i++){
            switch(1){
                case wdlAll[i].w:sumAll.w++;sumAwayAll.l++;break;
                case wdlAll[i].d:sumAll.d++;sumAwayAll.d++;break;
                case wdlAll[i].l:sumAll.l++;sumAwayAll.w++;break;
                default:break;
            }
            sumGoalsAll.gfHome += goalsAll[i].gfHome;
            sumGoalsAll.gfAway += goalsAll[i].gfAway;
            sumGoalsAll.glHome += goalsAll[i].glHome;
            sumGoalsAll.glAway += goalsAll[i].glAway;

            if(i==4){ // sent data set 1 for last5match variable
                last5matchAll = {
                    sum : {
                        w:sumAll.w,
                        d:sumAll.d,
                        l:sumAll.l
                    },
                    sumGoals : {
                        gfHome:sumGoalsAll.gfHome,
                        glHome:sumGoalsAll.glHome,
                        gfAway:sumGoalsAll.gfAway,
                        glAway:sumGoalsAll.glAway
                    }
                }
            }
        }
        /* e all match case */
        ////console.log(listData)
        ////console.log(wdlAll)
        ////console.log(goals)
        ////console.log(sum)
        ////console.log(sumGoals)

        
        resultWDL = (
            <div className="tournamentH2H" key='hvaResult'>
                <div className="col-12 text-center">รวม {listData.length} ชนะ {sum.w} เสมอ {sum.d} แพ้ {sum.l}</div>
            </div>
        )

        /* s charts hva */
        let arrSetWDL = []
        wdl.reverse()
        for(let i=0;i<wdl.length;i++){
            if(i==0){
                if(wdl[i].w==1){arrSetWDL.push(1)}
                else if(wdl[i].d==1){arrSetWDL.push(0)}
                else if(wdl[i].l==1){arrSetWDL.push(-1)}
            }else{
                if(wdl[i].w==1){arrSetWDL.push(arrSetWDL[i-1]+1)}
                else if(wdl[i].d==1){arrSetWDL.push(arrSetWDL[i-1]+0)}
                else if(wdl[i].l==1){arrSetWDL.push(arrSetWDL[i-1]-1)}
            }
        }
        let allMatch = arrSetWDL.length
        let wdlSet = {wdl:arrSetWDL,matchcount:allMatch}
        /* e charts hva */

        if(this.state.selectTdedOrH2h==='tded'){
            if(this.state.keyword==='hva'&&this.props.reciveDataChildHva===false){
                //console.log(`hva `+this.props.reciveDataChildHva)
                this.props.confirm(sum,sumGoals,last5match,this.state.keyword,sumAll,sumGoalsAll,last5matchAll,'yes',wdlSet)
            }else if(this.state.keyword==='home'&&this.props.reciveDataChildHome===false){
                //console.log(`home `+this.props.reciveDataChildHome)
                this.props.confirm(sum,sumGoals,last5match,this.state.keyword,sumAll,sumGoalsAll,last5matchAll,'yes',wdlSet)
            }else if(this.state.keyword==='away'&&this.props.reciveDataChildAway===false){
                //console.log(`away `+this.props.reciveDataChildAway)
                this.props.confirm(sum,sumGoals,last5match,this.state.keyword,sumAll,sumGoalsAll,last5matchAll,'yes',wdlSet)
            }
        }

        
        } // end if listForSet.length>0

        ////console.log(listData)

        this.setState({
            list : listData,
            loading : false,
            resultWDL : resultWDL,
            listDataNoChange : listforSet,
            connMobile : this.props.connMobile
        })
    }

    render() {
        //console.log(this.props.reciveDataChildHva)
        //console.log(this.state.keyword)
        return (
            
                this.state.list.length>0?
                <div>
                    <h4 className="text-center"><span className="text-gradient-v2">{this.state.keyword==='hva'?`สถิติพบกัน`:this.state.keyword==='home'?`สถิติ ${this.state.nameHome}`:`สถิติ ${this.state.nameAway}`}</span></h4>
                <div className="text-center selectHvaZone">
                    <div className={this.state.HorA==='home'?'active btn-h2h col-4':'btn-h2h col-4'} onClick={(e)=>this.setHva('home')}>Home</div>
                    <div className={this.state.HorA==='all'?'active btn-h2h col-4':'btn-h2h col-4'} onClick={(e)=>this.setHva('all')}>All</div>
                    <div className={this.state.HorA==='away'?'active btn-h2h col-4':'btn-h2h col-4'} onClick={(e)=>this.setHva('away')}>Away</div>
                </div>
                {this.state.list}
                {this.state.resultWDL}
                </div>:
                <div>
                <h4 className="text-center"><span className="text-gradient-v2">{this.state.keyword==='hva'?`สถิติพบกัน`:this.state.keyword==='home'?`สถิติ ${this.state.nameHome}`:`สถิติ ${this.state.nameAway}`}</span></h4>
                <div className="listMatch"><div className="col-12 text-center nomatchData">ไม่มีรายการ{this.state.keyword==='hva'?`พบกัน`:this.state.keyword==='home'?`แข่งทีมเหย้า`:`แข่งทีมเยือน`}</div></div>
                </div>
        )
    }

}

export default Hva;