import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Formation from './Formation'

class Lineup extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            homeLineup : this.props.homeLineup?this.props.homeLineup:[],
            awayLineup : this.props.awayLineup?this.props.awayLineup:[],
            idmatch : this.props.idmatch,
            connMobile : this.props.connMobile,
            homeInfo : this.props.homeInfo,
            awayInfo : this.props.awayInfo,
            fmtHome : this.props.fmtHome,
            fmtAway : this.props.fmtAway
        }
    }

    componentDidMount(){
        //console.log(this.state)
    }

    render() {
        let homeLineup = []
        let awayLineup = []
        homeLineup = this.state.homeLineup.map((item,index) => {
            let lineup_typeFKThai = '';
            switch (item.lineup_typeFK) {
                case '1':lineup_typeFKThai='GoalKeeper';break;
                case '2':lineup_typeFKThai='Defence';break;
                case '3':lineup_typeFKThai='Midfield';break;
                case '4':lineup_typeFKThai='Forward';break;
                case '5':lineup_typeFKThai='Substitute';break;
                case '7':lineup_typeFKThai='Injured';break;
                case '10':lineup_typeFKThai='Coach';break;
                default:lineup_typeFKThai=item.lineup_typeFK;break;
            }
            return(
                <div key={index} className='homeLineupRow'>
                    {index===0||(index>0&&item.lineup_typeFK!==this.state.homeLineup[index-1].lineup_typeFK)?<div className="typePlayer col-12"><LazyLoadImage src={this.state.homeInfo.logo} alt={this.state.homeInfo.name} className='logoPlayer' />{lineup_typeFKThai}</div>:''}
                    <div className='col-10'>
                        <LazyLoadImage src={item.participant.imgplayer} alt={item.participant.thainame?item.participant.thainame:item.participant.name} className='logoPlayer'/>
                        {item.participant.thainame?item.participant.thainame:item.participant.name}
                    </div>
                    <div className='col-2'>
                        {item.shirt_number}
                    </div>
                </div>
            )
        })
        awayLineup = this.state.awayLineup.map((item,index) => {
            let lineup_typeFKThai = '';
            switch (item.lineup_typeFK) {
                case '1':lineup_typeFKThai='GoalKeeper';break;
                case '2':lineup_typeFKThai='Defence';break;
                case '3':lineup_typeFKThai='Midfield';break;
                case '4':lineup_typeFKThai='Forward';break;
                case '5':lineup_typeFKThai='Substitute';break;
                case '7':lineup_typeFKThai='Injured';break;
                case '10':lineup_typeFKThai='Coach';break;
                default:lineup_typeFKThai=item.lineup_typeFK;break;
            }
            return(
                <div key={index} className='awayLineupRow'>
                    {index===0||(index>0&&item.lineup_typeFK!==this.state.awayLineup[index-1].lineup_typeFK)?<div className="typePlayer col-12"><LazyLoadImage src={this.state.awayInfo.logo} alt={this.state.awayInfo.name} className='logoPlayer' />{lineup_typeFKThai}</div>:''}
                    <div className='col-10'>
                        <LazyLoadImage src={item.participant.imgplayer} alt={item.participant.thainame?item.participant.thainame:item.participant.name} className='logoPlayer'/>
                        {item.participant.thainame?item.participant.thainame:item.participant.name}
                    </div>
                    <div className='col-2'>
                        {item.shirt_number}
                    </div>
                </div>
            )
        })
        
        return (
            <div>
                <div className='confirmLineup'>
                    <div className='col-6'>
                        <h3 style={{textAlign:'center'}}>{this.state.homeInfo.name}</h3>
                        <div className='col-10'><span style={{paddingLeft:'5px'}}>Name</span></div><div className='col-2'>No.</div>
                        {homeLineup.length>0?homeLineup:<div><span style={{paddingLeft:'5px',textAlign:'center'}}>No Lineup</span></div>}
                    </div>
                    <div className='col-6'>
                        <h3 style={{textAlign:'center'}}>{this.state.awayInfo.name}</h3>
                        <div className='col-10'><span style={{paddingLeft:'5px'}}>Name</span></div><div className='col-2'>No.</div>
                        {awayLineup.length>0?awayLineup:<div><span style={{paddingLeft:'5px',textAlign:'center'}}>No Lineup</span></div>}
                    </div>
                </div>
                {this.state.fmtHome!==undefined?<Formation fmt={this.state.fmtHome} HorA='home' lineupFmt={this.state.homeLineup} logo={this.state.homeInfo.logo} />:''}
                {this.state.fmtAway!==undefined?<Formation fmt={this.state.fmtAway} HorA='away' lineupFmt={this.state.awayLineup} logo={this.state.awayInfo.logo} />:''}
            </div>
        )
    }

}

export default Lineup;