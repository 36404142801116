import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Reward extends React.Component{

    componentDidMount(){
    }

    render() {
        return (
            <div>
                <div className="mainMemberPage">
                    <div className="boxls rewardZone">
                            <img src="/images/rewards/promotion_deposit_aitded_v2.webp" className='posterReward' />
                            <div class="cardBorder boxContentGacha" style={{marginTop: '20px', marginBottom: '10px'}}>
                            <div class="howtoReciveGacha text-center"><h2>กติการับรางวัลยอดฝากประจำเดือน</h2>
                            <p>เพียงสมาชิกมียอดฝากครบตามเงื่อนไข สามารถติดต่อแจ้งเจ้าหน้าที่ <span style={{color:'rgb(62, 218, 255)'}}>"รับรางวัลเงินฝากประจำเดือน"</span> ได้เลย เงินรางวัลทั้งหมดจำถูกเพิ่มให้ภายใน 24 ชั่วโมง เงื่อนไขการถอนโบนัสต้องทำเทิร์น 10 เท่าของยอดโบนัส สามารถทยอยแจ้งขอแลกรางวัลได้ทุกครั้งเมื่อถึงยอดฝากขั้นต่ำของแต่ละระดับ VIP หรือสามารถขอรวดเดียว (ก่อนสิ้นเดือน) ก็ได้เช่นกัน 
                            การคำนวนยอดฝากจะคิดจากวันที่ 1 ของเดือนจนถึงวันสุดท้ายของเดือนนั้นๆ</p>
                            <p>ตัวอย่างการคำนวนรางวัล : ลูกค้าฝากรวมทั้งเดือน 50,000 บาท (ถึงเงื่อนไขรางวัล VIP 4) หากไม่เคยแลกรางวัลมาก่อนภายในเดือนนั้นๆ ลูกค้าจะได้รับโบนัสรวมทั้งหมด VIP 1 (100) + VIP 2 (200) + VIP 3 (300) + VIP 4 (400) = <span style={{color:'orange'}}>รวมโบนัส 1,000 บาท</span> ทำเทิร์น 10 เท่า ( ครบ 10,000 ไม่รวมเงื่อนไขของเว็บไซต์พันธมิตรนั้นๆ ถ้ามี ) สามารถถอนได้ทันที 
                                กรณีลูกค้ามีการแลกรางวัล VIP 1, VIP 2, VIP 3 ไปก่อนหน้าแล้วของรางัวลภายในเดือนนั้นๆ ก็จะได้รับเพียงรางวัล VIP 4 = <span style={{color:'orange'}}>400 บาท</span>
                            </p>
                            <p><span style={{color:'#00ffd2'}}>สำคัญ : ยอดเงินฝาก จะคำนวนเฉพาะรายการฝากทั้งหมดของเดือนปัจจุบันเท่านั้น ไม่สามารถคำนวนหรือแลกย้อนหลังได้ อย่าลืมแจ้งเจ้าหน้า "แลกรางวัลเงินฝากประจำเดือน" ก่อนสิ้นเดือนด้วยนะคะ</span></p>
                            <p><span style={{color:'#ff687e'}}>หมายเหตุ : หากผู้เล่นมีการหาผลโปรโยชน์จากโปรโมชั่นในทางทุจริตจากระบบ ทีมงานขอสงวนสิทธิ์ยึดเครดิตและโบนัสทุกกรณี</span></p>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
            
}

export default Reward;
