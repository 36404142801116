import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Inc extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            idmatch : this.props.idmatch,
            connMobile : this.props.connMobile,
            homeInfo : this.props.homeInfo,
            awayInfo : this.props.awayInfo,
            homeInc : this.props.homeInc?this.props.homeInc:[],
            awayInc : this.props.awayInc?this.props.awayInc:[], 
            key : this.props.key,
            ht : this.props.ht,
            ft : this.props.ft
        }
    }


    componentDidMount(){
        
    }

    render() {
        let allInc = [...this.state.homeInc,...this.state.awayInc]
        allInc.sort((a,b)=>a.elapsed-b.elapsed)
        
        return (
            <div className='incBoxSet'>
                {
                allInc.length>0?
                allInc.map((item,index) => {
                    if((index>0&&allInc[index-1].elapsed<=45&&item.elapsed>45)||(allInc.length===1&&item.elapsed>45)||(index===0&&allInc.length>1&&item.elapsed>45)){
                        return(
                            item.team==='home'?
                            <div key={index} className="incRowLs homeIncRowLs">
                                <div style={{textAlign:'center',backgroundColor:'#212639',margin:'10px',padding:'5px',borderRadius:'1rem'}}>HT : {this.state.ht}</div>
                                <div>{item.elapsed}{item.elapsed_plus>0?`+${item.elapsed_plus}`:''}' <LazyLoadImage src={item.incImg} className='imgIncIcon' /> {item.participant!==undefined?item.participant.thainame!==null?item.participant.thainame:item.participant.name:''}</div>
                            </div>:
                            <div key={index} className="incRowLs awayIncRowLs">
                            <div style={{textAlign:'center',backgroundColor:'#212639',margin:'10px',padding:'5px',borderRadius:'1rem'}}>HT : {this.state.ht}</div>
                                <div>{item.participant!==undefined?item.participant.thainame!==undefined?item.participant.thainame:item.participant.name:''} <LazyLoadImage src={item.incImg} className='imgIncIcon' /> {item.elapsed}{item.elapsed_plus>0?`+${item.elapsed_plus}`:''}'</div>
                            </div>
                        )
                    }else if(allInc.length===1&&item.elapsed<=45){
                        return(
                            item.team==='home'?
                            <div key={index} className="incRowLs homeIncRowLs">
                                <div>{item.elapsed}{item.elapsed_plus>0?`+${item.elapsed_plus}`:''}' <LazyLoadImage src={item.incImg} className='imgIncIcon' /> {item.participant!==undefined?item.participant.thainame!==null?item.participant.thainame:item.participant.name:''}</div>
                                <div style={{textAlign:'center',backgroundColor:'#212639',margin:'10px',padding:'5px',borderRadius:'1rem'}}>HT : {this.state.ht}</div>
                            </div>:
                            <div key={index} className="incRowLs awayIncRowLs">
                                <div>{item.participant!==undefined?item.participant.thainame!==undefined?item.participant.thainame:item.participant.name:''} <LazyLoadImage src={item.incImg} className='imgIncIcon' /> {item.elapsed}{item.elapsed_plus>0?`+${item.elapsed_plus}`:''}'</div>
                                <div style={{textAlign:'center',backgroundColor:'#212639',margin:'10px',padding:'5px',borderRadius:'1rem'}}>HT : {this.state.ht}</div>
                            </div>
                        )
                    }else{
                        return(
                            item.team==='home'?
                            <div key={index} className="incRowLs homeIncRowLs">
                                <div>{item.elapsed}{item.elapsed_plus>0?`+${item.elapsed_plus}`:''}' <LazyLoadImage src={item.incImg} className='imgIncIcon' /> {item.participant!==undefined?item.participant.thainame!==null?item.participant.thainame:item.participant.name:''}</div>
                            </div>:
                            <div key={index} className="incRowLs awayIncRowLs">
                                <div>{item.participant!==undefined?item.participant.thainame!==undefined?item.participant.thainame:item.participant.name:''} <LazyLoadImage src={item.incImg} className='imgIncIcon' /> {item.elapsed}{item.elapsed_plus>0?`+${item.elapsed_plus}`:''}'</div>
                            </div>
                        )
                    }
                }):
                <div style={{textAlign:'center',backgroundColor:'#212639',margin:'10px',padding:'5px',borderRadius:'1rem'}}>ไม่มีข้อมูลรายการนี้</div>
                }
               {allInc.length>0?<div style={{textAlign:'center',backgroundColor:'#212639',margin:'10px',padding:'5px',borderRadius:'1rem'}}>FT : {this.state.ft}</div>:''}
            </div>
        )
    }

}

export default Inc;