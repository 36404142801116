import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

class Chartsh2h extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            listHome : this.props.wdlHome?this.props.wdlHome:null,
            listAway : this.props.wdlAway?this.props.wdlAway:null,
            idHome:this.props.idHome?this.props.idHome:null,
            idAway:this.props.idAway?this.props.idAway:null,
            homeInfo:this.props.homeInfo?this.props.homeInfo:null,
            awayInfo:this.props.awayInfo?this.props.awayInfo:null,
        }
    }

    componentDidMount(){
        //console.log(this.state)
        if(this.state.idHome!==null&&this.state.idAway!==null){
            const ctx = document.getElementById(`myChart${this.state.idHome}and${this.state.idAway}`).getContext('2d');
            
            
            const data1 = [];
            const data2 = [];
            let prev1 = 0;
            let prev2 = 0;
            /*
            for (let i = 0; i < allMacthCountSelect; i++) {
                prev1 += 5 - Math.random() * 10;
                data1.push({x: i, y: prev1});
                prev2 += 5 - Math.random() * 10;
                data2.push({x: i, y: prev2});
            }
            */
            for (let i = 0; i < this.state.listHome.matchcount; i++) {
                prev1 = this.state.listHome.wdl[i];
                data1.push({x: (i+1), y: prev1});
            }
            for (let i = 0; i < this.state.listAway.matchcount; i++) {
                prev2 = this.state.listAway.wdl[i];
                data2.push({x: (i+1), y: prev2});
            }
            /*
            for (let i = 0; i < this.state.listHome.matchcount; i++) {
                prev1 = this.state.listHome.wdl;
                data1.push({x: i, y: prev1});
            }
            for (let i = 0; i < this.state.listAway.matchcount; i++) {
                prev2 = this.state.listAway.wdl;
                data2.push({x: i, y: prev2});
            }*/
            const data = {
                datasets: [{
                  borderColor: '#00e88b',
                  borderWidth: 3,
                  radius: 3,
                  data: data1,
                },
                {
                  borderColor: '#fff',
                  borderWidth: 3,
                  radius: 3,
                  data: data2,
                }]
            }



            const totalDuration = 3000;
            const delayBetweenPoints = totalDuration / data1.length;
            const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
            const animation = {
                x: {
                    type: 'number',
                    easing: 'linear',
                    duration: delayBetweenPoints,
                    from: 1, // the point is initially skipped
                    delay(ctx) {
                    if (ctx.type !== 'data' || ctx.xStarted) {
                        return 0;
                    }
                    ctx.xStarted = true;
                    return ctx.index * delayBetweenPoints;
                    }
                },
                y: {
                    type: 'number',
                    easing: 'linear',
                    duration: delayBetweenPoints,
                    from: previousY,
                    delay(ctx) {
                    if (ctx.type !== 'data' || ctx.yStarted) {
                        return 0;
                    }
                    ctx.yStarted = true;
                    return ctx.index * delayBetweenPoints;
                    }
                }
            };

            const myChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    //animation,
                    interaction: {
                      intersect: false
                    },
                    plugins: {
                      legend: false
                      
                    },
                    scales: {
                      x: {
                        type: 'linear',
                        beginAtZero: false
                      }
                    }
                }
            })
        }
            
    }

    render(){
        return(
            this.state.listHome!==null&&this.state.listAway!==null?
            <div className="Charts-w100">
            <div className="boxMainCharts">
                <div className="chertH2hLineAni">
                    <div className="chertH2hTeam">
                        <LazyLoadImage className="logoTeamPage H2hTopLogo" alt={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} src={this.state.homeInfo.logo} /><br/>{this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name}
                        <div style={{border:'2px solid #00e88b',marginTop: '5px'}}></div>
                    </div>
                    <div className="chertH2hTeam">
                        <LazyLoadImage className="logoTeamPage H2hTopLogo" alt={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} src={this.state.awayInfo.logo} /><br/>{this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name}
                        <div style={{border:'2px solid #fff',marginTop: '5px'}}></div>
                    </div>
                </div>
                <canvas id={`myChart${this.state.idHome}and${this.state.idAway}`} max-width="400" max-height="400"></canvas>
            </div>
            </div>:
            ''
        )
    }
}

export default Chartsh2h;