import React from 'react'
import store from '../../store/configstore'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'


class Realtimegacha extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            connMobile : this.props.connMobile,
            listRealtimeGacha : [],
            listGacha : this.props.listGacha
        }
    }

    callApiLastGacha = () =>{
        axios.get(`https://sv.aitded.com/logsgacha`).then(res =>{
            if(res.data.error===undefined){
                this.setState({listRealtimeGacha:res.data})
            }
        })
    }
    
    componentDidMount(){
        this.callApiLastGacha()
        setInterval(this.callApiLastGacha, 300000); // รันตารางใหม่ทุก 5 นาที
    }

    componentWillUnmount(){
        clearInterval(this.callApiLastGacha)
    }

    render() {
        return (
            <div>

                <div className="sign text-center">
                    <span className="fast-flicker">ร</span>างวัลออก <span className="fast-flicker">30</span> รายการ<span className="flicker">ล่า</span>สุด
                </div>
                <div className='boxRealtimeReward'>
                
                {this.state.listRealtimeGacha.length>0?
                this.state.listRealtimeGacha.map((item,index)=>{
                    return(
                        <div key={index}>
                            {item.thaitime.substr(11,8)} : {item.user.substr(0,(item.user.length-2) )}xx<br/>ได้รับ {this.state.listGacha[this.state.listGacha.findIndex(({code}) => code === item.reviceGachaCode)].name} {item.sum!==undefined?item.sum:1} ชื้น
                            <LazyLoadImage src={`/images/reward/${this.state.listGacha[this.state.listGacha.findIndex(({code}) => code === item.reviceGachaCode)].img}`} alt={this.state.listGacha[this.state.listGacha.findIndex(({code}) => code === item.reviceGachaCode)].name} className='imgRealtimeReward'/>
                        </div>
                    )
                })
                :<div>ddd</div>}
                </div>
            </div>
        )
    }
}

export default Realtimegacha;