import React from 'react'
import axios from 'axios'
import  { Navigate } from 'react-router-dom'
import store from '../store/configstore'

class Homeadmin extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            username : null
        }
     }
     submitForm = (event) => {

        axios.post(`https://sv.aitded.com/admin_rockstar`,{username: event.target[0].value,password: event.target[1].value}).then(res =>{
            
            if(res.data.error===undefined){
                //console.log('login success')
                store.dispatch({ 
                    type: 'login',
                    data: {...res.data,level:'admin'}
                });
                this.setState({
                    username : event.target[0].value
                })
            }else{
                alert('Username หรือ Password ไม่ถูกต้อง')
                //console.log('login no success')
                //console.log(res.data)
                store.dispatch({ 
                    type: 'logout',
                    data: {username:undefined,expire:undefined,level:undefined}
                });
                this.setState({
                    username : null
                })
            }
        })
        event.preventDefault();
     }
     render() {
        //console.log(store.getState())
         //console.log(this.state)
         return (
                store.getState().username!==undefined&&store.getState().level==='admin'?<Navigate to={{ pathname: "/admin_rockstar"}} />:
                <div className="main-container">
                    <video autoPlay muted loop="yes" id="myVideo">
                        <source src="/videos/bg_video.mp4" type="video/mp4" width="100%" />
                    </video>
                    {/*<div className="main-content">
                        <div className="video_poster">
                            <video className="configVDO" controls="" poster="https://luck77bet.com/images/logo/poster_vdo.jpg" src="https://avporn69.b-cdn.net/adsvdo/luck77bet.mp4"></video>
                        </div>
         </div>*/}
                    <div className="main-login contentSalePage cardBorder adminLoginBox">
                        <div className="row">                    
                            <div className="space-6"></div>
                            <div className="login-box">
                                <div className="box">
                                    <h1 className="text-gradient">AITDED.COM (Admin)</h1>
                                    <form onSubmit={this.submitForm} id="chkLogin" method="post" role="form">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="usr" placeholder="username" required />          
                                        </div>
                                        <div className="form-group" style={{marginTop: '15px',marginBottom: '15px'}}>
                                            <input type="password" className="form-control" name="pwd" placeholder="password" required />
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12 col-xs-12">
                                                <button type="submit" value="Submit" className="btn btn-gradient-border btn-glow">Login</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
         )
     }
    
}

export default Homeadmin;