const Islogin = (state = {username : undefined, expire : undefined,level : undefined, pwd:undefined, gacha : 0,listGachaItem : undefined,exp : 0},action) =>{
    
    switch(action.type){
        case 'login':
            state = action.data
            return state;
        case 'logout':
            state = {username : undefined, expire : undefined,level : undefined, pwd:undefined, gacha : 0,listGachaItem : undefined}
            return state;
        case 'gacha':
            state = action.data
            return state;
        default:
            return state;
    }
    
}

export default Islogin;