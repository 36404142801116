import React from 'react'
import axios from 'axios'
import  { Navigate } from 'react-router-dom'
import store from '../store/configstore'

class Adminrockstar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            username : store.getState().username?store.getState().username:undefined,
            level : store.getState().level?store.getState().level:undefined,
            form : {
                user : '',
                pwd : '',
                userChangePass : '',
                pwdChangePass : '',
                pwdChangePassConfirm : '',
                userAddDay : '',
                usergacha : '',
                numgacha : '',
                usergachacheck : '',
                usergachaauto : '',
                radioBtnUsergachaauto : '',
                userGetReward: '',
                userlevelrewardcheck:'',
                userGetLevelReward:''
            },
            contentCheckGacha : '',
            contentCheckLevelRewardRecived : '',
            objGachaAuto:{
                msg:undefined,
                error:undefined,
                listDeposit:[],
                sumGacha:0,
                sumDeposit:0,
                listDepositCalGacha:[]
            },
            userListReward:[],
            confirmCheckReward:false,
            expCanReciveLevelReward:0,
            confirmCheckLevelReward:false,
            exp:0,
            expPayCompleted:0
        }
        
         
     }

     listGacha = [

        {code : 0, name : 'ชิ้นส่วน Iphone 14 6.1 นิ้ว 128GB มูลค่า 32,900 บาท',full : 100,img : 'iphone-13.png',price : 32900},
        {code : 1, name : 'ชิ้นส่วน MacBook Air M1 13 นิ้ว 256GB มูลค่า 34,900 บาท',full : 100,img : 'MacBook-Air-13-นิ้ว.png',price : 34900},
        {code : 2, name : 'ชิ้นส่วน ทองคำ 1 บาท มูลค่า 26,500 บาท',full : 100,img : 'gold.png',price : 26500},
        {code : 3, name : 'ชิ้นส่วน Sumsung Galaxy Z Fold4 256GB มูลค่า 59,900 บาท',full : 100,img : 'galaxy-z-fold3-5g.png',price : 59900},
        {code : 4, name : 'ชิ้นส่วน Apple Watch Series 8 มูลค่า 15,900 บาท',full : 50,img : 'Apple-Watch-Series-7.png',price : 15900},
        {code : 5, name : 'ชิ้นส่วน Honda New PCX 160 มูลค่า 93,400 บาท',full : 200,img : 'new-pcx-160.png',price : 93400},
        {code : 6, name : 'ชิ้นส่วน Sony X80H 4K UltraHD 43 นิ้ว มูลค่า 17,490 บาท',full : 50,img : 'Sony-X80H-4K-Ultra-HD-43-นิ้ว.png',price : 17490},
        {code : 7, name : 'ชิ้นส่วน AirPods Pro มูลค่า 8,992 บาท',full : 50,img : 'AirPods-Pro.png',price : 8992},
        {code : 8, name : 'ชิ้นส่วน ทองคำ 50 สตางค์ มูลค่า 13,250 บาท',full : 100,img : 'gold-50.png',price : 13250},
        {code : 9, name : 'ชิ้นส่วน Ps5 Blu Ray Edition มูลค่า 16,990 บาท',full : 75,img : 'ps5-Blu-Ray-Edition.png',price : 16990},
        {code : 10, name : 'ชิ้นส่วน เงินสด มูลค่า 10,000 บาท',full : 50,img : 'money-10k.png',price : 10000},
        {code : 11, name : 'ชิ้นส่วน เงินสด มูลค่า 5,000 บาท',full : 30,img : 'money-5k.png',price : 5000},
        {code : 12, name : 'ชิ้นส่วน เงินสด มูลค่า 2,000 บาท',full : 30,img : 'money-2k.png',price : 2000},
        {code : 13, name : 'ชิ้นส่วน เงินสด มูลค่า 1,000 บาท',full : 30,img : 'money-1k.png',price : 1000},
        {code : 14, name : 'ชิ้นส่วน เครดิตฟรี 500 บาท',full : 30,img : 'credit-500.png',price : 500},
        {code : 15, name : 'ชิ้นส่วน เครดิตฟรี 400 บาท',full : 30,img : 'credit-400.png',price : 400},
        {code : 16, name : 'ชิ้นส่วน เครดิตฟรี 300 บาท',full : 30,img : 'credit-300.png',price : 300},
        {code : 17, name : 'ชิ้นส่วน เครดิตฟรี 200 บาท',full : 30,img : 'credit-200.png',price : 200},
        {code : 18, name : 'ชิ้นส่วน เครดิตฟรี 100 บาท',full : 30,img : 'credit-100.png',price : 100},
        {code : 19, name : 'ชิ้นส่วน ไข่ที่แตกแล้ว',full : 500,img : 'egg-open.gif',price : 0},
        {code : 20, name : 'ชิ้นส่วน iPad Pro 12.9 นิ้ว 128GB มูลค่า 44,900 บาท',full : 100,img : 'ipad-pro-12-9.png',price : 44900},
        {code : 21, name : 'ชิ้นส่วน Nintendo Switch OLED Model มูลค่า 13,890 บาท',full : 50,img : 'Nintendo-Switch-OLED-Model.png',price : 13890},
        {code : 22, name : 'ชิ้นส่วน iMac Retina 5K รุ่น 27 นิ้ว 256GB มูลค่า 62,900 บาท',full : 150,img : 'iMac-Retina-5K-27-inc.png',price : 62900},
        {code : 23, name : 'ชิ้นส่วน ทองคำ 25 สตางค์ มูลค่า 6,625 บาท',full : 50,img : 'gold-25-satang.png',price : 6625},
        {code : 24, name : 'ชิ้นส่วน เงินสด มูลค่า 900 บาท',full : 30,img : 'money-900.png',price : 900},
        {code : 25, name : 'ชิ้นส่วน เงินสด มูลค่า 750 บาท',full : 30,img : 'money-750.png',price : 750},
        {code : 26, name : 'ชิ้นส่วน เครดิตฟรี 50 บาท',full : 15,img : 'credit-50.png',price : 50},
        {code : 27, name : 'ชิ้นส่วน เครดิตฟรี 25 บาท',full : 10,img : 'credit-25.png',price : 25},
    ]

     
     logoutActice = () =>{
        store.dispatch({  type: 'logout'});
        this.setState({username : undefined})
     }

     submitFormGetReward = (event) => {
        event.preventDefault();
        axios.post(`https://sv.aitded.com/checkreward`,{username: event.target[0].value,status:'check'}).then(res =>{
            if(res.data.err_msg===undefined){
              //  console.log(res.data)
                if(res.data.listGachaItem.length>0){
                    alert('พบข้อมูลลูกค้าในระบบ')
                    this.setState({userListReward:res.data.listGachaItem,confirmCheckReward:true})
                }else{
                    alert('ไม่มีรางวัลที่แลกได้')
                    this.setState({confirmCheckReward:true})
                }
            }else{
                alert('ไม่พบข้อมูลลูกค้า')
            }
        }).catch(err=>{
            console.log(err)
            alert('เช็คข้อมูลล้มเหลว โปรดแจ้ง Admin')
        })
     }
     
     cfReward = async () =>{
        let oldArr = this.state.userListReward
        let newArr = []
        for(let i=0;i<this.state.userListReward.length;i++){
            let getIndex = this.listGacha.findIndex(ele=>ele.code===this.state.userListReward[i].code)
            if(this.state.userListReward[i].sum>=this.listGacha[getIndex].full){
                newArr.push({
                    code:this.state.userListReward[i].code,
                    sum:this.state.userListReward[i].sum%this.listGacha[getIndex].full
                })
            }else{
                newArr.push({
                    code:this.state.userListReward[i].code,
                    sum:this.state.userListReward[i].sum
                })
            }
        }
      //  console.log(oldArr)
      //  console.log(newArr)
      //  console.log(this.state)

        let result = await axios.post(`https://sv.aitded.com/checkreward`,{username: this.state.form.userGetReward,status:'confirm',old_item:oldArr,new_item:newArr,mod:this.state.username})
        if(result.data.err_msg===undefined){
            alert('แลกรางวัลสำเร็จ อย่าลืมแจ้งลูกค้านะครับ')
            this.setState({userListReward:[],confirmCheckReward:false})
        }else{
            alert('ทำรายการแลกล้มเหลว')
        }
        
    }

    submitFormGetLevelReward = (event) => {
        event.preventDefault();
        //axios.post(`http://localhost/checkreward`,{username: event.target[0].value,status:'levelcheck'}).then(res =>{
        axios.post(`https://sv.aitded.com/checkreward`,{username: event.target[0].value,status:'levelcheck'}).then(res =>{
            if(res.data.err_msg===undefined){
              //  console.log(res.data)
                if(res.data.expCanReciveLevelReward>=1000){
                    alert('พบข้อมูลลูกค้าในระบบ และมีรางวัลที่แลกได้')
                    this.setState({
                        exp:res.data.exp,
                        expPayCompleted:res.data.expPayCompleted,
                        expCanReciveLevelReward:res.data.expCanReciveLevelReward,
                        confirmCheckLevelReward:true
                    })
                }else{
                    console.log(res.data)
                    alert('พบข้อมูล แต่ไม่มีรางวัลที่แลกได้')
                    this.setState({
                        exp:res.data.exp,
                        expPayCompleted:res.data.expPayCompleted,
                        expCanReciveLevelReward:res.data.expCanReciveLevelReward,
                        confirmCheckLevelReward:true
                    })
                }
            }else{
                alert('ไม่พบข้อมูลลูกค้า')
            }
        }).catch(err=>{
            console.log(err)
            alert('เช็คข้อมูลล้มเหลว โปรดแจ้ง Admin')
        })
     }

     cfLevelReward = async () =>{

        let exp_use_reward = Math.floor(this.state.expCanReciveLevelReward/1000)*1000

        //let result = await axios.post(`http://localhost/checkreward`,{username: this.state.form.userGetLevelReward,status:'levelconfirm',exp_use_reward:exp_use_reward,mod:this.state.username})
        let result = await axios.post(`https://sv.aitded.com/checkreward`,{username: this.state.form.userGetLevelReward,status:'levelconfirm',exp_use_reward:exp_use_reward,mod:this.state.username})
        if(result.data.err_msg===undefined){
            alert('แลกรางวัล Level สำเร็จ อย่าลืมแจ้งลูกค้านะครับ')
            this.setState({
                exp:0,
                expPayCompleted:0,
                expCanReciveLevelReward:0,
                confirmCheckLevelReward:false
            })
        }else{
            alert('ทำรายการแลกล้มเหลว')
        }
        
    }

     submitForm = (event) => {

        event.preventDefault();
        //axios.post(`http://localhost/registersent`,{username: event.target[0].value,password: event.target[1].value,admin:this.state.username,status:'add'}).then(res =>{
        axios.post(`https://sv.aitded.com/registersent`,{username: event.target[0].value,password: event.target[1].value,admin:this.state.username,status:'add'}).then(res =>{
            if(res.data.error===undefined){
                alert('สมัครสมาชิกสำเร็จ')
                //console.log('register member success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward:''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:undefined,
                        listDeposit:[],
                        sumGacha:0,
                        sumDeposit:0,
                        listDepositCalGacha:[]
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }else{
                alert('สมัครสมาชิกไม่สำเร็จ โปรดลองอีกครั้ง')
                //console.log('register member no success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward:''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:undefined,
                        listDeposit:[],
                        sumGacha:0,
                        sumDeposit:0,
                        listDepositCalGacha:[]
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }
        }).catch(e =>{
            //console.log(e)
        })
     }

     submitFormChangePass = (event) => {
        
        event.preventDefault();
        if(event.target[1].value!==event.target[2].value){
            alert('รหัสผ่านทั้ง 2 ช่องไม่ตรงกัน')
            //console.log('password 2 input no match')
            this.setState({
                form : {
                    user : '',
                    pwd : '',
                    userChangePass : '',
                    pwdChangePass : '',
                    pwdChangePassConfirm : '',
                    userAddDay : '',
                    usergacha : '',
                    numgacha : '',
                    usergachacheck : '',
                    usergachaauto : '',
                    radioBtnUsergachaauto : '',
                    userlevelrewardcheck : '',
                    userGetLevelReward:''
                },
                contentCheckGacha : '',
                contentCheckLevelRewardRecived : '',
                objGachaAuto:{
                    msg:undefined,
                    error:undefined,
                    listDeposit:[],
                    sumGacha:0,
                    sumDeposit:0,
                    listDepositCalGacha:[]
                },
                expCanReciveLevelReward:0,
                exp:0,
                expPayCompleted:0
            })
        }else{
            //axios.post(`http://localhost/registersent`,{username: event.target[0].value,password: event.target[1].value,admin:this.state.username,status:'update'}).then(res =>{
                axios.post(`https://sv.aitded.com/registersent`,{username: event.target[0].value,password: event.target[1].value,admin:this.state.username,status:'update'}).then(res =>{
            if(res.data.error===undefined){
                alert('เปลี่ยนรหัสสมาชิกสำเร็จ')
                //console.log('change pass member success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward:''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:undefined,
                        listDeposit:[],
                        sumGacha:0,
                        sumDeposit:0,
                        listDepositCalGacha:[]
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }else{
                alert('เปลี่ยนรหัสสมาชิกไม่สำเร็จ โปรดลองอีกครั้ง')
                //console.log('change pass member no success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward:''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:undefined,
                        listDeposit:[],
                        sumGacha:0,
                        sumDeposit:0,
                        listDepositCalGacha:[]
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }
            }).catch(e =>{
                //console.log(e)
            })
        }
     }

     submitFormAddDay = (event) => {

        event.preventDefault();
        //axios.post(`http://localhost/registersent`,{username: event.target[0].value,admin:this.state.username,addday:'yes'}).then(res =>{
            axios.post(`https://sv.aitded.com/registersent`,{username: event.target[0].value,admin:this.state.username,addday:'yes'}).then(res =>{
            if(res.data.error===undefined){
                alert('เพิ่มวันสมาชิกสำเร็จ')
                //console.log('add day success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward:''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:undefined,
                        listDeposit:[],
                        sumGacha:0,
                        sumDeposit:0,
                        listDepositCalGacha:[]
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }else{
                alert('เพิ่มวันสมาชิกไม่สำเร็จ โปรดลองอีกครั้ง')
                //console.log('add day no success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward:''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:undefined,
                        listDeposit:[],
                        sumGacha:0,
                        sumDeposit:0,
                        listDepositCalGacha:[]
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }
        }).catch(e =>{
            //console.log(e)
        })
     }

     submitFormCheckGacha = (event) =>{
        event.preventDefault();
        //axios.post(`http://localhost/registersent`,{username: event.target[0].value,checklastreward:'yes'}).then(res =>{
            axios.post(`https://sv.aitded.com/registersent`,{username: event.target[0].value,checklastreward:'yes'}).then(res =>{
                if(res.data.error===undefined){
                    alert(res.data.msg )
                    //console.log('register member success')
                    this.setState({
                        form : {
                            user : '',
                            pwd : '',
                            userChangePass : '',
                            pwdChangePass : '',
                            pwdChangePassConfirm : '',
                            userAddDay : '',
                            usergacha : '',
                            numgacha : '',
                            usergachacheck : '',
                            usergachaauto : '',
                            radioBtnUsergachaauto : '',
                            userlevelrewardcheck : '',
                            userGetLevelReward:''
                        },
                        contentCheckGacha : res.data.msg,
                        contentCheckLevelRewardRecived : '',
                        objGachaAuto:{
                            msg:undefined,
                            error:undefined,
                            listDeposit:[],
                            sumGacha:0,
                            sumDeposit:0,
                            listDepositCalGacha:[]
                        },
                        expCanReciveLevelReward:0,
                        exp:0,
                        expPayCompleted:0
                    })
                }else{
                    alert('พบ error นอกเหนือระบบตั้งค่า')
                    //console.log('register member no success')
                    this.setState({
                        form : {
                            user : '',
                            pwd : '',
                            userChangePass : '',
                            pwdChangePass : '',
                            pwdChangePassConfirm : '',
                            userAddDay : '',
                            usergacha : '',
                            numgacha : '',
                            usergachacheck : '',
                            usergachaauto : '',
                            radioBtnUsergachaauto : '',
                            userlevelrewardcheck : '',
                            userGetLevelReward:''
                        },
                        contentCheckGacha : '',
                        contentCheckLevelRewardRecived : '',
                        objGachaAuto:{
                            msg:undefined,
                            error:undefined,
                            listDeposit:[],
                            sumGacha:0,
                            sumDeposit:0,
                            listDepositCalGacha:[]
                        },
                        expCanReciveLevelReward:0,
                        exp:0,
                        expPayCompleted:0
                    })
                }
            }).catch(e =>{
                //console.log(e)
            })
     }

     submitFormGacha = (event) => {

        event.preventDefault();
        var numbers = /^[0-9]+$/;

        let checkMinus = event.target[1].value.indexOf("-")>-1?true:false // if -1 = จำนวนติดลบ
        console.log(checkMinus)
        let checkNum = "";
        if(checkMinus){
            checkNum = event.target[1].value.substring(1)
        }else{
            checkNum = event.target[1].value;
            console.log(checkNum)
        }

        if(!checkNum.match(numbers)){
            alert("โปรกรอกข้อมูล กาชา เป็นตัวเลข")
        }else{
            //axios.post(`http://localhost/registersent`,{username: event.target[0].value,password: event.target[1].value,admin:this.state.username,status:'add'}).then(res =>{
            axios.post(`https://sv.aitded.com/registersent`,{username: event.target[0].value,gachanum: event.target[1].value,admin:this.state.username}).then(res =>{
                if(res.data.error===undefined){
                    checkMinus?alert('ลดไข่กาชาสำเร็จ'):alert('เพิ่มไข่กาชาสำเร็จ')
                    //console.log('register member success')
                    this.setState({
                        form : {
                            user : '',
                            pwd : '',
                            userChangePass : '',
                            pwdChangePass : '',
                            pwdChangePassConfirm : '',
                            userAddDay : '',
                            usergacha : '',
                            numgacha : '',
                            usergachacheck : '',
                            usergachaauto : '',
                            radioBtnUsergachaauto : '',
                            userlevelrewardcheck : '',
                            userGetLevelReward:''
                        },
                        contentCheckGacha : '',
                        contentCheckLevelRewardRecived : '',
                        objGachaAuto:{
                            msg:undefined,
                            error:undefined,
                            listDeposit:[],
                            sumGacha:0,
                            sumDeposit:0,
                            listDepositCalGacha:[]
                        },
                        expCanReciveLevelReward:0,
                        exp:0,
                        expPayCompleted:0
                    })
                }else{
                    alert('เพิ่มไข่กาชาไม่สำเร็จ โปรดลองอีกครั้ง')
                    //console.log('register member no success')
                    this.setState({
                        form : {
                            user : '',
                            pwd : '',
                            userChangePass : '',
                            pwdChangePass : '',
                            pwdChangePassConfirm : '',
                            userAddDay : '',
                            usergacha : '',
                            numgacha : '',
                            usergachacheck : '',
                            usergachaauto : '',
                            radioBtnUsergachaauto : '',
                            userlevelrewardcheck : '',
                            userGetLevelReward:''
                        },
                        contentCheckGacha : '',
                        contentCheckLevelRewardRecived : '',
                        objGachaAuto:{
                            msg:undefined,
                            error:undefined,
                            listDeposit:[],
                            sumGacha:0,
                            sumDeposit:0,
                            listDepositCalGacha:[]
                        },
                        expCanReciveLevelReward:0,
                        exp:0,
                        expPayCompleted:0
                    })
                }
            }).catch(e =>{
                //console.log(e)
            })
        }

        
     }

     onHandlesubmitForm = (e) =>{
         //console.log(e.target)
         this.setState({
             form:{
                 user : e.target.name==='user'?e.target.value:this.state.form.user,
                 pwd : e.target.name==='pwd'?e.target.value:this.state.form.pwd,
                 userChangePass : e.target.name==='userChangePass'?e.target.value:this.state.form.userChangePass,
                 pwdChangePass : e.target.name==='pwdChangePass'?e.target.value:this.state.form.pwdChangePass,
                 pwdChangePassConfirm :  e.target.name==='pwdChangePassConfirm'?e.target.value:this.state.form.pwdChangePassConfirm,
                 userAddDay : e.target.name==='userAddDay'?e.target.value:this.state.form.userAddDay,
                 usergacha :  e.target.name==='usergacha'?e.target.value:this.state.form.usergacha,
                 numgacha : e.target.name==='numgacha'?e.target.value:this.state.form.numgacha,
                 usergachacheck : e.target.name==='usergachacheck'?e.target.value:this.state.form.usergachacheck,
                 usergachaauto : e.target.name==='usergachaauto'?e.target.value:this.state.form.usergachaauto,
                 radioBtnUsergachaauto : e.target.name==='web'?e.target.value:this.state.form.radioBtnUsergachaauto,
                 userGetReward : e.target.name==='usergetreward'?e.target.value:this.state.form.userGetReward,
                 userlevelrewardcheck : e.target.name==='userlevelrewardcheck'?e.target.value:this.state.form.userlevelrewardcheck,
                 userGetLevelReward: e.target.name==='usergetlevelreward'?e.target.value:this.state.form.userGetLevelReward,
             }
         })
     }



     submitFormGachaAuto = (event) =>{
        event.preventDefault();

        
        //axios.post(`http://localhost/addgachaauto`,{username: this.state.form.usergachaauto,admin:this.state.username,web:this.state.form.radioBtnUsergachaauto}).then(res =>{
        
        axios.post(`https://sv.aitded.com/addgachaauto`,{username: event.target[0].value,admin:this.state.username}).then(res =>{
            //console.log(res.data)
            if(res.data.error===undefined){
                alert('เพิ่มไข่กาชา Auto สำเร็จ')
                //console.log('register member success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward : ''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:res.data.msg,
                        error:undefined,
                        listDeposit:res.data.listDeposit,
                        sumGacha:res.data.sumGacha,
                        sumDeposit:res.data.sumDeposit,
                        listDepositCalGacha:res.data.listDepositCalGacha
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }else{
                alert('เพิ่มไข่กาชา Auto ไม่สำเร็จ โปรดลองอีกครั้ง')
                //console.log('register member no success')
                this.setState({
                    form : {
                        user : '',
                        pwd : '',
                        userChangePass : '',
                        pwdChangePass : '',
                        pwdChangePassConfirm : '',
                        userAddDay : '',
                        usergacha : '',
                        numgacha : '',
                        usergachacheck : '',
                        usergachaauto : '',
                        radioBtnUsergachaauto : '',
                        userlevelrewardcheck : '',
                        userGetLevelReward : ''
                    },
                    contentCheckGacha : '',
                    contentCheckLevelRewardRecived : '',
                    objGachaAuto:{
                        msg:undefined,
                        error:res.data.error,
                        listDeposit:res.data.listDeposit,
                        sumGacha:res.data.sumGacha,
                        sumDeposit:res.data.sumDeposit,
                        listDepositCalGacha:res.data.listDepositCalGacha
                    },
                    expCanReciveLevelReward:0,
                    exp:0,
                    expPayCompleted:0
                })
            }
        }).catch(e =>{
            //console.log(e)
        })
        
     }

     submitFormCheckLevelRewardRecived = (event) =>{
        event.preventDefault();
        //axios.post(`http://localhost/registersent`,{username: event.target[0].value,checklastlevelreward:'yes'}).then(res =>{
        axios.post(`https://sv.aitded.com/registersent`,{username: event.target[0].value,checklastlevelreward:'yes'}).then(res =>{
                if(res.data.error===undefined){
                    alert(res.data.msg )
                    //console.log('register member success')
                    this.setState({
                        form : {
                            user : '',
                            pwd : '',
                            userChangePass : '',
                            pwdChangePass : '',
                            pwdChangePassConfirm : '',
                            userAddDay : '',
                            usergacha : '',
                            numgacha : '',
                            usergachacheck : '',
                            usergachaauto : '',
                            radioBtnUsergachaauto : '',
                            userlevelrewardcheck : '',
                            userGetLevelReward : ''
                        },
                        contentCheckGacha : '',
                        contentCheckLevelRewardRecived : res.data.msg,
                        objGachaAuto:{
                            msg:undefined,
                            error:undefined,
                            listDeposit:[],
                            sumGacha:0,
                            sumDeposit:0,
                            listDepositCalGacha:[]
                        },
                        expCanReciveLevelReward:0,
                        exp:0,
                        expPayCompleted:0
                    })
                }else{
                    alert('พบ error นอกเหนือระบบตั้งค่า')
                    //console.log('register member no success')
                    this.setState({
                        form : {
                            user : '',
                            pwd : '',
                            userChangePass : '',
                            pwdChangePass : '',
                            pwdChangePassConfirm : '',
                            userAddDay : '',
                            usergacha : '',
                            numgacha : '',
                            usergachacheck : '',
                            usergachaauto : '',
                            radioBtnUsergachaauto : '',
                            userlevelrewardcheck : '',
                            userGetLevelReward : ''
                        },
                        contentCheckGacha : '',
                        contentCheckLevelRewardRecived : '',
                        objGachaAuto:{
                            msg:undefined,
                            error:undefined,
                            listDeposit:[],
                            sumGacha:0,
                            sumDeposit:0,
                            listDepositCalGacha:[]
                        },
                        expCanReciveLevelReward:0,
                        exp:0,
                        expPayCompleted:0
                    })
                }
            }).catch(e =>{
                //console.log(e)
            })
     }


     render() {
        let priceAllReward = 0;

        let listRewardUser = this.listGacha.map((item,index)=>{
            let checkHaveOrNot = this.state.userListReward.findIndex(ele=>ele.code===item.code)
            if(checkHaveOrNot!==-1&&(this.state.userListReward[checkHaveOrNot].sum>=item.full)){
                priceAllReward += item.price * Math.floor(this.state.userListReward[checkHaveOrNot].sum/item.full)
                return(<div key={index}>{item.name} = {this.state.userListReward[checkHaveOrNot].sum}/{item.full} รวมแลกได้ {Math.floor(this.state.userListReward[checkHaveOrNot].sum/item.full)} รางวัล</div>)
            }
        })

        for(let i=0;i<listRewardUser.length;i++){
            if(listRewardUser[i]===undefined){listRewardUser.splice([i], 1); i--}
        }
         return (
            this.state.username!==undefined&&this.state.level==='admin'?
            <div>
                <div className="TopbarMember">
                    <div className="avatarUser"><img src="/images/avatar-main.webp" alt="avatar" /></div>
                    <div className="infoUser">ยินดีต้อนรับ Admin : <span className="text-gradient"><strong>{this.state.username}</strong></span><br/>
                    <button className="btnMini btn-glow btn-primary" onClick={this.logoutActice}>ออกจากระบบ</button></div>
                </div>
            <div className="memberPage">
                <div className="mainMemberPage">
                    {/* */}
                <div className="adminPage">

                    <div className="regisUser">
                        <h1>Register User</h1>
                        <div>
                            <form onSubmit={this.submitForm} >
                            <label htmlFor="username">Username : </label><br />
                            <input type="text" id="user" name="user" value={this.state.form.user} placeholder="username" onChange={this.onHandlesubmitForm} required /><br /><br />
                            <label htmlFor="password">Password : </label><br />
                            <input type="password" id="pwd" name="pwd" value={this.state.form.pwd} placeholder="password" onChange={this.onHandlesubmitForm} required /><br /><br />
                            <input type="submit" value="สมัครสมาชิก" className="btnMini btn-red" />
                            </form>
                        </div>
                    </div>

                    <div className="changepassUser">
                        <h1>Change Pass User</h1>
                        <form onSubmit={this.submitFormChangePass} >
                        <label htmlFor="username">Username : </label><br />
                        <input type="text" id="user" name="userChangePass" value={this.state.form.userChangePass} placeholder="username" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <label htmlFor="password">New Password : </label><br />
                        <input type="password" id="pwd" name="pwdChangePass" value={this.state.form.pwdChangePass} placeholder="password" required onChange={this.onHandlesubmitForm} /><br /><br />
                        <label htmlFor="password">Confirm New Password : </label><br />
                        <input type="password" id="pwd" name="pwdChangePassConfirm" value={this.state.form.pwdChangePassConfirm} placeholder="password" required onChange={this.onHandlesubmitForm} /><br /><br />
                        <input type="submit" value="เปลี่ยนรหัส" className="btnMini btn-yellow" />
                        </form>
                    </div>

                    <div className="adddayUser">
                        <h1>Add Day User</h1>
                        <form onSubmit={this.submitFormAddDay} >
                        <label htmlFor="username">Username : </label><br />
                        <input type="text" id="user" name="userAddDay" value={this.state.form.userAddDay} placeholder="username" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <input type="submit" value="เพิ่มอายุเป็น 7 วัน" className="btnMini btn-green" />
                        </form>
                    </div>

                    <div className="checkGacha">
                        <h1>Check Last Add Gashapon</h1>
                        <p>{this.state.contentCheckGacha!==''?this.state.contentCheckGacha:''}</p>
                        <form onSubmit={this.submitFormCheckGacha} >
                        <label htmlFor="username">Username : </label><br />
                        <input type="text" id="user" name="usergachacheck" value={this.state.form.usergachacheck} placeholder="username" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <input type="submit" value="เช็ควันเพิ่มกาชาล่าสุด" className="btnMini btn-sky" />
                        {/*<input type="reset" value="รีเซ็ต" onClick={this.clearContentGacha} className="btnMini btn-sky" />*/}
                        </form>
                    </div>

                    <div className="addGacha">
                        <h1>Add Gashapon</h1>
                        <p style={{color:'rgb(203, 120, 255)'}}>*** ถ้าต้องการลดไข่สุ่ม : ให้ใส่เครื่องหมาย - ด้านหน้าก่อนตัวเลขครับ ***</p>
                        <form onSubmit={this.submitFormGacha} >
                        <label htmlFor="username">Username : </label><br />
                        <input type="text" id="user" name="usergacha" value={this.state.form.usergacha} placeholder="username" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <label htmlFor="username">จำนวนไข่กาชา : </label><br />
                        <input type="text" id="user" name="numgacha" value={this.state.form.numgacha} placeholder="จำนวนไข่กาชา" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <input type="submit" value="เพิ่มไข่กาชา" className="btnMini btn-puple" />
                        </form>
                    </div>

                    <div className="getReward">
                        <h1>Get Reward</h1>
                            <p style={{color:'#ff4c4c'}}>*** สำคัญ : ก่อนกดแลกรางวัลให้ Copy รายการรางวัลทั้งหมดส่งให้ลูกค้านะครับ ***</p>
                        <div>
                            <form onSubmit={this.submitFormGetReward} >
                            <label >Username : </label><br />
                            <input type="text" name="usergetreward" value={this.state.form.userGetReward} placeholder="username" onChange={this.onHandlesubmitForm} required /><br /><br />
                            <input type="submit" value="Check Rewards" className="btnMini btn-wood" />
                            </form>
                        </div>
                        
                        {listRewardUser.length>0?
                        <div>
                            <h3>รายการรางวัลที่ลูกค้าแลกได้มีดังนี้</h3>
                            {listRewardUser}
                            <br/>
                            รวมเป็นเครดิตฟรีทั้งหมด = {priceAllReward} บาท จะเข้าในระบบสมาชิกลูกค้าภายใน 1 ชม. นะครับ
                            <br/>
                            ขอบคุณที่ไว้วางใจเว็บพันธมิตรของเราเสมอมานะครับลูกค้า
                            <br/>
                            <button className="btnMini btn-green" onClick={this.cfReward}>แลกรางวัลให้ลูกค้า</button>
                        </div>:this.state.confirmCheckReward?<h3>ไม่มีรางวัลที่แลกได้</h3>:''}
                    </div>

                    <div className="checkLevelRewardRecived">
                        <h1>Check History Level Reward</h1>
                        <p>{this.state.contentCheckLevelRewardRecived!==''?this.state.contentCheckLevelRewardRecived:''}</p>
                        <form onSubmit={this.submitFormCheckLevelRewardRecived} >
                        <label htmlFor="username">Username : </label><br />
                        <input type="text" id="user" name="userlevelrewardcheck" value={this.state.form.userlevelrewardcheck} placeholder="username" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <input type="submit" value="เช็ค Level รับรางวัลไปแล้วล่าสุด" className="btnMini btn-skyLight" />
                        {/*<input type="reset" value="รีเซ็ต" onClick={this.clearContentGacha} className="btnMini btn-sky" />*/}
                        </form>
                    </div>

                    
                    <div className="cfLevelReward">
                        <h1>Get "Level" Reward</h1>
                            <p style={{color:'#ffd270'}}>*** สำคัญ : ก่อนกดแลกรางวัลให้ Copy รายการรางวัลทั้งหมดส่งให้ลูกค้านะครับ ***</p>
                            {/*<p style={{color:'#ff7676'}}>*** เพิ่มเติม : กรณีลูกค้าต้องการแลกรางวัลพิเศษ ( ทุกๆ 25 Level หรือก็คือ ทุกๆ exp รวม 25,000 ) แจ้งผู้ดูแลระบบเพื่อทำการแลกของรางวัลเท่านั้น จะแยกส่วนจากทุกๆ 10 Level ที่แอดมินสามารถจัดการเองได้ครับ ***</p>*/}
                        <div>
                            <form onSubmit={this.submitFormGetLevelReward} >
                            <label >Username : </label><br />
                            <input type="text" name="usergetlevelreward" value={this.state.form.userGetLevelReward} placeholder="username" onChange={this.onHandlesubmitForm} required /><br /><br />
                            <input type="submit" value="Check Rewards" className="btnMini btn-dark" />
                            </form>
                        </div>
                        
                        {this.state.expCanReciveLevelReward>=1000?
                        <div>
                            <p>EXP ลูกค้ารวม : {this.state.exp}</p>
                            <p>EXP ลูกค้าแลกไปแล้ว : {this.state.expPayCompleted}</p>
                            <p>EXP สะสมรวมรอแลก : {this.state.expCanReciveLevelReward}</p>
                            <h3 className='green-text'>แลกรางวัลได้ทั้งหมด รวม = {Math.floor(this.state.expCanReciveLevelReward/1000)*10} Level ( {Math.floor(this.state.expCanReciveLevelReward/1000)*1000} exp )</h3>
                            <br />
                            ทุกๆ 10 Level แลกเครดิตฟรีได้ 100 บาท
                            <br /><br />
                            <span className='green-text'>รวมเป็นเครดิตฟรีทั้งหมด = {Math.floor(this.state.expCanReciveLevelReward/1000)*100} บาท</span>
                            <br /><br />จะเข้าในระบบสมาชิกลูกค้าภายใน 1 ชม. นะครับ
                            <br/>
                            ขอบคุณที่ไว้วางใจเว็บพันธมิตรของเราเสมอมานะครับลูกค้า
                            <br/>
                            <br/>
                            <button className="btnMini btn-dark" onClick={this.cfLevelReward}>แลกรางวัลให้ลูกค้า</button>
                        </div>:this.state.confirmCheckLevelReward?
                        <div>
                            <h3 className='yelllow-text'>ไม่มีรางวัลที่ Level แลกได้</h3>
                            <p className='yelllow-text'>EXP ลูกค้ารวม : {this.state.exp}</p>
                            <p className='yelllow-text'>EXP ลูกค้าแลกไปแล้ว : {this.state.expPayCompleted}</p>
                            <p className='yelllow-text'>EXP สะสมรวมรอแลก : {this.state.expCanReciveLevelReward}</p>
                            <p className='yelllow-text'>ทุก 10 level ( 1,000 exp ) แลกเครดิตฟรีได้ 100 บาท</p>
                            <p className='yelllow-text'>หากลูกค้ามี Level สมาชิกตามเงื่อนไขแล้ว แจ้งทีมงานตรวจสอบอีกทีได้เลยนะครับ</p>
                        </div>:''}
                    </div>

                    {/*
                    <div className="addGachaAuto">
                        <h1>Add Gashapon Auto</h1>
                        {this.state.objGachaAuto.error==='no add db'?
                        <div>
                            <span>ฝากรวม = {this.state.objGachaAuto.sumDeposit}</span><br/><br/>
                            <span>รับไข่สุ่ม = {this.state.objGachaAuto.sumGacha}</span><br/><br/>
                            <span style={{color:'orange'}}>ไม่มียอดฝากใหม่ </span>
                            {this.state.objGachaAuto.listDeposit.map((item,index)=>{
                                return (<div key={index}>
                                    {item.amount} วันที่ {new Date(parseInt(item.depositThaitimestamp)*1000).toLocaleString()}
                                </div>)
                            })}
                        </div>
                        :''}
                        {this.state.objGachaAuto.msg!==undefined?
                        <div>
                            {this.state.objGachaAuto.listDepositCalGacha.map((item,index)=>{
                                return (<div key={index}>
                                    {item.amount} วันที่ {new Date(parseInt(item.depositThaitimestamp)*1000).toLocaleString()}
                                </div>)
                            })}

                            <span>รายการฝากลูกค้า 7 วันล่าสุด ที่สามารถคำนวน "ไข่สุ่ม" รอบปัจจุบันได้มีตามนี้นะครับ...</span><br/><br/>
                            <span>รวมฝาก {this.state.objGachaAuto.sumDeposit} บาท</span><br/><br/>
                            <span>ทุกๆ 100 บาทยอดฝาก ได้รับไข่สุ่ม 1 ฟอง</span><br/><br/>
                            <span>รวมลูกค้าได้รับไข่สุ่ม = {this.state.objGachaAuto.sumGacha} ฟอง ทีมงานทำการเพิ่มให้เรียบร้อยคับผม ขอให้โชคดีกับการลุ้นไข่สุ่มนะครับลูกค้า</span><br/><br/>

                            <span>รายการฝากทั้งหมด 7 วันล่าสุด</span><br/><br/>
                            {this.state.objGachaAuto.listDeposit.map((item,index)=>{
                                return (<div key={index}>
                                    {item.amount} วันที่ {new Date(parseInt(item.depositThaitimestamp)*1000).toLocaleString()}
                                </div>)
                            })}
                        </div>
                        :''}
                        <form onSubmit={this.submitFormGachaAuto} >
                        <label htmlFor="username">Username : </label><br />
                        <input type="text" id="user" name="usergachaauto" value={this.state.form.usergachaauto} placeholder="username" required onChange={this.onHandlesubmitForm}  /><br /><br />
                        <div onChange={this.onHandlesubmitForm}>
                        <input type="radio" name="web" value="clubfifa55" checked={this.state.form.radioBtnUsergachaauto==='clubfifa55'}/> <label for="clubfifa55">clubfifa55</label><br/><br/>
                        <input type="radio" name="web" value="luck77" checked={this.state.form.radioBtnUsergachaauto==='luck77'}/> <label for="luck77">luck77</label><br/><br/>
                        </div>
                        <input type="submit" value="เพิ่มไข่กาชา Auto" className="btnMini btn-brown" />
                        </form>
                    </div>
                    // ไม่ได้ใช้ตอนนี้เพราะฝากถอนมีตกหล่นบางเคส

                    */}
                    </div>
                    
                    
                </div>

                
            </div>
            </div>:
            <Navigate to ="/homeadmin_rockstar" />
            
         )
     }
}

export default Adminrockstar;