import React from 'react'
import axios from 'axios'
import  { Navigate,Link } from 'react-router-dom'
import store from '../store/configstore'
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import Aimain from '../components/Aimain'
import Toprankslot from '../components/Toprankslot'
import Casino from '../components/Casino'
import Reward from '../components/Reward'
import Gacha from '../components/gacha/Gacha'
import Ball from '../components/Ball'
import Lotto from '../components/Lotto'

class Member extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            username : store.getState().username?store.getState().username:undefined,
            expire : store.getState().expire? store.getState().expire:undefined,
            level : store.getState().level?store.getState().level:undefined,
            gacha : store.getState().gacha?store.getState().gacha:0,
            listGachaItem : store.getState().listGachaItem?store.getState().listGachaItem:[],
            list : [],
            connMobile : window.innerWidth>800?false:true,
            selectPage : undefined,
            showFooter : true,
            exp : store.getState().exp?store.getState().exp:0
        }
     }
     
     logoutActice = () =>{
        store.dispatch({ type: 'logout'});
        this.setState({username : undefined, expire : undefined})
     }

     checkUpdate = (gacha = store.getState().gacha,exp = store.getState().exp) =>{
         this.setState({gacha:gacha,exp:exp})
     }
     
     componentDidMount(){
        this.reloadLastDataUser()
        window.addEventListener("resize", this.handleWindowsize);
        
     }

     reloadLastDataUser = () =>{
         // สำหรับดึงไข่ล่าสุด กรณี รีเฟสหน้า
        //axios.post(`http://localhost/member`,{username: store.getState().username,password: store.getState().pwd}).then(res =>{
            axios.post(`https://sv.aitded.com/member`,{username: store.getState().username,password: store.getState().pwd}).then(res =>{
            if(res.data.error===undefined){
                store.dispatch({ 
                    type: 'gacha',
                    data: {
                        username : store.getState().username,
                        pwd : store.getState().pwd,
                        expire : store.getState().expire,
                        level : 'member',
                        gacha : res.data.gacha,
                        listGachaItem : res.data.listGachaItem,
                        exp : res.data.exp!==undefined?res.data.exp:0
                    }
                });
                this.setState({gacha:res.data.gacha,expire : store.getState().expire,listGachaItem : res.data.listGachaItem})
            }
        })
     }



     componentWillUnmount(){
        window.removeEventListener("resize", this.handleWindowsize);
    }
    selectPage = (page) =>{
        if(page==='contact'){
            window.open(
                "https://lin.ee/NIDxu4g", "_blank");
        }else{

            window.scrollTo(0, 0);
            if(page!==this.state.selectPage){this.setState({selectPage : page})}
        }
    }

     handleWindowsize = () =>{
        window.innerWidth>800?this.state.connMobile===false?console.log('handle size PC'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile'):this.setState({connMobile : true});
    }
    switchmenuFooter = () =>{
        this.setState({showFooter:!this.state.showFooter})
    }

    checkLevelMember = () =>{

    }

     render() {


        //console.log(this.state)
        //console.log(store.getState())
         return (
            this.state.username!==undefined&&this.state.level==='member'?
            <div>
                <div className="TopbarMember">
                    <div className="avatarUser"><img src="/images/avatar-main.webp" alt="avatar" /></div>
                    <div className="infoUser">ยินดีต้อนรับ คุณ : <span className="text-gradient"><strong>{this.state.username}</strong></span><br/>ไข่กาชาคงเหลือ : {this.state.gacha} ฟอง<LazyLoadImage style={{verticalAlign:'sub',maxWidth:'20px'}} alt={`กาชาปอง`} src={`/images/reward/egg_cartoon.png`} /><br/>
                    ใช้งานได้ถึง : <span style={{color:'#ffc800'}}>{`${new Date (new Date(this.state.expire).getTime() + (3600000*7)).toISOString().replace('T'," เวลา ").slice(0,21)}น.`}</span><br/>
                    Level : <span style={{color:'#ffc800'}}>{Math.floor(this.state.exp/100)}</span> Exp : <span style={{color:'#ffc800'}}>{this.state.exp%100}/100</span><br/>
                    <button className="btnMini btn-glow btn-primary" onClick={this.logoutActice}>ออกจากระบบ</button> <a href="https://lin.ee/NIDxu4g" rel="nofollow noopener" target="_blank"><button className="btnMini btn-green btn-glow">ต่ออายุสูตร</button></a>
                    <button className="btnMini btn-gradient-border btn-glow" onClick={this.reloadLastDataUser} >Reload ไข่สุ่ม</button><button className="btnMini btn-gradient-border btn-casino btn-glow" onClick={this.switchmenuFooter} >{this.state.showFooter?'ซ่อนเมนู':'แสดงเมนู'}</button></div>
                </div>
                <div className="memberPage">
                    {this.state.selectPage==='ball'?
                    /*<div>
                        <div className="mainMemberPage">
                            <div className="boxls">
                                <h2 className="text-center">โปรแกรมแข่ง วันที่ {`${new Date(Date.now() + (3600000*2) ).toISOString().slice(0,10)}`}</h2>
                                
                                <div className='rowDate'>
                                    <div className='colDate' onClick={(e)=>this.callApi(new Date(Date.now() + (3600000*2) - (3600000*24)).toISOString().slice(0,10))}
                                    style={this.state.HLdate===new Date(Date.now() + (3600000*2) - (3600000*24)).toISOString().slice(0,10)?{backgroundColor:'#089b5b'}:{}}
                                    >{new Date(Date.now() + (3600000*2) - (3600000*24) ).toISOString().slice(5,10)}</div>
                                    <div className='colDate' onClick={(e)=>this.callApi(new Date(Date.now() + (3600000*2)).toISOString().slice(0,10))}
                                    style={this.state.HLdate===new Date(Date.now() + (3600000*2)).toISOString().slice(0,10)?{backgroundColor:'#089b5b'}:{}}
                                    >วันนี้</div>
                                    <div className='colDate' onClick={(e)=>this.callApi(new Date(Date.now() + (3600000*2) + (3600000*24)).toISOString().slice(0,10))}
                                    style={this.state.HLdate===new Date(Date.now() + (3600000*2) + (3600000*24)).toISOString().slice(0,10)?{backgroundColor:'#089b5b'}:{}}
                                    >{new Date(Date.now() + (3600000*2) + (3600000*24)).toISOString().slice(5,10)}</div>
                                </div>

                            {listEvents}
                            </div>
                        </div>
                        <div className="note">
                            <h2>คำแนะนำการใช้สูตร</h2>
                            <p>สูตรวิเคราะห์บอล <span className="text-gradient">AITDED.COM</span> เหมาะกับการวิเคราะห์บอลลีกเป็นหลัก ซึ่งเป็นรายการแข่งที่ทั้งสองทีมอยู่ในทัวร์นาเม้นเดียวกัน สำหรับโปรแกรมบอลถ้วยต่างแดนมาเจอกัน อาทิ บอลทีมชาติ บอลยูฟ่า ยูโรปา FA CUP ฯลฯ ที่ทั้งสองทีมไม่ได้อยู่ในลีกเดียวกันมาพบกัน อาจไม่เหมาะสมกับการใช้สูตรบอลของเรา 
                                เพราะสถิติที่เทียบค่านั้นวัดเกณฑ์ได้ยากกว่าพอสมควร รวมไปถึงหากรายการแข่งไหนมีสถิติย้อนหลังน้อยเกินไป (ไม่ถึง 5 รายการแข่งย้อนหลังสำหรับสถิติของแต่ละทีม) ก็อาจไม่เหมาะสมเช่นกันเพราะสถิติยังถือว่าน้อยไป อย่างไรก็ตาม ทีมงานไม่ได้ล็อครายการที่กล่าวมาข้างต้นเอาไว้ 
                                เผื่อมีเพื่อนๆบางคนอยากทดลองใช้สูตรกับรายการดังที่กล่าวมา
                            </p>
                            <p>สรุปรายการที่เหมาะสมกับสูตรคือ รายการที่มีสถิติเจ้าบ้านและทีมเยือนมากกว่า 5 รายการแข่งย้อนหลัง และเป็นรายการแข่งที่อยู่ในลีกเดียวกัน ขอให้ทุกท่านสนุกกับการเชียร์บอลครับ...</p>
                        </div>
                    </div>*/
                    <Ball connMobile={this.state.connMobile} />:
                    this.state.selectPage==='casino'?
                    <Casino connMobile={this.state.connMobile} />:
                    this.state.selectPage==='lotto'?
                    <Lotto connMobile={this.state.connMobile} />:
                    this.state.selectPage==='reward'?
                    <Reward connMobile={this.state.connMobile} />:
                    this.state.selectPage==='gacha'?
                    <Gacha connMobile={this.state.connMobile} checkUpdate={this.checkUpdate} />:
                    <div className="mainMemberPage zoneChoise">
                        <div className='boxChoise' onClick={(e) =>this.selectPage('gacha')}>
                            <div className="selectChoise" id="gachaMenu"><div className='boxBG'><div className='hlBG'><img src="/images/icon/menu_1_gift.webp" className='iconY_anim_normal' /><br/><span className='btnMenu btn-glow btn-gradient-border btn-gacha-login'>สุ่มกาชา</span></div></div></div>
                        </div>
                        <div className='boxChoise' onClick={(e) =>this.selectPage('ball')}>
                            <div className="selectChoise" id="spotyMenu"><div className='boxBG'><div className='hlBG'><img src="/images/icon/menu_1_sudball.webp" className='iconY_anim_normal' /><br/><span className='btnMenu btn-glow btn-gradient-border btn-casino-login'>สูตรบอล</span></div></div></div>
                        </div>
                        {/*<div className='boxChoise' onClick={(e) =>this.selectPage('casino')}>
                            <div className="selectChoise" id="casinoMenu"><div className='boxBG'><div className='hlBG'><span className='btnMenu btn-glow btn-gradient-border btn-casino'>คาสิโน</span></div></div></div>
                        </div>
                        <div className='boxChoise' onClick={(e) =>this.selectPage('slot')}>
                            <div className="selectChoise" id="slotMenu"><div className='boxBG'><div className='hlBG'><span className='btnMenu btn-glow btn-gradient-border'>สูตรสล็อต</span></div></div></div>
                        </div>
                        <a href="https://www.youtube.com/channel/UCPY3lTIyrPuKPCO0zATl14g/videos" target={'_blank'} rel="nofollow noreferrer"><div className='boxChoise'>
                            <div className="selectChoise" id="slotChannel"><div className='boxBG'><div className='hlBG'><span className='btnMenu btn-glow btn-gradient-border btn-slot-channel'>เฮียหมูนำเล่น</span></div></div></div>
                        </div></a>
                        <div className='boxChoise' onClick={(e) =>this.selectPage('lotto')}>
                            <div className="selectChoise" id="lottoMenu"><div className='boxBG'><div className='hlBG'><span className='btnMenu btn-glow btn-gradient-border btn-lotto-login'>เลขเด็ด</span></div></div></div>
                        </div>*/}
                    </div>
                    }
                    <div style={{clear:'both'}}></div>
                <div className="creditfooter" style={{padding:'10px'}}>ให้บริการโดย : aitded.com</div>
                </div>
                {this.state.showFooter?
                <div className="FooterbarMember">
                        {/*<div className="nav-footer menuFooter1" onClick={(e) =>this.selectPage(undefined)} ><span className="text-footermenu">หน้าหลัก</span></div>
                        <div className="nav-footer menuFooter2" onClick={(e) =>this.selectPage('gacha')} ><span className="text-footermenu">สุ่มกาชา</span></div>
                        <div className="nav-footer menuFooter3" onClick={(e) =>this.selectPage('ball')} ><span className="text-footermenu">สูตรบอล</span></div>
                        <div className="nav-footer menuFooter4" onClick={(e) =>this.selectPage('contact')} ><span className="text-footermenu">ติดต่อเรา</span></div>*/}
                        {/*<div className="nav-footer"><span onClick={(e) =>this.selectPage('casino')} className="btn btn-gradient-border btn-glow">คาสิโน</span></div>
                        <div className="nav-footer"><span onClick={(e) =>this.selectPage('slot')} className="btn btn-gradient-border btn-glow">สูตรสล็อต</span></div>*/}
                        <div className="nav-footer menuFooter5" onClick={(e) =>this.selectPage('reward')} ><div className='iconMobileFooterMenu'><img className='iconY_anim' src='/images/icon/menu_1_rank.webp' /></div><br/><span className="text-footermenu">พิเศษ</span></div>
                        <div className="nav-footer menuFooter2" onClick={(e) =>this.selectPage('ball')} ><div className='iconMobileFooterMenu'><img className='iconY_anim'  src='/images/icon/menu_1_sudball.webp' /></div><br/><span className="text-footermenu">สูตรบอล</span></div>
                        <div className="nav-footer menuFooter1" onClick={(e) =>this.selectPage(undefined)} ><div className='iconMobileFooterMenu'><img src='/images/icon/menu_1_home.webp' className='imageRotate' /></div><br/><span className="text-footermenu">AITDED</span></div>
                        <div className="nav-footer menuFooter4" onClick={(e) =>this.selectPage('gacha')} ><div className='iconMobileFooterMenu'><img className='iconY_anim'  src='/images/icon/menu_1_gift.webp' /></div><br/><span className="text-footermenu">สุ่มกาชา</span></div>
                        <div className="nav-footer menuFooter3" onClick={(e) =>this.selectPage('contact')} ><div className='iconMobileFooterMenu'><img className='iconY_anim'  src='/images/icon/menu_1_chat.webp' /></div><br/><span className="text-footermenu">ติดต่อ</span></div>
                </div>:''}
            </div>:
            <Navigate to ="/" />
            
         )
     }
}

export default Member;
