import React from 'react'
import axios from 'axios'
import  { Navigate,Link } from 'react-router-dom'
import store from '../store/configstore'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Aimain from '../components/Aimain'

class Ball extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            expire : store.getState().expire? store.getState().expire:undefined,
            gacha : store.getState().gacha?store.getState().gacha:0,
            list : [],
            connMobile : this.props.connMobile,
            loading : true,
            tdedkickList : [],
            focusmatchid : undefined,
            caseFocusMatchId : false, // true = end, false = no start
            HLdate : new Date(Date.now() + (3600000*2)).toISOString().slice(0,10)
        }
     }
     
     componentDidMount(){
        let today = new Date(Date.now() + (3600000*2)).toISOString().slice(0,10)
        setInterval(this.callApi, 300000); // รันตารางใหม่ทุก 5 นาที

        axios.get(`https://sv.tdedkick.com/index.php?date=${today}&topleague=yes`).then(response =>{
            var mainTdedkick = response.data; // run tdedkick เพื่อเก็บ odds หน้าแรก
            
            //axios.get(`http://localhost/apils?date=${today}`).then(res => {
                axios.get(`https://sv.aitded.com/apils?date=${today}`).then(res => {
            
            let listforSet = [];
            for (const listEvent of res.data) {
                listforSet.push(listEvent)
                ////console.log(listEvent)
                }
                listforSet.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
                listforSet.sort((a, b) => a.tournament_templateFK - b.tournament_templateFK);
                this.setState({list : listforSet, loading : false, tdedkickList : mainTdedkick, HLdate :today})
            })

        })
     }

     componentWillUnmount(){
        clearInterval(this.callApi)
    }
    callApi = (selectDate = this.state.HLdate) =>{
        
        axios.get(`https://sv.tdedkick.com/index.php?date=${selectDate}&topleague=yes`).then(response =>{
            var mainTdedkick = response.data; // run tdedkick เพื่อเก็บ odds หน้าแรก
            
            //axios.get(`http://localhost/apils?date=${today}`).then(res => {
                axios.get(`https://sv.aitded.com/apils?date=${selectDate}`).then(res => {
            
            let listforSet = [];
            for (const listEvent of res.data) {
                listforSet.push(listEvent)
                ////console.log(listEvent)
                }
                listforSet.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
                listforSet.sort((a, b) => a.tournament_templateFK - b.tournament_templateFK);
                this.setState({list : listforSet, loading : false, tdedkickList : mainTdedkick, HLdate :selectDate})

            })

        })
    }

    componentDidUpdate(){
        this.props.connMobile!==this.state.connMobile?this.setState({connMobile:this.props.connMobile}):console.log()
    }

    calTded = (idmatch, checkCaseFocusMatchId = false) =>{

        //let checkExpire = this.state.expire
        let checkExpire = new Date(this.state.expire).getTime()
        let nowTime = new Date(Date.now()).getTime()
        //console.log(checkExpire)
        //console.log(nowTime)
        if(nowTime>checkExpire){
            alert('วันใช้งานของคุณหมดอายุ โปรดติดต่อเจ้าหน้าที่เพื่อต่ออายุฟรี')
        }else{
            this.state.focusmatchid===idmatch?
            this.setState({focusmatchid : undefined,caseFocusMatchId:checkCaseFocusMatchId}):
            this.setState({focusmatchid : idmatch,caseFocusMatchId:checkCaseFocusMatchId})
            document.getElementById(idmatch).scrollIntoView();
            ////console.log(idmatch);
        }
    }

     render() {
         //console.log(this.props.connMobile)
         let listEvents = []
         if(this.state.list.length>0){
            listEvents = this.state.list.map((item,index) =>{
              // //console.log(item)
                let {event_participants} = item;
                let homeInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:''
                    },
                    result:{
                        ordinarytime:'',
                        halftime:'',
                        finalresult:'',
                        runningscore:''
                    }
                }
                let awayInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:''
                    },
                    result:{
                        ordinarytime:'',
                        halftime:'',
                        finalresult:'',
                        runningscore:''
                    }
                }
                let oddsTK = {
                    hdp: "",
                    hdp_away: "",
                    hdp_home: "",
                    home_1x2: "",
                    draw_1x2: "",
                    away_1x2: "",
                    ou: "",
                    over_odds: "",
                    under_odds: ""
                }
                let found = this.state.tdedkickList.find(({id}) => id === item.id)
                if(found!==undefined){
                    oddsTK = {
                        hdp : found.hdp,
                        hdp_away : found.hdp_away,
                        hdp_home : found.hdp_home,
                        home_1x2 : found.home_1x2,
                        draw_1x2 : found.draw_1x2,
                        away_1x2 : found.away_1x2,
                        ou : found.ou,
                        over_odds : found.over_odds,
                        under_odds : found.under_odds
                    }
    
                }else{
                    oddsTK = {
                        hdp : "",
                        hdp_away : "",
                        hdp_home : "",
                        home_1x2 : "",
                        draw_1x2 : "",
                        away_1x2 : "",
                        ou : "",
                        over_odds : "",
                        under_odds : ""
                    }
                }
                for (const listEventParticipants in event_participants) {
                    let {number,participant,result} = event_participants[listEventParticipants];
                    if(number==='1'){
                        homeInfo.participant = participant;
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':homeInfo.result.runningscore=value;break;
                                case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                                case 'halftime':homeInfo.result.halftime=value;break;
                                case 'finalresult':homeInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                    }else if(number==='2'){
                        awayInfo.participant = participant;
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':awayInfo.result.runningscore=value;break;
                                case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                                case 'halftime':awayInfo.result.halftime=value;break;
                                case 'finalresult':awayInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                    }
                }
                
               return(
                <div key={item.id} id={`${item.id}`} className="fading">
                    
                        {
                        index === 0 ? <div className="tournament row my-1" id={item.tournament_templateFK}>
                            <div className="col col-10"><LazyLoadImage className="flagLs" alt={item.tournament_template_name} src={item.flag} />{item.tournament_template_name} : {item.tournament_stage_name}</div>
                            </div> : 
                        item.tournament_templateFK===this.state.list[index-1].tournament_templateFK ? '' : <div className="tournament row my-1" id={item.tournament_templateFK}><div className="col col-10"><LazyLoadImage className="flagLs" alt={item.tournament_template_name} src={item.flag} />{item.tournament_template_name} : {item.tournament_stage_name}</div></div>
                        }
                    
                    {
                    this.state.connMobile===false?
                    /* PC */
                    <div className={`listMatch fading`}>
                        <div className="col-2 text-center">{item.status_descFK==='1'?<span className="timeLs">{item.startdate.substr(11,5)}<br /><span className="oddsLs">{oddsTK.hdp}</span></span>:`${item.status_type}`}</div>
                        <div className="col-3 text-right">{homeInfo.participant.name}<LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                        <div className="col-2 text-center ftScore"><a href={`https://mvpkick.com/viewmatch/${item.id}/`} target="_blank" rel='nofollow noreferrer'><span className='ftShow'>{item.status_descFK==='6'||item.status_descFK==='1'||item.status_descFK==='5'?homeInfo.result.finalresult+' - '+awayInfo.result.finalresult:homeInfo.result.ordinarytime+' - '+awayInfo.result.ordinarytime}</span></a></div>
                        <div className="col-3"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} />{awayInfo.participant.name}</div>
                        <div className="col-2 text-center">
                            {item.status_descFK!=='1'?
                            item.status_descFK!=='6'&&item.status_descFK!=='2'&&item.status_descFK!=='13'? // กำลังเตะหรือเตะจบ รวมถึงจบแบบต่อเวลา 30 นาที
                            '':
                            <button onClick={(e) => this.calTded(item.id,true)} className="btnSeeTded btn-gradient-border btn-glow bgLinerHot">{this.state.focusmatchid!==item.id?`ดูผล`:`ซ่อนผล`}</button>: // case ปุ่ม Show Incident
                            <button onClick={(e) => this.calTded(item.id,false)} className="btnSeeTded btn-gradient-border btn-glow">{this.state.focusmatchid!==item.id?`คำนวนสูตร`:`ซ่อนสูตร`}</button>}
                         </div>
                        {/*<div className="col-2 text-center"><button onClick={(e) => this.calTded(item.id)} className="btnSeeTded btn-gradient-border btn-glow">{this.state.focusmatchid!==item.id?`คำนวนสูตร`:`ซ่อนสูตร`}</button></div>*/}
                        {this.state.focusmatchid==item.id?
                        <div className="col-12"><Aimain idmatch={this.state.focusmatchid} connMobile={this.state.connMobile} checkCase={this.state.caseFocusMatchId} /></div>
                        :''}
                    </div>:
                    /* Mobile */
                    <div className={`listMatch fading`}>
                        <div className="col-2 text-center">{item.status_descFK==='1'?<span className="timeLs">{item.startdate.substr(11,5)}<br /><span className="oddsLs">{oddsTK.hdp}</span></span>:`${item.status_type}`}</div>
                        <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} />{homeInfo.participant.name}<br /><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} />{awayInfo.participant.name}</div>
                        <div className="col-1 text-center ftMobileBox"><a href={`https://mvpkick.com/viewmatch/${item.id}/`} target="_blank" rel='nofollow noreferrer'>{item.status_descFK==='6'||item.status_descFK==='1'||item.status_descFK==='5'?homeInfo.result.finalresult:homeInfo.result.ordinarytime}<br />{item.status_descFK==='6'||item.status_descFK==='1'||item.status_descFK==='5'?awayInfo.result.finalresult:awayInfo.result.ordinarytime}</a></div>
                        <div className="col-1 text-center">{homeInfo.result.halftime}<br />{awayInfo.result.halftime}</div>
                        {/*<div className="col-2 text-center"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIconVertical border-shine" alt='h2h' src='/img/icon/h2h_icon_vertical.webp' /></Link></div>*/}
                        <div className="col-2 text-center">
                            {item.status_descFK!=='1'?
                            item.status_descFK!=='6'&&item.status_descFK!=='2'&&item.status_descFK!=='13'? // กำลังเตะหรือเตะจบ รวมถึงจบแบบต่อเวลา 30 นาที
                            '':
                            <button onClick={(e) => this.calTded(item.id,true)} className="btnSeeTded btn-gradient-border btn-glow bgLinerHot">{this.state.focusmatchid!==item.id?`ดูผล`:`ซ่อนผล`}</button>:
                            <button onClick={(e) => this.calTded(item.id,false)} className="btnSeeTded btn-gradient-border btn-glow">{this.state.focusmatchid!==item.id?`คำนวนสูตร`:`ซ่อนสูตร`}</button>}
                        </div>
                        {/*<div className="col-2 text-center"><button onClick={(e) => this.calTded(item.id)} className="btnSeeTded btn-gradient-border btn-glow">{this.state.focusmatchid!==item.id?`คำนวนสูตร`:`ซ่อนสูตร`}</button></div>*/}
                        {this.state.focusmatchid==item.id?
                        <div className="col-12"><Aimain idmatch={this.state.focusmatchid} connMobile={this.state.connMobile} checkCase={this.state.caseFocusMatchId} /></div>
                        :''}
                    </div>
                    }
                    
                </div>
               )
           })
         }
         return (
            <div>
                        <div className="mainMemberPage">
                            <h2>วิธีใช้สูตรบอล</h2>
                            <p>เลือกคู่ที่สนใจ แล้วกดปุ่ม "คำนวนสูตร" ได้เลย !<br/><br/><span className="text-gradient-v4">( อ่านคำแนะนำการใช้สูตรที่ด้านล่างของตารางบอล )</span></p>
                            <div className="boxls">
                                <h2 className="text-center">โปรแกรมบอลวันที่ {`${new Date(Date.now() + (3600000*2) ).toISOString().slice(0,10)}`}</h2>
                                
                                <div className='rowDate'>
                                    <div className='colDate' onClick={(e)=>this.callApi(new Date(Date.now() + (3600000*2) - (3600000*24)).toISOString().slice(0,10))}
                                    style={this.state.HLdate===new Date(Date.now() + (3600000*2) - (3600000*24)).toISOString().slice(0,10)?{backgroundColor:'rgb(5,202,116)'}:{}}
                                    >{new Date(Date.now() + (3600000*2) - (3600000*24) ).toISOString().slice(5,10)}</div>
                                    <div className='colDate' onClick={(e)=>this.callApi(new Date(Date.now() + (3600000*2)).toISOString().slice(0,10))}
                                    style={this.state.HLdate===new Date(Date.now() + (3600000*2)).toISOString().slice(0,10)?{backgroundColor:'rgb(5,202,116)'}:{}}
                                    >วันนี้</div>
                                    <div className='colDate' onClick={(e)=>this.callApi(new Date(Date.now() + (3600000*2) + (3600000*24)).toISOString().slice(0,10))}
                                    style={this.state.HLdate===new Date(Date.now() + (3600000*2) + (3600000*24)).toISOString().slice(0,10)?{backgroundColor:'rgb(5,202,116)'}:{}}
                                    >{new Date(Date.now() + (3600000*2) + (3600000*24)).toISOString().slice(5,10)}</div>
                                </div>

                            {listEvents}
                            </div>
                        </div>
                        <div className='boxContentGacha cardBorder'>
                        <div className="note">
                            <h2>คำแนะนำการใช้สูตร</h2>
                            <p>สูตรวิเคราะห์บอล <span className="text-gradient">AITDED.COM</span> เหมาะกับการวิเคราะห์บอลลีกเป็นหลัก ซึ่งเป็นรายการแข่งที่ทั้งสองทีมอยู่ในทัวร์นาเม้นเดียวกัน สำหรับโปรแกรมบอลถ้วยต่างแดนมาเจอกัน อาทิ บอลทีมชาติ บอลยูฟ่า ยูโรปา FA CUP ฯลฯ ที่ทั้งสองทีมไม่ได้อยู่ในลีกเดียวกันมาพบกัน อาจไม่เหมาะสมกับการใช้สูตรบอลของเรา 
                                เพราะสถิติที่เทียบค่านั้นวัดเกณฑ์ได้ยากกว่าพอสมควร รวมไปถึงหากรายการแข่งไหนมีสถิติย้อนหลังน้อยเกินไป (ไม่ถึง 5 รายการแข่งย้อนหลังสำหรับสถิติของแต่ละทีม) ก็อาจไม่เหมาะสมเช่นกันเพราะสถิติยังถือว่าน้อยไป อย่างไรก็ตาม ทีมงานไม่ได้ล็อครายการที่กล่าวมาข้างต้นเอาไว้ 
                                เผื่อมีเพื่อนๆบางคนอยากทดลองใช้สูตรกับรายการดังที่กล่าวมา
                            </p>
                            <p>สรุปรายการที่เหมาะสมกับสูตรคือ รายการที่มีสถิติเจ้าบ้านและทีมเยือนมากกว่า 5 รายการแข่งย้อนหลัง และเป็นรายการแข่งที่อยู่ในลีกเดียวกัน ขอให้ทุกท่านสนุกกับการเชียร์บอลครับ...</p>
                        </div>
                        </div>
                    </div>
            
         )
     }
}

export default Ball;
