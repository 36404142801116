import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Casino extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            listGames : [
                {name:'Sexy Baccarat',img:'Sexy-Baccarat.jpg',demo:undefined},
                {name:'SA Gaming',img:'SA-Gaming.jpg',demo:'https://www.sagaming.com/trial?title=Baccarat'},
                {name:'AG Gaming',img:'AG-Gaming.jpg',demo:undefined},
                {name:'MG Live',img:'MG-Live.jpg',demo:undefined},
                {name:'DG Casino',img:'DG-Casino.jpg',demo:'https://login.ruihejade.com/pc-index/index.html'},
                {name:'RCG Casino',img:'RCG-Casino.jpg',demo:undefined}
            ],
            connMobile : this.props.connMobile
        }
    }
    componentDidMount(){
    }

    render() {
        //urlDemo = `https://m.pg-demo.com/114/index.html?l=en-US&btt=2&ot=8735ze6y8kp7jpwmxvau7gvytu3adwj4&f=https%3A%2F%2Fpublic.pg-redirect.com%2Fpages%2Fclose.html&__refer=m.pg-redirect.com&__sv=0`
        return (
            <div>
                <div className="mainMemberPage">
                    <div className="boxls">
                            {
                                this.state.listGames.map((item,index)=>{
                                    return(
                                        <div key={index} className="col-6 casinoListItem text-center">
                                            <LazyLoadImage src={`https://aitded.com/images/casino/${item.img}`} alt={item.name} />
                                            <h4>{item.name}</h4>
                                            {item.demo!==undefined?<a href={item.demo} target="_blank" rel="nofollow noreferrer"><span className="btn btn-gradient-border btn-glow btn-casino-demo">ทดลอง</span></a>:''}
                                            <a href={`https://bonus55bet.com`} target="_blank" rel="nofollow noreferrer"><span className="btn btn-gradient-border btn-glow btn-casino">เล่นจริง</span></a>
                                            <a href={`https://bonus55bet.com`} target="_blank" rel="nofollow noreferrer"><span className="btn btn-gradient-border btn-glow btn-casino-login">สมัคร</span></a>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
        )
    }
            
}

export default Casino;
