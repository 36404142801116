import React from 'react'
import store from '../../store/configstore'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Realtimegacha from './Realtimegacha'
import '../../Snowfall.css';

class Gacha extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            connMobile : this.props.connMobile,
            gacha : store.getState().gacha,
            listGacha : [

                /*
                back up
                {code : 0, name : 'ชิ้นส่วน เครดิตฟรี 10,000 บาท',full : 20,img : 'chip-10k.png'},
                {code : 1, name : 'ชิ้นส่วน เครดิตฟรี 5,000 บาท',full : 20,img : 'chip-5k.png'},
                {code : 2, name : 'ชิ้นส่วน เครดิตฟรี 2,000 บาท',full : 20,img : 'chip-2k.png'},
                {code : 3, name : 'ชิ้นส่วน เครดิตฟรี 1,000 บาท',full : 20,img : 'chip-1k.png'},
                {code : 4, name : 'ชิ้นส่วน เครดิตฟรี 800 บาท',full : 20,img : 'chip-800.png'},
                {code : 5, name : 'ชิ้นส่วน เครดิตฟรี 500 บาท',full : 20,img : 'chip-500.png'},
                {code : 6, name : 'ชิ้นส่วน เครดิตฟรี 300 บาท',full : 20,img : 'chip-300.png'},
                {code : 7, name : 'ชิ้นส่วน เครดิตฟรี 200 บาท',full : 20,img : 'chip-200.png'},
                {code : 8, name : 'ชิ้นส่วน เครดิตฟรี 100 บาท',full : 20,img : 'chip-100.png'},
                {code : 9, name : 'ชิ้นส่วน ไข่ที่แตกแล้ว',full : 3,img : 'chip-10k.png'}
                */

                {code : 0, name : 'ชิ้นส่วน Iphone 14 6.1 นิ้ว 128GB มูลค่า 32,900 บาท',full : 100,img : 'iphone-13.png'},
                {code : 1, name : 'ชิ้นส่วน MacBook Air M1 13 นิ้ว 256GB มูลค่า 34,900 บาท',full : 100,img : 'MacBook-Air-13-นิ้ว.png'},
                {code : 2, name : 'ชิ้นส่วน ทองคำ 1 บาท มูลค่า 26,500 บาท',full : 100,img : 'gold.png'},
                {code : 3, name : 'ชิ้นส่วน Sumsung Galaxy Z Fold4 256GB มูลค่า 59,900 บาท',full : 100,img : 'galaxy-z-fold3-5g.png'},
                {code : 4, name : 'ชิ้นส่วน Apple Watch Series 8 มูลค่า 15,900 บาท',full : 50,img : 'Apple-Watch-Series-7.png'},
                {code : 5, name : 'ชิ้นส่วน Honda New PCX 160 มูลค่า 93,400 บาท',full : 200,img : 'new-pcx-160.png'},
                {code : 6, name : 'ชิ้นส่วน Sony X80H 4K UltraHD 43 นิ้ว มูลค่า 17,490 บาท',full : 50,img : 'Sony-X80H-4K-Ultra-HD-43-นิ้ว.png'},
                {code : 7, name : 'ชิ้นส่วน AirPods Pro มูลค่า 8,992 บาท',full : 50,img : 'AirPods-Pro.png'},
                {code : 8, name : 'ชิ้นส่วน ทองคำ 50 สตางค์ มูลค่า 13,250 บาท',full : 100,img : 'gold-50.png'},
                {code : 9, name : 'ชิ้นส่วน Ps5 Blu Ray Edition มูลค่า 16,990 บาท',full : 75,img : 'ps5-Blu-Ray-Edition.png'},
                {code : 10, name : 'ชิ้นส่วน เงินสด มูลค่า 10,000 บาท',full : 50,img : 'money-10k.png'},
                {code : 11, name : 'ชิ้นส่วน เงินสด มูลค่า 5,000 บาท',full : 30,img : 'money-5k.png'},
                {code : 12, name : 'ชิ้นส่วน เงินสด มูลค่า 2,000 บาท',full : 30,img : 'money-2k.png'},
                {code : 13, name : 'ชิ้นส่วน เงินสด มูลค่า 1,000 บาท',full : 30,img : 'money-1k.png'},
                {code : 14, name : 'ชิ้นส่วน เครดิตฟรี 500 บาท',full : 30,img : 'credit-500.png'},
                {code : 15, name : 'ชิ้นส่วน เครดิตฟรี 400 บาท',full : 30,img : 'credit-400.png'},
                {code : 16, name : 'ชิ้นส่วน เครดิตฟรี 300 บาท',full : 30,img : 'credit-300.png'},
                {code : 17, name : 'ชิ้นส่วน เครดิตฟรี 200 บาท',full : 30,img : 'credit-200.png'},
                {code : 18, name : 'ชิ้นส่วน เครดิตฟรี 100 บาท',full : 30,img : 'credit-100.png'},
                {code : 19, name : 'ชิ้นส่วน ไข่ที่แตกแล้ว',full : 500,img : 'egg-open.gif'},
                {code : 20, name : 'ชิ้นส่วน iPad Pro 12.9 นิ้ว 128GB มูลค่า 44,900 บาท',full : 100,img : 'ipad-pro-12-9.png'},
                {code : 21, name : 'ชิ้นส่วน Nintendo Switch มูลค่า 13,890 บาท',full : 50,img : 'Nintendo-Switch-OLED-Model.png'},
                {code : 22, name : 'ชิ้นส่วน iMac Retina 5K รุ่น 27 นิ้ว 256GB มูลค่า 62,900 บาท',full : 150,img : 'iMac-Retina-5K-27-inc.png'},
                {code : 23, name : 'ชิ้นส่วน ทองคำ 25 สตางค์ มูลค่า 6,625 บาท',full : 50,img : 'gold-25-satang.png'},
                {code : 24, name : 'ชิ้นส่วน เงินสด มูลค่า 900 บาท',full : 30,img : 'money-900.png'},
                {code : 25, name : 'ชิ้นส่วน เงินสด มูลค่า 750 บาท',full : 30,img : 'money-750.png'},
                {code : 26, name : 'ชิ้นส่วน เครดิตฟรี 50 บาท',full : 15,img : 'credit-50.png'},
                {code : 27, name : 'ชิ้นส่วน เครดิตฟรี 25 บาท',full : 10,img : 'credit-25.png'},

                /* เพิ่มสินค้าอย่าลืมเพิ่มหน้า Adminrockstar ด้วย */
            ],
            reviceItem : undefined,
            reviceItemImg : undefined,
            selGachaBtn : 0
        }
    }
    componentDidMount(){

    }

    randItem = (useEgg = 1) => {
        /*

        ++ คำนวนทุน 10,000 ฟอง = 1,000,000 ++
        0 : ชิ้นส่วน Iphone 13 6.1 นิ้ว 128GB มูลค่า 29,990 บาท = 8/100 =~ 2400
        1 : ชิ้นส่วน MacBook Air รุ่น 13 นิ้ว 256GB มูลค่า 29,600 บาท = 4/100 =~ 1200
        2 : ชิ้นส่วน ทองคำ 1 บาท มูลค่า 28,500 บาท = 10/100 =~ 3000
        3 : ชิ้นส่วน Sumsung Galaxy Z Fold3 5g มูลค่า 57,900 บาท = 2/100 =~ 1200
        4 : ชิ้นส่วน Apple Watch Series 7 มูลค่า 13,900 บาท = 4/50 =~ 1200
        5 : ชิ้นส่วน Honda New PCX 160 มูลค่า 86,900 บาท = 2/200 =~ 900
        6 : ชิ้นส่วน Sony X80H 4K UltraHD 43 นิ้ว มูลค่า 17,490 บาท = 4/50 = 1500
        7 : ชิ้นส่วน AirPods Pro มูลค่า 8,992 บาท = 2/50 = 400
        8 : ชิ้นส่วน ทองคำ 50 สตางค์ มูลค่า 14,250 บาท = 10/100 = 1500
        9 : ชิ้นส่วน ชิ้นส่วน PS4 Pro 1TB มูลค่า 14,990 = 10/75 = 2000
        10 : ชิ้นส่วน เงินสด มูลค่า 10,000 บาท = 10/50 = 2000
        11 : ชิ้นส่วน เงินสด 5,000 บาท = 10/30 = 1700
        12 : ชิ้นส่วน เงินสด 3,000 บาท = 90/30 = 9000
        13 : ชิ้นส่วน เงินสด 1,000 บาท = 150/30 = 5000
        14 : ชิ้นส่วน เครดิตฟรี 500 บาท = 90/30 = 1500
        15 : ชิ้นส่วน เครดิตฟรี 400 บาท = 120/30 = 1600
        16 : ชิ้นส่วน เครดิตฟรี 300 บาท = 300/30 = 3000
        17 : ชิ้นส่วน เครดิตฟรี 200 บาท = 600/30 = 4000
        18 : ชิ้นส่วน เครดิตฟรี 100 บาท = 3000/30 = 10000
        19 : ชิ้นส่วนไข่ = 1/5 = 0
        20 : ชิ้นส่วน iPad Pro 12.9 นิ้ว 128GB มูลค่า 34,500 บาท = 4/100 =~ 1400
        21 : ชิ้นส่วน Nintendo Switch OLED Model มูลค่า 14,990 บาท = 2/50 =~ 600
        22 : ชิ้นส่วน iMac Retina 5K รุ่น 27 นิ้ว มูลค่า 69,900 บาท = 4/150 =~ 2000
        23 : ชิ้นส่วน ทองคำ 25 สตางค์ มูลค่า 7,125 บาท = 10/50 =~ 1500
        24 : ชิ้นส่วน เงินสด 900 บาท = 30/30
        25 : ชิ้นส่วน เงินสด 750 บาท = 30/30
        26 : ชิ้นส่วน เครดิตฟรี 50 บาท = 15/15
        27 : ชิ้นส่วน เครดิตฟรี 25 บาท = 10/10

        จ่ายออกตัวเงิน 59,400 ต่อยอด 1,000,000
        รวมชิ้นไอเทม 4450 ชิ้น และชิ้นส่วนไข่ 5550 ชิ้น ( 555 ฟอง )
        ไข่ 555 ฟองที่สุ่ม เฉลี่ยเงินจ่ายออก = 59,400x(5/100) = 2,970
        รวมเฉลี่ย จ่ายออกทั้งหมด = 61,970 หรือประมาณ ~62,000 ต่อยอดฝาก 1,000,000
        ** เพิ่มไอเทม 20-23 ต้องคำนวนจ่ายใหม่ **
    
        */

        let arrGacha = this.state.listGacha
        let numLotto = Math.floor(Math.random()*10000)

        if(useEgg==10){

            if(numLotto>=0&&numLotto<=100){ return arrGacha[17]} // 1%
            else if(numLotto>100&&numLotto<=999){ return arrGacha[18]} // 9%
            else if(numLotto>999&&numLotto<=1999){ return arrGacha[26]} // 10%
            else if(numLotto>1999&&numLotto<=2999){ return arrGacha[27]} // 10%
            else if(numLotto>2999&&numLotto<=9999){ return arrGacha[19]} // 70%

        }else{
            
            if(numLotto>=0&&numLotto<=11){ return arrGacha[0]} // 0.12%
            else if(numLotto>11&&numLotto<=15){ return arrGacha[22]} // 0.04%
            else if(numLotto>15&&numLotto<=19){ return arrGacha[1]} // 0.04%
            else if(numLotto>19&&numLotto<=23){ return arrGacha[20]} // 0.04%
            else if(numLotto>23&&numLotto<=28){ return arrGacha[2]} // 0.05%
            else if(numLotto>33&&numLotto<=35){ return arrGacha[3]}// 0.02%
            else if(numLotto>35&&numLotto<=37){ return arrGacha[21]}// 0.02%
            else if(numLotto>37&&numLotto<=41){ return arrGacha[4]} // 0.04%
            else if(numLotto>41&&numLotto<=43){ return arrGacha[5]} // 0.02%
            else if(numLotto>43&&numLotto<=47){ return arrGacha[6]} // 0.04%
            else if(numLotto>47&&numLotto<=49){ return arrGacha[7]} // 0.02%
            else if(numLotto>49&&numLotto<=54){ return arrGacha[8]} // 0.05%
            else if(numLotto>54&&numLotto<=59){ return arrGacha[23]} // 0.05%
            else if(numLotto>59&&numLotto<=64){ return arrGacha[9]} // 0.05%
            else if(numLotto>79&&numLotto<=80){ return arrGacha[10]} // 0.01%
            else if(numLotto>80&&numLotto<=81){ return arrGacha[11]} // 0.01%
            else if(numLotto>81&&numLotto<=82){ return arrGacha[12]} // 0.01%
            else if(numLotto>82&&numLotto<=83){ return arrGacha[13]} // 0.01%
            else if(numLotto>83&&numLotto<=88){ return arrGacha[24]} // 0.05%
            else if(numLotto>88&&numLotto<=93){ return arrGacha[25]} // 0.05%
            else if(numLotto>339&&numLotto<=389){ return arrGacha[14]} // 0.5%
            else if(numLotto>389&&numLotto<=439){ return arrGacha[15]} // 0.5%
            else if(numLotto>439&&numLotto<=539){ return arrGacha[16]} // 1%
            else if(numLotto>539&&numLotto<=839){ return arrGacha[17]} // 3%
            else if(numLotto>839&&numLotto<=2339){ return arrGacha[18]} // 15%
            else if(numLotto>2339&&numLotto<=8999){ return arrGacha[19]} // 66.6%
            else if(numLotto>8999&&numLotto<=9500){ return arrGacha[26]} // 5.0%
            else if(numLotto>9500&&numLotto<=9999){ return arrGacha[27]} // 5.0%
            else if((numLotto>28&&numLotto<=33)||(numLotto>64&&numLotto<=79)||(numLotto>93&&numLotto<=339)){ return arrGacha[19]} // ส่วนรวมที่ลดเรทออก
        }
    }
    

    delaySwithShowReward(){
        
        document.getElementById('loadGacha').style.display = "none";
        document.getElementById('dataReward').style.display = "block";
        var myAudio2 = new Audio('/audios/item.mp3');
        myAudio2.play();
    }
    openGacha = async (useEgg = 1) =>{
        let reward = await this.randItem(useEgg)


        //if(store.getState().gacha<=0){
        if(store.getState().gacha<useEgg){

            //axios.post(`http://localhost/member`,{username: store.getState().username,password: store.getState().pwd}).then(res =>{
            axios.post(`https://sv.aitded.com/member`,{username: store.getState().username,password: store.getState().pwd}).then(res =>{
                if(res.data.error===undefined){
                    //console.log(res.data)
                    //if(res.data.gacha<=0){
                    if(res.data.gacha<useEgg){
                        //console.log(useEgg)
                        // กรณีเติมไข่แล้ว ให้ลูกค้ากด Reload แทน จะได้ไม่ต้อง Call Data ซ้ำ
                        document.getElementById('loadGacha').style.display = "none";
                        document.getElementById('dataReward').style.display = "block";
                        var myAudio3 = new Audio('/audios/alerterr.mp3');
                        myAudio3.play();
                        //this.setState({gacha:-1}) //ค่า -1 ไม่เกี่ยวกับ DB
                        this.setState({selGachaBtn:0}) // reset to defalt ไม่เกี่ยวกับ DB
                    }else{
                        var myAudio1 = new Audio('/audios/load-gacha.mp3');
                        myAudio1.play();
                        
                        document.getElementById('loadGacha').style.display = "block";
                        document.getElementById('dataReward').style.display = "none";
                        setTimeout(this.delaySwithShowReward, 2000);
                        store.dispatch({ 
                            type: 'gacha',
                            data: {
                                username : store.getState().username,
                                pwd : store.getState().pwd,
                                expire : store.getState().expire,
                                level : store.getState().level,
                                gacha : res.data.gacha,
                                listGachaItem : res.data.listGachaItem,
                                exp : store.getState().exp+useEgg
                            }
                        })
                        axios.post(`https://sv.aitded.com/member`,{username: store.getState().username,password: store.getState().pwd,newgacha:store.getState().gacha-useEgg,itemcodegacha:reward.code,useegg:useEgg}).then(res => {
                            if(res.data.error===undefined){
                                //console.log('success ' + store.getState().gacha)
                                //console.log(res.data)
                                store.dispatch({ 
                                    type: 'gacha',
                                    data: {
                                        username : store.getState().username,
                                        pwd : store.getState().pwd,
                                        expire : store.getState().expire,
                                        level : store.getState().level,
                                        gacha : res.data.gacha,
                                        listGachaItem : res.data.listGachaItem,
                                        exp : store.getState().exp+useEgg
                                    }
                                });
                
                                this.props.checkUpdate(res.data.gacha,res.data.exp)
                                //alert(`คุณมีกาชาปองคงเหลือ ${store.getState().gacha} ได้รับรางวัล : ${reward.name}`)
                                this.setState({gacha:res.data.gacha,reviceItem:reward.name,reviceItemImg:reward.img,selGachaBtn:useEgg})
                            }else{
                                //console.log(res.data)
                                //console.log('no success ' + store.getState().gacha)
                            }
                        }).catch(e => {
                            console.log(e)
                        })
                    }
                }
            })
                
            
        }else{
            
            var myAudio1 = new Audio('/audios/load-gacha.mp3');
            myAudio1.play();
            
            document.getElementById('loadGacha').style.display = "block";
            document.getElementById('dataReward').style.display = "none";
            setTimeout(this.delaySwithShowReward, 2000);

            //axios.post(`http://localhost/member`,{username: store.getState().username,password: store.getState().pwd}).then(res =>{
            axios.post(`https://sv.aitded.com/member`,{username: store.getState().username,password: store.getState().pwd}).then(res =>{
                if(res.data.error===undefined){
                    //console.log(res.data)
                    store.dispatch({ 
                        type: 'gacha',
                        data: {
                            username : store.getState().username,
                            pwd : store.getState().pwd,
                            expire : store.getState().expire,
                            level : store.getState().level,
                            gacha : res.data.gacha,
                            listGachaItem : res.data.listGachaItem,
                            exp : store.getState().exp+useEgg
                        }
                    })
                //axios.post(`http://localhost/member`,{username: store.getState().username,password: store.getState().pwd,newgacha:store.getState().gacha-useEgg,itemcodegacha:reward.code,useegg:useEgg}).then(res => {
                axios.post(`https://sv.aitded.com/member`,{username: store.getState().username,password: store.getState().pwd,newgacha:store.getState().gacha-useEgg,itemcodegacha:reward.code,useegg:useEgg}).then(res => {
                        if(res.data.error===undefined){
                            //console.log('success ' + store.getState().gacha)
                            //console.log(res.data)
                            store.dispatch({ 
                                type: 'gacha',
                                data: {
                                    username : store.getState().username,
                                    pwd : store.getState().pwd,
                                    expire : store.getState().expire,
                                    level : store.getState().level,
                                    gacha : res.data.gacha,
                                    listGachaItem : res.data.listGachaItem,
                                    exp : store.getState().exp+useEgg
                                }
                            });
            
                            this.props.checkUpdate(res.data.gacha,res.data.exp)
                            //alert(`คุณมีกาชาปองคงเหลือ ${store.getState().gacha} ได้รับรางวัล : ${reward.name}`)
                            this.setState({gacha:res.data.gacha,reviceItem:reward.name,reviceItemImg:reward.img,selGachaBtn:useEgg})
                        }else{
                            //console.log(res.data)
                            //console.log('no success ' + store.getState().gacha)
                        }
                    }).catch(e => {
                        console.log(e)
                    })
                }else{

                }
            })
        }
    }

    render() {
        //console.log(this.state.selSumGacha)
        return (
            <div>
                <snowfall>
                    <snowflake><img src="images/reward/iMac-Retina-5K-27-inc.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/ipad-pro-12-9.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/ps5-Blu-Ray-Edition.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/gold-25-satang.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/gold.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/MacBook-Air-13-นิ้ว.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/iphone-13.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/money-1k.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/money-2k.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/money-5k.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/money-10k.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/Sony-X80H-4K-Ultra-HD-43-นิ้ว.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/Nintendo-Switch-OLED-Model.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/galaxy-z-fold3-5g.png" className='itemfall' /></snowflake>
                    <snowflake><img src="images/reward/credit-500.png" className='itemfall' /></snowflake>
                </snowfall>
                <div className="mainMemberPage" id='mainGachaPage'>
                    <div className="boxls">

                    {/* s modal 1 */}
                    <div className="box">
                        <div className='gachaSpin'>
                            <div><LazyLoadImage src={`/images/gashapon-nobg.gif`} className='shake' alt='Gashapon' style={{maxWidth:'100%',width:'300px'}}/></div>
                            
                            <a href="#m1-o" className="link-1 rungachabtn" id="m1-c" onClick={(e)=>this.openGacha(1)}><img src='/images/icon/menu_1_gift.webp' style={{width:'30px'}} /> สุ่มรางวัล 1 ฟอง !</a><br /><br /><br />
                            <a href="#m1-o" className="link-1 rungachabtn" id="m1-c" onClick={(e)=>this.openGacha(10)}><img src='/images/icon/menu_1_gift.webp' style={{width:'30px'}} /> สุ่มรางวัล 10 ฟอง !</a>
                        </div>
                        <div className="modal-container" id="m1-o" style={{backgroundColor:'rgba(0,0,0,0)'}}>
                            <div className="modal" id="dataReward">
                                {//this.state.gacha>=0? //เพราะมีการ Render หลัง gacha = 0 1 รอบ
                                this.state.selGachaBtn>0?
                                <div>
                                    <h1 className="modal__title">ยินดีด้วย !!</h1>
                                    <LazyLoadImage src={`/images/reward/${this.state.reviceItemImg}`} alt={this.state.reviceItem} className='rewardIcon'/><br/>
                                    <span className="modal__text text-center">คุณได้รับ {this.state.reviceItem} จำนวน {this.state.selGachaBtn} ชิ้น</span><br/>
                                    <span className="text-center">ไข่สุ่ม คงเหลือ : {this.state.gacha} ฟอง</span><br/>
                                    <button className="modal__btn link-1" style={{padding:'0.5rem 1rem',fontSize:'10pt'}} onClick={(e)=>this.openGacha(1)}>สุ่มรางวัลต่อ 1 ฟอง..</button><br />
                                    <button className="modal__btn link-1" style={{padding:'0.5rem 1rem',fontSize:'10pt'}} onClick={(e)=>this.openGacha(10)}>สุ่มรางวัลต่อ 10 ฟอง..</button>
                                    <a href="#mainGachaPage" className="link-2"></a>
                                </div>:
                                <div>
                                    <h1 className="modal__title">ไข่สุ่มเหลือไม่พอ{/*this.state.gacha+1*/} โปรดเติมเครดิต</h1>
                                    <a href="#mainGachaPage" className="link-2"></a>
                                </div>}
                            </div>
                            <div className="modal" id="loadGacha">
                                <div><LazyLoadImage src={`/images/gashapon-nobg.gif`} alt='Gashapon' style={{maxWidth:'250px'}}/></div>
                                <h1 className="modal__title">กำลังหมุนกาชาปอง...</h1>
                            </div>
                        </div>
                    </div>
                    {/* e modal 1 */}

                    <div className='text-center'>
                                {/*<div style={{marginTop:'20px'}}><button className='btn btn-gradient-border btn-glow btn-gacha' onClick={(e)=>this.openGacha()}>สุ่มกาชา คลิ๊ก !!</button></div>*/}
                                <div><button className='btn btn-gradient-border btn-glow' style={{cursor:'default'}}><img alt="กาชาปอง" src="/images/reward/egg_cartoon.png" style={{verticalAlign:'sub',maxWidth:'30px'}} /> ไข่สุ่มกาชา คงเหลือ : {this.state.gacha<0?0:this.state.gacha} ฟอง</button></div>
                                    <div style={{marginTop:'10px',marginBottom:'10px'}}><a href="https://lin.ee/NIDxu4g" rel="nofollow noopener" target="_blank"><button className='btn btn-glow btn-green' style={{fontSize:'13pt'}}>ติดต่อแลกของรางวัล คลิ๊ก !!</button></a></div>
                                </div>
                                <div className='cardBorder boxContentGacha'>
                                <div className='howtoReciveGacha text-center'>
                                    <h2>วิธีรับไข่กาชาปอง</h2>
                                    <div className='link-1' style={{maxWidth:'300px',width:'90%',margin:'auto',fontSize:'1rem'}}>
                                    <span>ทุกยอดฝาก 100 = <img alt="กาชาปอง" src="/images/reward/egg_cartoon.png" style={{verticalAlign:'sub',maxWidth:'30px'}} /> รับไข่สุ่ม 1 ฟอง</span><br/>
                                    <span>สะสมชิ้นส่วนครบ แลกของรางวัลได้ทันที !!</span>
                                    </div>
                                    <p>
                                        ทุกๆยอดฝาก <span style={{color:'yellow'}}>100 บาท</span> ที่สมาชิกฝากเข้าระบบ bonus55bet.com จะได้รับไข่สุ่มกาชาปอง 1 ฟอง ไม่จำกัดจำนวนสูงสุด ( ฝาก 10,000 = รับไข่ 100 ฟอง เป็นต้น ) หลังเพื่อนๆทำรายการฝากเว็บดังกล่าวแล้ว 
                                        ต้องแจ้งทีมงาน AITDED ผ่านไลน์ @aitded ( คลิกที่นี่... <a href="https://lin.ee/NIDxu4g" target='_blank' rel="nofollow noreferrer" style={{color:'#00ffd0'}}>https://lin.ee/NIDxu4g</a> ) 
                                        ทีมงานจะทำการตรวจสอบยอดฝากของผู้เล่นทั้งหมด และทำการอัพเดทไข่สุ่มให้ <span style={{color:'orange'}}>ภายใน 24 ชั่วโมง</span> เมื่อมีการเพิ่มให้เรียบร้อยแล้ว ทีมงานจะตอบกลับลูกค้าทางไลน์ให้ทราบครับ
                                    </p>
                                    <p style={{color:'#50ffcf'}}>หมายเหตุ : การขอไข่กาชาปอง สามารถคิดจากยอดฝากย้อนหลังได้สูงสุดเพียง 7 วันเท่านั้น ดังนั้น อย่าลืมมาขอไข่กาชาทุกครั้งที่ฝากเงินกันด้วยนะครับ</p>
                                </div>
                                </div>

                                <div className='cardBorder boxContentGacha' style={{marginTop:'20px',marginBottom:'10px'}}>
                                <div className='howtoReciveGacha text-center'>
                                    <h2>เว็บพันธมิตรของเรา</h2>
                                <div>
                                    <a href="https://bonus55bet.com" rel="nofollow noopener" target="_blank"><button className='btn btn-gradient-border btn-glow' >www.bonus55bet.com</button></a><br />
                                    
                                </div>
                                </div>
                                </div>
   
                                <div className='text-center'><LazyLoadImage src={`/images/10lvbonus.webp`} alt={'bonus level'} className='bonusLevelImg'/></div>

                                {/* s realtime reward update */}
                                <Realtimegacha listGacha={this.state.listGacha} />
                                {/* e realtime reward update */}
                        <h2 className='text-center text-gradient'>ของรางวัลสะสมทั้งหมด ของสมาชิก : {store.getState().username}</h2>
                        <div className='listRewardAll'>
                            {
                                this.state.listGacha.map((item,index) => {
                                    return(
                                        <div className='rowReward' key={index}>
                                            <div className='cropReward'>
                                            <div className='itemImg'><LazyLoadImage src={`/images/reward/${item.img}`} alt={item.name} className='rewardIcon rewardItem'/><br/>{item.name}<br/>
                                            {
                                            store.getState().listGachaItem.find(({code}) => code === item.code)!==undefined?
                                            (store.getState().listGachaItem.find(({code}) => code === item.code)).sum:
                                            0
                                            } / {item.full}</div>
                                            <div className='text-gradient-v4'>สะสมครบ {item.full} ชิ้น {item.code===19?'แลกไข่กาชาปองได้ 100 ใบ':'แลกรางวัลได้ทันที'}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                            <div style={{clear:'both'}}></div>
                            
                    </div>
                </div>
            </div>
        )
    }
            
}

export default Gacha;
